var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ss-left-nav-content"},[_c('div',{staticClass:"ss-nav-title"},[_vm._v("SETUP CHECKLIST")]),_c('div',{staticClass:"ss-lnav-links"},_vm._l((_vm.accountChecklist),function(checkListOpt,index){return _c('div',{key:`checkListOpt_${index}`,class:`ss-lnav-link ${checkListOpt.isDisabled ? 'disable-opt' : ''}`},[_c('div',[_c('font-awesome-icon',{staticClass:"lnav-icon",attrs:{"icon":[`${checkListOpt.isSolidLeftIcon ? 'fas' : 'far'}`, `${checkListOpt.leftIcon}`]}}),_c('div',{staticClass:"ss-lnav-txt",on:{"click":function($event){return _vm.handleCheckOptClick(
              checkListOpt.route,
              checkListOpt.isDisabled,
              checkListOpt.elementId
            )}}},[_c('p',[_vm._v(" "+_vm._s(checkListOpt.title)+" ")])]),_c('font-awesome-icon',{class:`lnav-icon2 ${checkListOpt.isActive ? 'active' : ''}`,attrs:{"icon":['far', `${checkListOpt.rightIcon}`]}})],1),(checkListOpt.subOptions)?_c('div',{staticClass:"ss-lnav-subOpt-cont"},_vm._l((checkListOpt.subOptions),function(subOptions,index){return _c('p',{key:`subOptionsList_${index}`,class:`ss-lnav-subTxt ${checkListOpt.isDisabled ? 'disable-sub-opt' : ''}`,on:{"click":function($event){return _vm.handleCheckOptClick(subOptions.route, checkListOpt.isDisabled)}}},[_vm._v(" "+_vm._s(subOptions && subOptions.title)+" "),_c('font-awesome-icon',{class:`lnav-icon2 ${subOptions.isActive ? 'active' : ''} ${
                subOptions.rightIcon === 'exclamation' ? 'error-icon' : ''
              }`,attrs:{"icon":[subOptions.isSolidLeftIcon ? 'fas' : 'far', `${subOptions.rightIcon}`]}})],1)}),0):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }