import {
  LIS_DRAFT,
  LIS_NEW,
  LIS_PENDING_ACTIVATION,
  LIS_ACTIVATED,
  LIS_DISAPPROVED,
  LIS_CAMPAIGN_LIVE,
  LIS_PAUSED,
  LIS_ENDED,
  LIS_CANCELED,
  LIS_REOPENED,
  LIS_PENDING_CANCELLATION,
  LIS_PENDING_PAUSED,
  LIW_PENDING_AM,
} from 'adready-api/constant';

export const LI_STATUS_DISPLAY_NAME_DRAFT = 'Draft';
export const LI_STATUS_DISPLAY_NAME_SUBMITTED = 'Submitted';
export const LI_STATUS_DISPLAY_NAME_LIVE = 'Live';
export const LI_STATUS_DISPLAY_NAME_PAUSED = 'Paused';
export const LI_STATUS_DISPLAY_NAME_ENDED = 'Ended';
export const LI_STATUS_DISPLAY_NAME_CANCELED = 'Canceled';

export const LI_DISPLAY_STATUS_COLOR_CODES = {
  [LI_STATUS_DISPLAY_NAME_DRAFT]: '#d6dadf',
  [LI_STATUS_DISPLAY_NAME_SUBMITTED]: '#4fd1cf',
  [LI_STATUS_DISPLAY_NAME_LIVE]: '#006987',
  [LI_STATUS_DISPLAY_NAME_PAUSED]: '#fdce07',
  [LI_STATUS_DISPLAY_NAME_ENDED]: '#3d9afb',
  [LI_STATUS_DISPLAY_NAME_CANCELED]: '#fd9807',
};

const displayStatusNamesMap = {
  [LIS_DRAFT]: LI_STATUS_DISPLAY_NAME_DRAFT,
  [LIS_NEW]: LI_STATUS_DISPLAY_NAME_SUBMITTED,
  [LIS_PENDING_ACTIVATION]: LI_STATUS_DISPLAY_NAME_SUBMITTED,
  [LIS_ACTIVATED]: LI_STATUS_DISPLAY_NAME_SUBMITTED,
  [LIS_DISAPPROVED]: LI_STATUS_DISPLAY_NAME_SUBMITTED,
  [LIS_REOPENED]: LI_STATUS_DISPLAY_NAME_SUBMITTED,
  [LIS_CAMPAIGN_LIVE]: LI_STATUS_DISPLAY_NAME_LIVE,
  [LIS_PAUSED]: LI_STATUS_DISPLAY_NAME_PAUSED,
  [LIS_ENDED]: LI_STATUS_DISPLAY_NAME_ENDED,
  [LIS_CANCELED]: LI_STATUS_DISPLAY_NAME_CANCELED,
  [LIS_PENDING_CANCELLATION]: LI_STATUS_DISPLAY_NAME_CANCELED,
  [LIS_PENDING_PAUSED]: LI_STATUS_DISPLAY_NAME_PAUSED,
};

export const STATUS_CAN_PAUSE = [
  LIS_NEW,
  LIS_PENDING_ACTIVATION,
  LIS_ACTIVATED,
  LIS_DISAPPROVED,
  LIS_CAMPAIGN_LIVE,
  LIS_REOPENED,
];

export const STATUS_CAN_PLAY = [LIS_PENDING_PAUSED, LIS_PAUSED];

export function canPauseLineItem(statusId) {
  return STATUS_CAN_PAUSE.includes(statusId);
}

export function canPlayLineItem(statusId) {
  return STATUS_CAN_PLAY.includes(statusId);
}

export function displayNameVsStatusIdsMap() {
  return Object.entries(displayStatusNamesMap).reduce((acc, v) => {
    const val = acc[v[1]] ? acc[v[1]] : { ids: [], displayName: v[1] };
    val.ids.push(v[0]);
    acc[v[1]] = val;
    return acc;
  }, {});
}

/**
 * This function is used to build rules to update statusId/workflowId paused/ request to paused on current status basis.
 * @param {*} currentStatus
 * @returns updated statusId/workflowId
 */
export function pausedStatusAndWorkflowChange(currentStatus) {
  let updatedStatus = {};
  switch (currentStatus) {
    case LIS_NEW:
      updatedStatus = { statusId: LIS_PAUSED };
      break;
    case LIS_PENDING_ACTIVATION:
      updatedStatus = { statusId: LIS_PENDING_PAUSED, workflowId: LIW_PENDING_AM };
      break;
    case LIS_ACTIVATED:
      updatedStatus = { statusId: LIS_PENDING_PAUSED, workflowId: LIW_PENDING_AM };
      break;
    case LIS_CAMPAIGN_LIVE:
      updatedStatus = { statusId: LIS_PENDING_PAUSED, workflowId: LIW_PENDING_AM };
      break;
    case LIS_REOPENED:
      updatedStatus = { statusId: LIS_PENDING_PAUSED, workflowId: LIW_PENDING_AM };
      break;
    default:
      break;
  }
  return updatedStatus;
}

/**
 * This function is used to build rules to update statusId/workflowId 'Ready for Activation' on current status basis.
 * @param {*} currentStatus
 * @returns updated statusId/workflowId
 */
export function playStatusAndWorkflowChange(currentStatus) {
  let updatedStatus = {};
  switch (currentStatus) {
    case LIS_PAUSED:
      updatedStatus = { statusId: LIS_PENDING_ACTIVATION, workflowId: LIW_PENDING_AM };
      break;
    case LIS_PENDING_PAUSED:
      updatedStatus = { statusId: LIS_PENDING_ACTIVATION, workflowId: LIW_PENDING_AM };
      break;
    default:
      break;
  }
  return updatedStatus;
}

export function displayLIStatusNames(statusId) {
  return displayStatusNamesMap[statusId];
}

export const STATUS_DISPLAY_READ_ONLY = [
  LI_STATUS_DISPLAY_NAME_ENDED,
  LI_STATUS_DISPLAY_NAME_CANCELED,
];

export function isStatusReadonly(statusId) {
  const displayStatus = displayLIStatusNames(statusId);
  return STATUS_DISPLAY_READ_ONLY.includes(displayStatus);
}

export default {};
