<template>
  <ul class="nav-tabs">
    <li
      v-for="(item, i) in menu"
      :key="i"
      :class="{
        'tab-active': item.name.toUpperCase() === active.toUpperCase(),
      }"
      @click="setActiveTab(item.name || '')"
    >
      <a rel="nofollow">
        <font-awesome-icon v-if="item.icon" class="icon" :icon="['far', item.icon]" size="1x" />
        <span>{{ item.name || '' }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      required: true,
      default: () => [],
    },
    active: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  methods: {
    setActiveTab(tab = '') {
      const vm = this;

      vm.$emit('selected-menu', tab.toUpperCase());
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  margin: -1px 4px;
}
.nav-tabs li.tab-active a,
.nav-tabs li.tab-active2 a {
  top: -2px;
  height: 37px;
  font-size: 12px;
  color: #fff;
  background-color: #212429;
}
.nav-tabs li a {
  position: relative;
  top: 0px;
  box-sizing: border-box;
  display: block;
  height: 33px;
  padding: 6px 10px;
  margin-right: 3px;
  font-size: 13px;
  font-weight: 400;
  color: #848890;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #1b1d21;
  border-radius: 3px 3px 0px 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-touch-callout: none;
}
.nav-tabs li a:hover {
  top: -2px;
  color: #cad1d6;
  background-color: #212429;
}
.nav-tabs {
  list-style: none;
}
.nav-tabs .tab-active a {
  cursor: default;
  border-top: 1px solid #01abb7;
  border-radius: 0;
}
.nav-tabs a {
  display: block;
}
.nav-tabs li {
  float: left;
}
</style>
