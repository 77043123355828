<template>
  <div class="d-board">
    <div class="container pub-manage-system">
      <b-loading-spinner v-if="isLoading" class="txt-center" />
      <div class="mid-area list-box">
        <div v-if="!isLoading" class="pub-manage-system-container">
          <div class="pub-manage-system-header">
            <b-text-input
              :value="searchedText"
              placeholder="Search"
              :icon="['far', 'search']"
              class="pub-manage-search"
              @keyup="onSearchTextChange"
            />

            <k-button
              :size="4"
              label="Add Publisher"
              :disabled="isDisableAddNewBtn()"
              @click="onAddNewPub"
            />
          </div>
          <p v-if="errorText" class="error-text">{{ errorText }}</p>
          <table class="publish-detail-table">
            <thead>
              <th class="com-title">Id</th>
              <th class="com-title">Name</th>
              <th class="com-title">Description</th>
              <th class="com-title">DIDs/Media Type</th>
              <th class="com-title">Scalability</th>
              <th class="com-title">logo</th>
              <th class="com-title">actions</th>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in mediaPublisherData"
                :key="data.id"
                :class="`${data.hide ? 'hide-row' : ''}`"
              >
                <td class="sub-li">
                  <span>{{ data.id }}</span>
                </td>
                <td class="sub-li name">
                  <span v-if="data.readonly" :title="data.publisherName">
                    {{ data.publisherName }}
                  </span>
                  <k-text-input
                    v-else
                    :value="data.publisherName"
                    :max-length="255"
                    :required="false"
                    :readonly="data.readonly"
                    @keyup="(val) => changePubValue({ value: val, index, node: 'publisherName' })"
                  />
                </td>
                <td class="sub-li description">
                  <span v-if="data.readonly" :title="data.streamingCategory">{{
                    data.streamingCategory
                  }}</span>
                  <k-text-input
                    v-else
                    :value="data.streamingCategory"
                    :max-length="255"
                    :required="false"
                    :readonly="data.readonly"
                    @keyup="
                      (val) => changePubValue({ value: val, index, node: 'streamingCategory' })
                    "
                  />
                </td>
                <td
                  class="sub-li did cursor-pointer highlight-txt"
                  @click="() => (data.isNew ? null : onDidMediaTypeClick(data, index))"
                >
                  <span :title="getPubRulesDataTooltip(data)">{{
                    getPubRulesDataString(data)
                  }}</span>
                </td>
                <td class="sub-li scalability">
                  <span v-if="data.readonly">{{ data.scalability }} </span>
                  <b-number-input
                    v-else
                    :required="false"
                    :value="data.scalability"
                    type="number"
                    :enable-set-empty="true"
                    @keyup="(val) => changePubValue({ value: val, index, node: 'scalability' })"
                  />
                </td>
                <td class="sub-li logo">
                  <img
                    v-if="data.readonly"
                    :src="data.logoURL || DEFAULT_PUBLISHER_LOGO"
                    class="publisher-logo"
                    :title="data.logoURL"
                  />
                  <k-text-input
                    v-else
                    :value="data.logoURL"
                    :max-length="500"
                    :required="false"
                    :readonly="data.readonly"
                    @keyup="(val) => changePubValue({ value: val, index, node: 'logoURL' })"
                  />
                </td>
                <td class="sub-li">
                  <div class="button-box sub-li-item">
                    <div v-if="data.readonly" class="box px-2">
                      <span class="intip4 action-btn" data-tool-tip="Edit Publisher">
                        <font-awesome-icon
                          :icon="['fas', 'pencil']"
                          @click="onEditPublisher(index)"
                        />
                      </span>
                      <span class="intip4 action-btn" data-tool-tip="Delete Publisher">
                        <font-awesome-icon
                          :icon="['fas', 'trash']"
                          @click="onShowConfirmation(index)"
                        />
                      </span>
                    </div>
                    <div v-if="!data.readonly" class="box px-2">
                      <span class="intip4 action-btn" data-tool-tip="Save Publisher">
                        <font-awesome-icon
                          :icon="['fas', 'save']"
                          @click="onSavePublisher(index)"
                        />
                      </span>
                      <span class="intip4 action-btn" data-tool-tip="Discard Changes">
                        <font-awesome-icon
                          :icon="['fas', 'times']"
                          @click="onDiscardChanges(index)"
                        />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <PubDidMediaTypeModal
      v-if="showDidModal.show"
      :modal-payload="showDidModal.payload"
      :media-publisher-data="mediaPublisherData"
      @update-deal-payload="updateDealPayload"
      @cancel="showDidModal = { show: false, payload: {} }"
    />
    <div v-if="confirmModal.show" class="confirm-modal-publisher">
      <FlipConfirmationModal
        width="400px"
        :type="confirmModal.text"
        custom-heading="Delete Publisher"
        :is-cancel="true"
        :is-remove="true"
        @cancel="onCancelDelete()"
        @remove="onDeletePublisher(confirmModal.index)"
      />
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import mediaPublisherApi from '@/api/media-publisher';
import BNumberInput from '~/components/elements/b-number-input.vue';
import BTextInput from '~/components/elements/b-text-input.vue';
import FlipConfirmationModal from './FlipConfirmationModal.vue';
import PubDidMediaTypeModal from './PubDidMediaTypeModal.vue';

export default {
  name: 'PubManagementSystem',
  components: {
    BNumberInput,
    PubDidMediaTypeModal,
    BTextInput,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    FlipConfirmationModal,
  },
  data() {
    return {
      DEFAULT_PUBLISHER_LOGO:
        'https://storage.googleapis.com/flip-assets/publisher_logos/color/otherhub_80x80.png',
      showDidModal: { show: false, payload: {} },
      searchedText: '',
      mediaPublisherData: [],
      isLoading: false,
      tempPublisherData: {},
      confirmModal: { show: false, text: '' },
      errorText: '',
    };
  },
  async mounted() {
    this.isLoading = true;
    const { data } = await mediaPublisherApi.mediaPublisher();
    if (data.length > 0) {
      this.mediaPublisherData = data.map((d) => ({ ...d, readonly: true, hide: false }));
    }
    this.isLoading = false;
  },
  methods: {
    changePubValue(payload) {
      const { node, index, value } = payload;
      if (node === 'publisherName') {
        if (!value) {
          this.errorText = 'Please enter publisher name';
        } else {
          this.errorText = '';
        }
      } else if (node === 'scalability') {
        if (!value) {
          this.errorText = 'Scalability must be greater than 0';
        } else {
          this.errorText = '';
        }
      }
      this.mediaPublisherData[index][node] = value;
    },
    isDisableAddNewBtn() {
      let isDisabled = false;
      this.mediaPublisherData.forEach((media) => {
        if (!media.readonly) {
          isDisabled = true;
        }
      });
      return isDisabled;
    },
    onCancelDelete() {
      this.confirmModal = { show: false, text: '' };
    },
    onShowConfirmation(index) {
      if (!this.isDisableAddNewBtn()) {
        const { publisherName } = this.mediaPublisherData[index];
        this.confirmModal = {
          show: true,
          text: `Are you sure you want to remove ${publisherName}?`,
          index,
        };
      }
    },
    onEditPublisher(index) {
      if (!this.isDisableAddNewBtn()) {
        this.tempPublisherData = {};
        this.tempPublisherData = JSON.parse(JSON.stringify({ ...this.mediaPublisherData[index] }));
        this.mediaPublisherData[index].readonly = false;
        this.$forceUpdate();
      }
    },
    async onDeletePublisher(index) {
      this.isLoading = true;
      try {
        await mediaPublisherApi.deletePublisher(this.mediaPublisherData[index].id);
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
      this.mediaPublisherData.splice(index, 1);
      this.confirmModal = { show: false, text: '' };
    },
    async onSavePublisher(index) {
      const { id, publisherName, scalability } = this.mediaPublisherData[index];
      this.errorText = '';
      if (!publisherName) {
        this.errorText = 'Please enter publisher name';
        return;
      }
      if (!scalability) {
        this.errorText = 'Scalability must be greater than 0';
        return;
      }
      if (publisherName) {
        this.isLoading = true;
        try {
          if (id) {
            await mediaPublisherApi.updatePublisher(id, {
              ...this.mediaPublisherData[index],
            });
          } else {
            const newData = await mediaPublisherApi.createPublisher({
              ...this.mediaPublisherData[index],
            });
            this.mediaPublisherData[index] = newData.data;
          }
        } catch (e) {
          console.error(e);
        }
        this.isLoading = false;
        this.mediaPublisherData[index].readonly = true;
      }
    },
    onDiscardChanges(index) {
      this.errorText = '';
      if (this.tempPublisherData.id) {
        this.mediaPublisherData[index] = { ...this.tempPublisherData };
        this.tempPublisherData = {};
        this.mediaPublisherData[index].readonly = true;
      } else {
        this.mediaPublisherData.splice(index, 1);
      }
      this.$forceUpdate();
    },
    getPubRulesDataString(data) {
      if (data.isNew) {
        return '';
      }

      const array = [];
      if (data?.deals?.length > 0) {
        const dealsString = data?.deals?.map((d) => d?.dealId).join(', ');
        if (!isBlank(dealsString)) {
          array.push(`DIDs: ${dealsString}`);
        }
      }
      if (data?.mediaTypes?.length > 0) {
        const mediaTypeString = data?.mediaTypes?.map((d) => d?.productCategory?.name).join(', ');
        if (!isBlank(mediaTypeString)) {
          array.push(`Media Types: ${mediaTypeString}`);
        }
      }

      return array.length > 0 ? array.join('\n') : 'Click to add';
    },
    getPubRulesDataTooltip(data) {
      const tooltip = this.getPubRulesDataString(data);
      return tooltip === 'Click to add' ? '' : tooltip;
    },
    onSearchTextChange(val) {
      this.searchedText = val;
      this.mediaPublisherData.forEach((data) => {
        let didMdString = '';
        didMdString = data?.deals?.map((d) => d?.dealId).join(',');
        if (
          data?.publisherName?.toLowerCase()?.includes(val.toLowerCase()) ||
          data?.streamingCategory?.toLowerCase()?.includes(val.toLowerCase()) ||
          didMdString.includes(val)
        ) {
          data.hide = false;
        } else {
          data.hide = true;
        }
      });
    },
    onAddNewPub() {
      this.mediaPublisherData.unshift({
        deals: [],
        logoURL: '',
        mediaTypes: [],
        publisherName: '',
        scalability: 0,
        streamingCategory: '',
        readonly: false,
        hide: false,
        isNew: true,
      });
    },
    onDidMediaTypeClick(payload, index) {
      this.showDidModal = { show: true, payload: { ...payload, index } };
    },
    updateDealPayload(modalPayload, updatedPayload) {
      const { index } = modalPayload;
      const { publisherName, streamingCategory, scalability, logoURL } = this.mediaPublisherData[
        index
      ];
      this.mediaPublisherData[index] = {
        ...updatedPayload,
        publisherName,
        streamingCategory,
        scalability,
        logoURL,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .k-btn span.label {
  color: var(--adminovertext);
}
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
::v-deep .cl-subcat.form-input .form-input-field {
  height: 40px;
}
.pub-manage-system {
  padding-top: 0px !important;
  .pub-manage-system-header {
    margin-bottom: 25px;
  }
}
.list-box {
  top: 13px;
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  display: block;
  min-width: 900px;
  max-width: 900px;
  min-height: 500px;
  margin: 0 auto;
}
.publish-detail-table {
  width: 95%;
  margin: 0px 8px;
  .com-title {
    // font-size: 15px;
    font-weight: 400;
    line-height: 1.1em;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    font-size: 18px;
    color: #cad1d6;
    margin-bottom: 20px;
    margin-left: 12px !important;
    margin-right: 30px !important;
    padding: 10px 10px 10px 0;
    span {
      text-transform: capitalize;
    }
  }
  .sub-li {
    margin-left: 12px !important;
    margin-right: 30px !important;
    padding: 10px 10px 10px 0;
    margin-top: 15px;
    font-size: 14px;
    vertical-align: middle;
    &.description span {
      width: 150px;
    }
    &.name span {
      width: 80px;
    }
    &.did span {
      width: 120px;
      white-space: pre;
    }
    &.scalability span {
      width: 60px;
    }

    span {
      &.intip4.action-btn {
        overflow: unset;
      }
      color: rgba(130, 130, 140, 0.8);
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
    }
  }
}
::v-deep .sub-li.name .form-input .form-input-field {
  width: 80px;
}
::v-deep .sub-li.description .form-input .form-input-field {
  width: 150px;
}
::v-deep .sub-li.logo .form-input .form-input-field {
  width: 150px;
}
::v-deep .sub-li.scalability .form-input.form-number-input {
  width: 60px;
}
::v-deep .sub-li.scalability .form-input.form-number-input .form-input-field {
  padding: 3px;
  color: #cad1d6;
  font-size: 14px;
  background-color: #323239;
}
::v-deep .sub-li.scalability .form-input.form-number-input .form-input-arrow-container {
  display: none;
}
::v-deep .sub-li .form-input .form-input-field {
  width: 80%;
  float: left;
  height: 30px;
  font-size: 0.875rem;
  display: block;
  min-height: 40px;
  padding: 8px 10px;
  color: #cad1d6;
  background-color: #323239;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgba(170, 170, 170, 0.1);
}
@media screen {
  * {
    box-sizing: border-box;
  }
  .intip4 {
    position: relative;
    top: 0;
  }
  // .intip4.action-btn {
  //   color: #fff;
  // }
  .intip4[data-tool-tip]::after {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 99;
    display: block;
    min-width: 140px;
    padding: 10px 12px;
    margin-left: -50px;
    font-size: 12px;
    line-height: 1.2em;
    text-align: center;
    pointer-events: none;
    content: attr(data-tool-tip);
    border-radius: 3px;
    transition: ease 0.3s;
    transform: scale(0);
    transform-origin: bottom center;
  }
  .intip4[data-tool-tip]::after {
    width: auto;
    margin-left: -61px;
    font-size: 12px;
    font-weight: 400;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
  }
  .intip4[data-tool-tip]:hover::after {
    bottom: 20px;
    transform: scale(1);
  }
}
.box.px-2 {
  display: flex;
  justify-content: space-evenly;
}
// .publish-detail-table .sub-li span.intip4.action-btn {
//   color: #fff;
// }
.highlight-txt span {
  color: var(--adminprimarycolor) !important;
  text-decoration: underline !important;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.pub-manage-system-header {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}

::v-deep .pub-manage-search.form-input label {
  display: none;
}
::v-deep .pub-manage-search.form-input .fa-search {
  position: absolute;
  right: 13px;
  bottom: 13px;
  color: #bdbdbd;
}

.pub-manage-search {
  width: 250px;
  position: relative;
}
.hide-row {
  display: none;
}
.publisher-logo {
  height: 40px;
  width: 40px;
  margin-right: 25px;
}
.pub-manage-system {
  position: relative;
}
.txt-center {
  position: absolute !important;
  margin-top: 15px;
  font-size: 13px;
  color: #bac0c5;
  text-align: center !important;
  top: 200px;
  margin: 0px auto;
  z-index: 10;
  width: 100%;
}
.error-text {
  text-align: center;
  padding: 10px;
  font-size: 13px;
  color: #fc4242;
  letter-spacing: 0.9px;
}
::v-deep .confirm-modal-publisher .modal-wrapper-box {
  padding-bottom: 0 !important;
  display: flex !important;
  align-items: center !important;
}
::v-deep .confirm-modal-publisher .selfServBox .modal-container {
  min-height: 30px !important;
  /* min-width: 300px !important; */
  max-width: unset !important;
  min-width: unset !important;
  width: 100% !important;
  color: #a1a5af !important;
}

::v-deep .confirm-modal-publisher .modal-mask .selfServBox .modal-container .modal-footer {
  background-color: unset !important;
  text-align: center !important;
}
::v-deep .confirm-modal-publisher .modal-mask .selfServBox .modal-container .container-text {
  text-align: center;
}
::v-deep .confirm-modal-publisher .modal-mask .selfServBox {
  overflow-x: hidden !important;
}
</style>
