import { ApiCrud } from 'adready-api/api/api-crud';
import http from 'adready-api/api/http';

const advertiserApi = new ApiCrud(http.AXIOS, {
  createAdvertiser: 'POST advertisers',
  editAdvertiser: 'PUT advertisers/:advertiserId',
  getAdvertiser: 'GET advertisers/:advertiserId',
  getIOsAndLIs:
    'GET /api/advertisers/:advertiserId/brand_lift/campaign/:campaign_id/line_items_with_spc_groupings',
  advertiserUpdateEmailNotification:
    'POST advertisers/:advertiserId/advertiserUpdateEmailNotification',
  pixelReadyEmailNotification: 'POST advertisers/:advertiserId/pixelReadyEmailNotification',
});
export default advertiserApi;
