<template>
  <div
    ref="b-select-checkbox"
    class="dropdown noselect"
    style="margin-right: 20px"
    tabindex="1"
    :class="[{ disabled }, className]"
  >
    <div
      :id="componentId"
      class="select"
      :class="[showDropdown ? 'open' : '', selectedVal, overrideClass]"
      @click="showDropdown = !showDropdown"
    >
      <div class="selected-opt-wrapper tooltip">
        <span class="selected-opt-header">{{ header ? `${header}: ` : '' }}</span>
        <font-awesome-icon
          v-if="selectedTextIcon"
          class="selected-icon"
          :icon="[selectedTextIconStyle, selectedTextIcon]"
          size="1x"
        />
        <span class="selected-opt">{{ selectedVal }}</span>
        <span v-if="showToolTip" class="tooltiptext">{{ selectedVal }}</span>
      </div>
      <font-awesome-icon
        :icon="['far', showDropdown ? 'chevron-up' : 'chevron-down']"
        class="droparw"
      />
    </div>
    <ul v-if="showDropdown" class="dropdown-menu">
      <li
        v-for="(option, i) in options"
        :key="i"
        :class="[option.showStar ? 'rollup-option' : 'norm-option', option.name || option]"
        @click="
          $emit('change', option.id || option, option.name || option);
          showDropdown = false;
        "
      >
        <div v-if="option.showStar" class="roll-preset">
          <font-awesome-icon :icon="['fas', 'star']" />
        </div>
        <font-awesome-icon
          v-if="option.icon"
          class="icon"
          :icon="[option.iconStyle || 'far', option.icon]"
          size="1x"
        />
        {{ option.name || option }}
        <span v-if="option.infoText" :data-tool-tip="option.infoText" class="intip4">
          <font-awesome-icon :icon="['fas', 'info-circle']" />
        </span>
        <div v-if="option.children && option.children.length">
          <span v-for="(child, ci) in option.children" :key="ci" class="psub">{{ child }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: () => 'Select',
    },
    componentId: {
      type: String,
      required: false,
      default: () => '',
    },
    header: {
      type: String,
      required: false,
      default: () => '',
    },
    selected: {
      type: [String, Number, Array, Object, Boolean],
      required: false,
      default: 'None',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    showToolTip: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedTextIconStyle: {
      type: String,
      required: false,
      default: 'far',
    },
    selectedTextIcon: {
      type: String,
      required: false,
      default: '',
    },
    overrideClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    selectedVal() {
      const select = this.options.find((opt) => opt.id === this.selected) || {};

      if (select.name) {
        return select.name;
      }
      if (this.selected?.displayText) {
        return this.selected?.displayText;
      }

      if (
        this.selected &&
        typeof this.selected === 'string' &&
        this.selected.toUpperCase() === 'NONE' &&
        this.options.length
      ) {
        // this.selected = this.options[0];
        return this.options[0].name || this.options[0];
      }

      return this.selected;
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },

  methods: {
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    },
    closeDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.psub {
  display: block;
  padding-left: 10px;
  font-size: 12px;
  color: #a4acbb;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.intip4 {
  position: relative;
  top: 0;
  display: inline-block;
  margin-left: 8px;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
.intip4[data-tool-tip]::after {
  width: 155px;
  margin-left: -70px;
  font-size: 12px;
  font-weight: 400;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 1);
}
.intip4[data-tool-tip]:hover::after {
  bottom: -145%;
  transform: scale(1);
}
.selected-opt-header {
  font-size: 14px;
  color: #707e8a;
}
.selected-opt-wrapper {
  display: inline-block;
}
.selected-opt {
  // display: inline-block;
  width: 90%;
  overflow: hidden;
  font-size: 14px;
  color: #cad1d6;
  text-overflow: ellipsis;
  white-space: nowrap;
}
svg.reverse {
  transform: rotate(180deg);
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
svg {
  float: right;
}
ul li svg {
  position: relative;
  font-size: 18px;
  color: #cbccd2;
  border: none;
  fill: #cbccd2;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  * {
    outline: 0;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    min-width: 200px;
    height: 100%;
    font-size: 14px;
    color: #fff;
    text-align: left;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    border-radius: 4px;
    transition: all 0.3s ease;
    -webkit-touch-callout: none;
  }
  .light-theme {
    .selected-opt-wrapper {
      svg {
        color: #555 !important;
        &.check-off {
          color: transparent !important;
        }
      }
    }
    .left-active-tab-cont {
      .dropdown .select {
        background-color: #fff !important;
        border: 1px solid #edeefb !important;
        border-bottom: 0px !important;
        border-radius: 5px 5px 0px 0px !important;
        padding-bottom: 3px !important;
      }
    }
    .right-active-tab-cont {
      .dropdown .select {
        background-color: #fff !important;
        border: 1px solid #edeefb !important;
        border-bottom: 0px !important;
        border-radius: 5px 5px 0px 0px !important;
        padding-bottom: 3px !important;
      }
    }
    .selected-opt {
      color: #212325;
    }
    .dropdown.b-select-dd {
      .select {
        background-color: transparent;
        border: 1px solid rgba(170, 170, 170, 0.1);
        &.open {
          background-color: #383a44;
        }
      }
      .selected-opt-header {
        color: #707e8a;
      }
      .selected-opt {
        color: #cad1d6;
      }
      .droparw {
        color: #fff;
      }
      .dropdown-menu {
        background-color: rgba(45, 46, 53, 0.98);
        box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
        li {
          color: #cad1d6;
          &:hover {
            background-color: rgba(255, 255, 255, 0.04);
          }
        }
        .norm-option .icon {
          color: #cad1d6;
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        background-color: #f9f9f9;
        li {
          color: #555;
          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
        .norm-option .icon {
          color: #555;
        }
      }
      .select {
        background-color: #ffffff;
        border: 1px solid #c5d2d8;
        color: #555;
        &.open {
          background-color: #ffffff;
        }
      }
    }
  }
  .dropdown .select {
    display: block;
    min-height: 40px;
    padding: 8px 10px;
    text-transform: capitalize;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid rgba(170, 170, 170, 0.1);
    border-radius: 4px;
    &.open {
      background-color: #383a44;
    }
  }
  .greyBackground {
    background-color: #30323a;
  }
  .droparw {
    position: relative;
    top: 5px;
    float: right;
    font-size: 11px;
    line-height: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .dropdown .dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 9;
    width: 100%;
    max-height: 250px;
    margin-top: 0;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(45, 46, 53, 0.98);
    border-radius: 0 1px 4px 4px;
    box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.1);
  }
  .dropdown .dropdown-menu li {
    padding: 10px 17px;
    font-size: 13px;
    color: #cad1d6;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    svg {
      font-size: 17px;
    }
  }
  .dropdown .dropdown-menu {
    padding: 0;
    list-style: none;
  }
  .dropdown .dropdown-menu li:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .dropdown .dropdown-menu li:active {
    background-color: #333;
  }
  .roll-preset {
    display: inline-block;
    padding: 0px 4px;
    margin-right: 6px;
    font-size: 10px;
    font-weight: 600;
    color: #cad1d6;
    text-transform: uppercase;
    background-color: var(--primarycolor);
    border-radius: 12px;
    svg {
      top: 0;
      width: 0.625rem !important;
    }
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}
.tooltip {
  position: relative;
}
.tooltip .tooltiptext {
  visibility: hidden;
  max-width: 200px;
  min-width: 100px;
  width: auto;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
  text-align: center;
  border-radius: 3px;
  transition: ease 0.3s;
  line-height: 1.2em;
  transform-origin: bottom center;
  padding: 5px;
  position: absolute;
  z-index: 10;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
