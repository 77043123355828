/* eslint-disable func-names */
<template>
  <div class="wrap-campaign">
    <input ref="b-campaign-datepicker-inp" readonly autocomplete="off" />
    <font-awesome-icon :icon="['far', 'chevron-down']" />
  </div>
</template>

<script>
import $ from 'jquery';
import { get } from 'vuex-pathify';
import '../../util/b-campaign-datepicker-jq';
import { DATEPICKER_RANGE_OPTIONS } from '~/constant';

export default {
  props: {
    week1: {
      type: Array,
      required: false,
      default: () => [new Date(new Date().setDate(new Date().getDate() - 29)), new Date()],
    },
    week2: {
      type: Array,
      required: false,
      default: () => [
        new Date(new Date().setDate(new Date().getDate() - 29)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
    },
  },
  data() {
    return {
      minCampaignDate: '',
    };
  },

  computed: {
    pixelDates: get('dashboard/pixelDates'),
  },
  mounted() {
    const vm = this;

    $.curCSS = function(element, prop, val) {
      return $(element).css(prop, val);
    };

    const to = new Date();

    $(vm.$refs['b-campaign-datepicker-inp']).CampaignDatePicker({
      inline: false,
      date: [this.pixelDates.startDate, this.pixelDates.endDate],
      date2: [this.pixelDates.startDate, this.pixelDates.endDate],
      comparable: true,
      compareWith: false,
      allowToday: true,
      calendars: 3,
      mode: 'range',
      disableDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      position: 'bottom',
      instanlyApply: false,
      className: 'pixel-container',
      constants: {
        DATEPICKER_RANGE_OPTIONS: JSON.parse(JSON.stringify(DATEPICKER_RANGE_OPTIONS)),
      },
      current: new Date(to.getFullYear(), to.getMonth() - 1, 1),
      language: 'en', // en is default,
      onChange(dates, something, compareWith, dateRangeOption, dateCompareOption) {
        vm.$emit('change', { dates, compareWith, dateRangeOption, dateCompareOption });
      },
      onAfterHide: () => {
        $(vm.$refs['b-campaign-datepicker-inp']).DatePickerSetDate({
          date: [this.pixelDates.startDate, this.pixelDates.endDate],
          date2: [this.pixelDates.startDate, this.pixelDates.endDate],
        });
      },
      getDefaultOptions: () => {
        return {
          startDate: this.pixelDates.startDate,
          endDate: this.pixelDates.endDate,
          compareWith: false,
          dateRangeOption: this.pixelDates.dateRangeOption,
        };
      },
      getMinCampaignDate: () => {
        return this.minCampaignDate;
      },
    });
  },
  beforeDestroy() {
    const vm = this;
    $(vm.$refs['b-campaign-datepicker-inp']).DatePickerHide();
  },
};
</script>

<style lang="scss" scoped>
.wrap-campaign {
  .drp-wrap {
    padding: 6px 12px !important;
  }
  .drp-wrap > input {
    width: 100%;
    min-width: 216px;
    max-width: unset;
    height: auto !important;
    padding: 0 !important;
    margin-bottom: 0;
    line-height: normal !important;
    font-size: 14px;
    color: #60697b;
    cursor: pointer;
    letter-spacing: 0.03em;
    background: none !important;
    border: none;
    outline: none;
    box-shadow: none !important;
    font-family: sans-serif;
  }
  .drp-wrap:hover {
    background-color: transparent;
    border: unset !important;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 600px) {
  /*div.datepicker {margin: 10px 0px 0px -496px;}
*/

  .datepicker {
    max-width: 250px !important;
    margin: 10px 0px !important;
  }
  .datepickerBlock .cal-picker-wrap,
  .datepickerSpace,
  td.datepickerBlock.cal-picker-wrap {
    display: none !important;
  }
  .compare {
    padding: 0px 0px 0px 0px;
  }
}
.wrap-campaign {
  position: relative;
  min-width: 255px;
  display: flex;
  .fa-chevron-down {
    position: absolute;
    right: 37px;
    top: 9px;
    width: 9px;
  }
}

/* Datepicker border styling */
.datepicker {
  padding: 8px 30px 10px;
  background-color: #edeef1;
  border: 0px solid #cccccc;
  border-radius: 4px;
}
.datepicker.pixel-container {
  top: 160px !important;
}
.datepickerContainer {
  margin-left: -12px;
}

.cal-prevnext {
  position: relative;
  top: 1px;
  font-size: 18px;
}

.datepickerGoNext span,
.datepickerGoPrev span {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  font-size: 10px;
}

.datepickerGoNext span:hover,
.datepickerGoPrev span:hover {
  opacity: 0.7;
}
.datepickerGoPrev span:before {
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -10px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  color: #fff;
  content: '\25C0';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.datepickerGoNext span:before {
  position: absolute;
  top: 50%;
  right: 6px;
  margin-top: -10px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  color: #fff;
  content: '\25BA';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

/* Tighten up the spacing between multiple calendars */
.datepickerSpace div {
  width: 2px;
}

/* Grey background behind Month Name row, and blue text */
div.datepicker th {
  background-color: #dddddd;
}

div.datepicker th a.datepickerMonth {
  font-size: 1em;
  font-weight: normal;
  color: #ffffff;
}

/* style the day of week header cells */
div.datepicker th {
  font-weight: bold;
  color: #f00;

  /* cursor: pointer; */
}

/* grey underline beneath day of week row */
div.datepicker table thead tr.datepickerDoW th {
  border-bottom: 1px solid #cccccc;
}

/* Black text, white background, and grey borders around day cells */
div.datepicker a,
div.datepicker a:hover {
  color: #787878;
}

div.datepicker table.datepickerViewDays,
div.datepicker table.datepickerViewMonths,
div.datepicker table.datepickerViewYears {
  background-color: white;
}
table.datepickerViewDays {
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

div.datepicker table tbody.datepickerDays td {
  // border: 1px solid #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

/* blue cell color for selected days */
tbody.datepickerDays td.datepickerSelected {
  background-color: #0077cc;
}

/* Grey out dates in the future */
div.datepicker td.datepickerFuture a {
  color: #dddddd;
  pointer-events: auto;

  &:hover:after {
    content: 'Data is available up to two days prior to the current date';
    position: fixed;
    font-size: 10px;
    padding: 5px;
    background-color: dimgray;
    color: white;
    opacity: 0.8;
    border-radius: 5px;
    z-index: 99999 !important;
  }
}

/* yellow cell background on hover */
div.datepicker tbody.datepickerDays td:hover {
  background-color: #ddd;
}

/* Bold today's date */
div.datepicker td.datepickerToday a {
  font-weight: bold;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
}

/* cells are wider in the clean style */
.datepickerYears a,
.datepickerMonths a {
  width: 42px;
}

.datepickerMonths td:first-child a,
.datepickerYears td:first-child a {
  width: 39px;
}

div.datepicker th a.datepickerMonth {
  width: 121px;
}

div.datepicker {
  // position: relative;
  position: absolute;
  top: 122px !important;
  left: 0;
  z-index: 999;
  display: none;
  width: 196px;
  height: 207px !important;
  margin: 10px 0px 0px 244px;

  /*font-family: Arial, Helvetica, sans-serif;*/
  font-size: 12px;
  cursor: default;
  border: 1px solid #fff;
  box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.4);
}

.datepickerContainer {
  position: absolute;
}

/* Hide border divs by default */
// .datepickerBorderT,
// .datepickerBorderB,
// .datepickerBorderL,
// .datepickerBorderR,
// .datepickerBorderTL,
// .datepickerBorderTR,
// .datepickerBorderBL,
// .datepickerBorderBR {
//   display: none;
// }

.datepickerHidden {
  display: none;
}

div.datepicker table {
  border-collapse: collapse;
}

div.datepicker a {
  color: #787878;
  text-decoration: none;
  cursor: default;
  outline: none;
}

div.datepicker table td {
  padding: 1px 1px !important;
  margin: 0;
  text-align: center;
  widows: 100%;
}

div.datepicker th {
  padding: 3px;
  font-size: 0.91em;
  font-weight: normal;
  // color: #666666;

  /* padding: 5px 5px; */
  color: #2f323a;
  text-align: center;
  background: white;

  /* border-bottom: 1px solid #eee!Important; */
}

div.datepicker thead tr:first-child th.hmtitle {
  height: 26px;
  padding: 3px;
  font-size: 1em;
  background-color: #2f323a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

div.datepicker tbody a {
  display: block;
  cursor: pointer;
}

.datepickerDays a {
  width: 20px;
  height: 16px;
  padding-right: 2px;
  line-height: 16px;
}

.datepickerYears a,
.datepickerMonths a {
  width: 39px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}

.datepickerMonths td:first-child a,
.datepickerMonths td:last-child a,
.datepickerYears td:first-child a,
.datepickerYears td:last-child a {
  width: 38px;
}

td.datepickerNotInMonth a {
  color: #666666;
  pointer-events: none !important;
}

tbody.datepickerDays td.datepickerSelected {
  background: var(--primarycolor);
}

tbody.datepickerDays td.datepickerNotInMonth.datepickerSelected {
  background: #2f323a;
}

tbody.datepickerYears td.datepickerSelected,
tbody.datepickerMonths td.datepickerSelected {
  background: #17384d;
}

div.datepicker td.datepickerSelected a {
  color: white;
}

div.datepicker a:hover,
div.datepicker a:hover {
  color: #586167;
}

div.datepicker td.datepickerNotInMonth a:hover {
  color: #999999;
}

.datepickerSpace div {
  width: 10px;
}

.datepickerBlock {
  vertical-align: top;
}

a.datepickerGoNext,
a.datepickerGoPrev,
a.datepickerMonth {
  float: left;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

div.datepicker th a.datepickerGoNext,
div.datepicker th a.datepickerGoPrev {
  display: none;
  width: 19px;
  // color: #666666;
  color: #ffffff;
}

div.datepicker th a.datepickerMonth {
  display: block;
  width: 113px;
}

/* Only display the previous/next arrows on the first/last calendars */
div.datepicker .datepickerFirstView th a.datepickerGoPrev,
div.datepicker .datepickerLastView th a.datepickerGoNext {
  display: block;
}

div.datepicker .datepickerLastView th a.datepickerGoNext {
  float: right;
}

/* Compensate for the space lost by not having a previous arrow */
div.datepicker th a.datepickerMonth {
  margin-left: 20px;
}

div.datepicker .datepickerFirstView th a.datepickerMonth {
  margin-left: 0;

  /* margin-right: 20px; */
}

/* Day mode calendar body */
table.datepickerViewDays tbody.datepickerMonths,
table.datepickerViewDays tbody.datepickerYears {
  display: none;
}

/* Month mode calendar body */
table.datepickerViewMonths tbody.datepickerDays,
table.datepickerViewMonths tbody.datepickerYears,
table.datepickerViewMonths tr.datepickerDoW {
  display: none;
}

/* Year mode calendar body */
table.datepickerViewYears tbody.datepickerDays,
table.datepickerViewYears tbody.datepickerMonths,
table.datepickerViewYears tr.datepickerDoW {
  display: none;
}

td.datepickerDisabled a,
td.datepickerDisabled.datepickerNotInMonth a {
  color: #555555;
}

td.datepickerDisabled a:hover {
  color: #333333;
}

/* Not used by default, calendar cells can be marked as special if desired (doesn't seem to be totally working) */
div.datepicker tbody.datepickerDays td.datepickerSpecial a {
  color: white;
  background: #770000;
}

div.datepicker tbody.datepickerDays td.datepickerSpecial.datepickerSelected a {
  background: #aa0000;
}

/* style the 'not in month' day cell away */
tbody.datepickerDays td.datepickerNotInMonth.datepickerSelected {
  background-color: transparent;
}

div.datepicker td.datepickerNotInMonth a span {
  display: none;
}

div.datepicker td.datepickerNotInMonth a:hover span {
  display: none;
}

div.datepicker tbody.datepickerDays td.datepickerNotInMonth:hover {
  background-color: transparent;
}

.compare {
  /*width: 220px;
  padding: 0px 0px 0px 20px;
  margin: -4px 10px 0 0;*/
  width: 220px;
  padding: 0;
  margin: -4px 0 0 10px;
  font-size: 11px;
  text-align: left;
  border-radius: 0px;
}

.compare .form-group {
  margin-bottom: 5px;
}

.compare .control-label {
  font-size: 11px;
}

.compare input[type='text'],
.compare select {
  height: auto;
  min-height: inherit;
  padding: 5px 5px;
  font-size: 11px;
  line-height: normal;
  color: #565a65;
  text-align: left;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-radius: 3px;

  /* background: #fff; */
  -webkit-box-shadow: inset 0 1px 0 #d6d6d6;
  -moz-box-shadow: inset 0 1px 0 #d6d6d6;
  box-shadow: inset 0 1px 0 #d6d6d6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.compare div[campaign-data-id='firstinputgroup'] input[type='text'].focus,
.compare div[campaign-data-id='firstinputgroup'] select.focus {
  border: var(--primarycolor) solid 2px;
  -webkit-box-shadow: 0px 0px 3px #8c8c8c;
  -moz-box-shadow: 0px 0px 3px #8c8c8c;
  box-shadow: 0px 0px 3px #8c8c8c;
}

.compare div[campaign-data-id='secondinputgroup'] input[type='text'].focus,
.compare div[campaign-data-id='secondinputgroup'] select.focus {
  border: #8c97ad solid 2px;
  -webkit-box-shadow: 0px 0px 3px #8c8c8c;
  -moz-box-shadow: 0px 0px 3px #8c8c8c;
  box-shadow: 0px 0px 3px #8c8c8c;
}

.compare input[type='text'].disabled,
.compare select.disabled {
  /* background: red; */
  color: #d0d0d0;
  border-top: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
}

.compare select:disabled {
  opacity: 0.4;
}

.selectwrap {
  position: relative;
  display: inline-block;
  // width: 100%;
  width: 239px;
}

.selectwrap:after {
  position: absolute;
  top: 50%;
  right: 25px;
  margin-top: -8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  color: #222;
  pointer-events: none;
  content: '\2039';
  transform: rotate(-90deg);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

[campaign-data-id^='campaign-datedistance'],
[campaign-data-id^='selectcompare'] {
  display: inline-block;
  width: 96%;
}

[campaign-data-id^='firstinputgroup'] .col-xs-6,
[campaign-data-id^='secondinputgroup'] .col-xs-6 {
  display: inline-block;
  width: 48%;
  vertical-align: top;
}
.col-xs-6 .form-control.disabled {
  opacity: 0.8 !important;
}

[campaign-data-id^='date1s'],
[campaign-data-id^='date1e'],
[campaign-data-id^='date2s'],
[campaign-data-id^='date2e'] {
  display: inline-block;
  width: 98%;
}
[campaign-data-id^='date1e'],
[campaign-data-id^='date2e'] {
  float: right;
}

[campaign-data-id^='chkcompare'] {
  position: absolute;
}
.compare .checkbox {
  margin-bottom: 5px;
}

/* .compare .btn {
        line-height: normal;
        font-size: 12px;
        padding: 4px 8px;
    }*/

.compare .btn {
  display: inline-block;
  padding: 5px 8px;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--primarycolor);
  border: 0px;
  -webkit-border-radius: 0px;
  // border-radius: 0px;
  border-radius: 3px;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-touch-callout: none;
}
.btn.pull-right {
  margin-right: 8px;
  background-color: #c1c9d0;
}
.compare .btn-primary {
  margin-top: 1px;
  margin-right: 9px;
}

.compare .btn:hover {
  color: #fff !important;
  background-color: #424242 !important;
}

.compare .form-horizontal .control-label {
  padding-top: 3px;
  margin-bottom: 0;
  text-align: left;
}

.compare .checkbox label,
.radio label {
  display: block;
  min-height: 10px;
  padding-left: 20px;
  margin-right: 0;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.compare hr {
  display: none;
  margin-top: 5px;
  margin-bottom: 10px;
}

tbody.datepickerDays td.datepickerSelected2:not(.datepickerNotInMonth) {
  background: #8c97ad;
}

tbody.datepickerDays td.datepickerSelected.datepickerSelected2:not(.datepickerNotInMonth) {
  background: var(--primarycolor);

  /* color: red; */
}

table.datepickerViewDays {
  margin-top: 14px;
}

div.datepicker td.datepickerSelected2 a {
  color: white;
}

div.datepicker .compare .checkbox {
}

div.datepicker .compare .checkbox label:before {
  display: none !important;
  background-color: Transparent !important;
  border: none;
}

div.datepicker .compare .checkbox input[type='checkbox'] {
  display: block;
  margin-top: 2px;
  margin-left: -18px;
}

.daterangecomparepicker {
  max-width: 400px;
  padding-left: 35px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  background: url(/content/assets/plugins/date-range-comparison-picker/calendar24px.png) no-repeat
    5px center;
  background-size: 16px;
  border: 1px solid #ccc;
}

.white-date .daterangecomparepicker {
  color: #607d8b;
  background-color: none;
}

.white-date.single-mode {
  min-height: 37px;
  padding-left: 35px !important;
  vertical-align: top;
  cursor: pointer;
  background: url(/content/assets/plugins/date-range-comparison-picker/calendar24px.png) no-repeat
    15px center !important;
  background-size: 16px !important;
  border: 1px solid #e5e9ec;
  // -webkit-border-radius: 0px;
  // -moz-border-radius: 0px;
  // -webkit-border-radius: 2px;
  // -moz-border-radius: 2px;
  // border-radius: 2px;
  // border-radius: 0px;
  border-radius: 2px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.white-date.single-mode .drp-wrap {
  border: none;
}

.gray-date .daterangecomparepicker {
  color: #fff;
  background: url(/content/assets/plugins/date-range-comparison-picker/calendar16px-white.png)
    no-repeat 5px center;
  background-color: #607d8b;

  /*min-width: 400px;*/
}
.wrap-campaign {
  .drp-wrap {
    position: relative;
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #fff;
    background-image: none;
    border-bottom: 0px;
    border-radius: 4px;
    margin-right: 10px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-family: sans-serif;
  }
}

.controls .drp-wrap > input {
  min-height: 27px;
}

.drp-wrap .compare-label {
  bottom: 0px;
  font-size: 75%;
  color: rgba(129, 140, 165, 1);
  text-align: left;
  cursor: pointer;
}

.drp-wrap .compare-label > span {
  margin-left: 5px;

  /* bottom: 0px; */

  /* font-size: 80%; */
  color: rgb(129 140 165);
  cursor: pointer;
}

.drp-wrap {
  background-color: transparent;
  border: 1px solid transparent;
}

.datepicker {
  max-width: 800px;
}
.datePickerDateDisabled {
  opacity: 0.4;
  pointer-events: none;
}
.input-group {
  display: flex;
  gap: 13px;
}
</style>
