<template>
  <div class="form-input form-number-input">
    <input
      ref="input"
      :name="name"
      :type="type"
      :value="value"
      class="form-input-field"
      :min="min"
      step="1"
      pattern="[0-9]"
      :required="required"
      :maxlength="maxLength"
      :readonly="readonly"
      @change="onChange"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @keyup="onKeyUp"
      @blur="onBlur"
    />
    <div class="form-input-arrow-container">
      <font-awesome-icon
        class="up-arrow"
        :icon="['far', 'chevron-up']"
        size="1x"
        @click="onArrowClick(1)"
      />
      <font-awesome-icon
        class="up-arrow"
        :icon="['far', 'chevron-down']"
        size="1x"
        @click="onArrowClick(-1)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BNumberInput',
  props: {
    name: {
      required: false,
      type: String,
      default: null,
    },
    value: {
      required: false,
      type: [String, Number, Array],
      default: undefined,
    },
    maxLength: {
      required: false,
      type: Number,
      default: 524288,
    },
    min: {
      required: false,
      type: Number,
      default: 0,
    },
    enableSetEmpty: {
      required: false,
      type: Boolean,
      default: false,
    },
    type: {
      required: false,
      type: String,
      default: 'text',
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    icon: {
      required: false,
      type: Array,
      default: null,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isNumericalInput(e) {
      if (e.target.type === 'number' && !e.target.value.match(/^[0-9]+$/)) {
        return false;
      }
      return true;
    },

    onChange(ev) {
      if (!ev.target.value && this.enableSetEmpty) {
        this.$emit('change', '');
        return;
      }
      if (!this.isNumericalInput(ev)) {
        ev.target.value = Math.round(+ev.target.value.replace(/[^\d.-]/g, ''));
      }
      this.$emit('change', Math.round(+ev.target.value));
      this.$emit('input', Math.round(+ev.target.value));
    },

    onKeyUp(ev) {
      if (!ev.target.value && this.enableSetEmpty) {
        this.$emit('keyup', '');
        return;
      }
      if (!this.isNumericalInput(ev)) {
        ev.target.value = Math.round(+ev.target.value.replace(/[^\d.-]/g, ''));
      }
      this.$emit('keyup', Math.round(+ev.target.value), ev);
    },

    onBlur(ev) {
      if (!ev.target.value && this.enableSetEmpty) {
        this.$emit('blur', '');
        return;
      }
      if (!this.isNumericalInput(ev)) {
        ev.target.value = Math.round(+ev.target.value.replace(/[^\d.-]/g, ''));
      }
      this.$emit('blur', Math.round(+ev.target.value));
    },

    onFocusIn(ev) {
      if (!ev.target.value && this.enableSetEmpty) {
        this.$emit('focusin', '');
        return;
      }
      if (!this.isNumericalInput(ev)) {
        ev.target.value = Math.round(+ev.target.value.replace(/[^\d.-]/g, ''));
      }
      this.$emit('focusin', Math.round(+ev.target.value));
    },

    onFocusOut(ev) {
      if (!ev.target.value && this.enableSetEmpty) {
        this.$emit('focusout', '');
        return;
      }
      if (!this.isNumericalInput(ev)) {
        ev.target.value = Math.round(+ev.target.value.replace(/[^\d.-]/g, ''));
      }
      this.$emit('focusout', Math.round(+ev.target.value));
    },
    onArrowClick(action) {
      let tempValue = this.value ? this.value : 0;
      if (action === 1) {
        tempValue += 1;
      } else {
        tempValue -= 1;
      }
      if (tempValue >= 0) {
        this.$emit('change', Math.round(tempValue));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-number-input {
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 60px;
  .form-input-field {
    padding-right: 20px !important;
  }
  .form-input-arrow-container {
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    right: 6px;
    top: 8px;
    font-size: 12px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
