const methods = {
  clearAdvanceFilters(filtersObj, isApplyClearFilter = false) {
    const newFilterObj = { ...filtersObj };
    const filterOptionsArray = [
      'publisherOptions',
      'audienceOptions',
      'creativeOptions',
      'mediaTypeOptions',
    ];
    filterOptionsArray.forEach((filter) => {
      if (filter === 'mediaTypeOptions') {
        newFilterObj[filter] = filtersObj.mediaTypeOptions.map((ff) => {
          ff.checked = false;
          return ff;
        });
      } else {
        newFilterObj[filter] = [];
      }
    });
    if (isApplyClearFilter) {
      this.$store.set('dashboard/filters@audienceOptions', []);
      this.$store.set('dashboard/filters@creativeOptions', []);
      this.$store.set('dashboard/filters@publisherOptions', []);
      this.$store.set(
        'dashboard/filters@mediaTypeOptions',
        filtersObj.mediaTypeOptions.map((ff) => {
          ff.checked = false;
          return ff;
        })
      );
    }
    return newFilterObj;
  },
};

export default {
  methods,
};
