<template>
  <div :class="`${isReadonly ? 'readonly-info-section' : ''}`">
    <p class="acc-info-title">Account Information</p>
    <div class="s2-info-wrap">
      <div class="mvc-half mvc-half-left ">
        <div class="input-box-format">
          <div class="ss-form-label2">Company</div>
          <k-text-input
            :max-length="255"
            :required="true"
            :value="accInfo.name"
            @change="(val) => handleValueChange(val, 'name')"
          />
        </div>
      </div>
      <div class="mvc-half mvc-half-right ">
        <div class="input-box-format">
          <div class="ss-form-label2">Industry</div>
          <k-text-input
            :required="true"
            :max-length="255"
            :value="accInfo.industry"
            :placeholder="'e.g. heath & wellness, apparel & footware, etc.'"
            @change="(val) => handleValueChange(val, 'industry')"
          />
        </div>
      </div>
    </div>
    <div class="s2-info-wrap">
      <div class="mvc-half mvc-half-left ">
        <div class="input-box-format">
          <div class="ss-form-label2">Company Size (Employees)</div>
          <k-select
            :options="companySizeOptions"
            label-key="displayCompanySize"
            :max-length="255"
            :multiple="false"
            :value="accInfo.companySize"
            @input="(val) => handleValueChange(val, 'companySize')"
          />
        </div>
      </div>
      <div class="mvc-half mvc-half-right ">
        <div class="input-box-format">
          <div class="ss-form-label2">Annual Paid Advertising Budget</div>
          <k-select
            :options="annualBudgetOptions"
            label-key="displayCompanySize"
            :max-length="255"
            :multiple="false"
            :value="accInfo.annualBudget"
            @input="(val) => handleValueChange(val, 'annualBudget')"
          />
        </div>
      </div>
    </div>
    <div class="s2-info-wrap">
      <div class="mvc-half mvc-half-left ">
        <div class="input-box-format">
          <div class="ss-form-label2">Advertiser Domain</div>
          <k-text-input
            :max-length="255"
            :required="true"
            :value="accInfo.domainName"
            @input="(val) => handleValueChange(val, 'domainName')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';

export default {
  name: 'AccInformation',
  props: {
    advertiserData: {
      type: Object,
      required: true,
      default: () => {},
    },
    updateAdvertiser: {
      type: Function,
      required: true,
      default: () => {},
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      companySizeOptions: ['0-10', '10-50', '50-100', '100-500', '500+'],
      annualBudgetOptions: ['<$1m', '$1-2m', '$2-5m', '$5-10m', '$10m+'],
      accInfo: {
        name: this.advertiserData?.name || null,
        domainName: this.advertiserData?.domainName || null,
        annualBudget: this.advertiserData?.annualBudget || null,
        companySize: this.advertiserData?.companySize || null,
        industry: this.advertiserData?.industry || null,
      },
    };
  },
  watch: {
    advertiserData: {
      handler(nv, ov) {
        if (!isEqual(nv, ov)) {
          this.accInfo = {
            name: nv?.name || null,
            domainName: nv?.domainName || null,
            annualBudget: nv?.annualBudget || null,
            companySize: nv?.companySize || null,
            industry: nv?.industry || null,
          };
        }
      },
    },
  },

  methods: {
    handleValueChange(val, type) {
      if (type === 'domainName') {
        const validDomainRegex = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;
        if (!val.match(validDomainRegex)) {
          this.$emit('handleShowGenericModal', {
            showModal: true,
            title: 'Account Info',
            message: 'Please enter valid domain',
          });
          return;
        }
      }
      this.updateAdvertiser(val, type);
    },
  },
};
</script>

<style lang="scss" scoped>
.readonly-info-section {
  pointer-events: none;
}
.acc-info {
  &-title {
    font-size: 20px;
    color: #48525c;
    margin-bottom: 25px;
  }
}
.mvc-form-wrap3 {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  color: #48525c;
  width: 860px;
  min-height: 680px;
}

.body-sec-title {
  font-size: 1.6em;
  font-weight: 500;
  color: var(--selfserveprimarycolor);
}

.mvc-half {
  display: inline-block;
  width: 48%;
}
.mvc-half-left {
  margin-right: 3%;
}
.mvc-half-right {
  float: right;
}
.pure-input-1 {
  width: 100%;
  height: 50px !important;
  padding: 10px 18px !important;
  font-size: 14px;
  font-weight: 400;
}
.sec-title {
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
  color: #444;
  font-size: 20px;
  letter-spacing: 0.02em;
}
.flex-wrap {
  display: flex;
  justify-content: space-between;
  & .custom-dropdown-cont {
    width: 31%;
    margin-right: unset;
  }
}
.s2-info-wrap {
  width: 852px;
  margin: 0px 0px 15px 0px;

  display: flex;
}
.ss-form-label2 {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #48525c;
  text-align: left;
  vertical-align: baseline;
  border: 0;
}
.ss-third {
  display: inline-block;
  margin-right: 2%;
  vertical-align: top;
}

.vc-half-f-left {
}
.vc-half-s-right {
  display: inline-block;
  width: 50%;
  padding-left: 20px;
}
.mvc-flex {
  display: inline-flex !important;
}
::v-deep .input-box-format .form-input .form-input-field {
  border-radius: 5px;
  background: #f7f9fd;
  border: 1px solid #d3d6dd;
  font-size: 14px;
  color: #48525c;
  font-weight: 500;
}
::v-deep .input-box-format .form-input .form-input-field.v-select .vs__dropdown-toggle {
  border-radius: 5px;
  background: #f7f9fd;
  font-size: 14px;
  color: #48525c;
  font-weight: 500;
}
::v-deep .input-box-format .form-input-wrap .form-click {
  border-radius: 5px;
  background: #f7f9fd;
  font-size: 14px;
  color: #48525c;
  font-weight: 500;
  border: 1px solid #d3d6dd;
}
::v-deep .input-box-format .form-input .form-input-field.v-select .vs__search {
  color: #48525c;
  font-size: 14px;
}
::v-deep .input-box-format .form-input-wrap .form-click .selected-text {
  color: #48525c;
  font-size: 14px;
}
::v-deep .input-box-format .form-input label {
  display: none;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  color: #48525c;
  font-size: 14px;
}
::v-deep .checkbox-btn svg.checkbox-inactive {
  color: #fff !important;
  width: unset;
  border: 1px solid #d3d6dd;
  border-radius: 3px;
  width: 18px;
  height: 18.5px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
</style>
