import plansApi from 'adready-api/api/plans';
import {
  IOS_LIVE,
  LIS_CANCELED,
  LIS_ENDED,
  IOS_COMPLETED,
  LIS_NEW,
  IOS_NEW,
  IOS_DRAFT,
  IOS_CHANGE_NEW,
  IOS_CHANGE_READY_FOR_ACTIVATION,
} from 'adready-api/constant';
import deepcloneHelpers from 'adready-api/helpers/deepclone-helpers';
import { Plan } from 'adready-api/model/plan';
import { buildQueryString } from 'adready-api/helpers/url-helpers';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import moment from 'moment';
import advertiserReportsApi from '~/api/advertiser-reports';
import config from '~/config';
import lineItemHelpers from '~/helpers/line-item-helpers';
import store from '~/store';

import { FLIP_CAMPAIGN_INFO_DEFAULT, LUCID_CAMPAIGN_INFO_DEFAULT } from '~/constant';
import { capitalizeStringSentence } from '~/util/utility-functions';

function handleError(error, errorMessage) {
  if (!errorMessage) {
    errorMessage = 'Request Failed. Try again or contact support.';
  }
  this.$emit('showErrorModal', errorMessage, error); // TODO
}
function isLucidViable(impressionCount) {
  return impressionCount > config.LUCID_IMPRESSION_COUNT;
}
function isLucidFormDataProvided(lucidCampaignInfo) {
  if (!lucidCampaignInfo || !lucidCampaignInfo.brandLiftInfo) return 'no';
  let score = 2;
  // For each piece of information that is missing, keep reducing the score.
  if (!lucidCampaignInfo.brandLiftInfo.category) score--;
  if (
    !lucidCampaignInfo.brandLiftInfo.competitors ||
    lucidCampaignInfo.brandLiftInfo.competitors.length <= 0
  )
    score--;
  // Not using lucidCampaignInfo.type for now, otherwise score should start as 3.
  // if (!lucidCampaignInfo.type || !lucidCampaignInfo.type.value) score--;
  switch (score) {
    case 2:
      return 'yes';
    case 0:
      return 'no';
    default:
      return 'partial';
  }
}
function doesPlanHaveCR(plan) {
  return plan.crPlans && plan.crPlans.length > 0;
}
function isFieldChangedInCR(plan, field) {
  if (!doesPlanHaveCR(plan)) {
    return false;
  }
  if (isBlank(plan.crPlans[0][field])) {
    return false;
  }
  const original = JSON.stringify(plan[field]);
  const changed = JSON.stringify(plan.crPlans[0][field]);

  return original !== changed;
}
function getPlanFieldValue(plan, field) {
  return isFieldChangedInCR(plan, field) ? plan.crPlans[0][field] : plan[field];
}
function getPlanFieldBothValues(plan, field, plans) {
  const value = { originalValue: plan[field] };
  if (plan.state === 'CHANGE') {
    const parentPlan = plans.find((p) => p.id === plan.parentId);
    const crPlan =
      !isBlank(parentPlan.crPlans) &&
      parentPlan.crPlans.find((p) =>
        [IOS_CHANGE_NEW, IOS_CHANGE_READY_FOR_ACTIVATION].includes(p.statusId)
      );
    if (!isBlank(crPlan)) {
      const mergePlan = deepcloneHelpers.mergePlan(parentPlan, crPlan);
      value.changedValue = plan[field];
      value.originalValue = mergePlan[field];
    } else {
      value.changedValue = plan[field];
      value.originalValue = parentPlan[field];
    }
  }
  return value;
}
function calculateImpressionCount(plan, mediaPublisherCPMData) {
  if (isBlank(plan.lineItems)) {
    return 0;
  }
  const totalImpressions = plan.lineItems.reduce((s, l) => {
    const { impressions } = lineItemHelpers.calculateLineItemCPMAndImpressions(
      l,
      mediaPublisherCPMData
    );
    return s + impressions;
  }, 0);

  return totalImpressions;
}
async function fetchBrandLiftCampaigns(plan, account) {
  let accountName = '';
  let advertiserName = '';
  let campaign = null;
  const advertiserId = plan ? plan.advertiserId : null;
  if (account && plan) {
    const advertiser = account.advertisers.find((a) => a.id === plan.advertiserId);
    accountName = account.name ? account.name : '';
    advertiserName = advertiser && advertiser.name ? advertiser.name : '';
  } else {
    return campaign;
  }
  const payload = {
    client: accountName,
    advertiser: advertiserName,
    io: plan ? plan.campaignName : '',
    intCampaignId: plan.id ? plan.id : 0,
  };

  const apiData = await advertiserReportsApi.fetchBrandLiftCampaigns(
    advertiserId || 0,
    buildQueryString(payload)
  );
  if (!isBlank(apiData)) {
    campaign = apiData[0].campaign;
  }
  return campaign;
}
async function isLucidCampaignCreated(plan, account) {
  const lucidCampaign = await this.fetchBrandLiftCampaigns(plan, account);
  if (!isBlank(lucidCampaign) && !isBlank(lucidCampaign.campaign_id)) {
    return true;
  }
  return false;
}
async function isLucidCampaignLaunched(plan, account) {
  const lucidCampaign = await this.fetchBrandLiftCampaigns(plan, account);
  if (
    !isBlank(lucidCampaign) &&
    !isBlank(lucidCampaign.campaign_id) &&
    lucidCampaign.status.toLowerCase() !== 'draft'
  ) {
    return true;
  }

  return false;
}

/**
 * Save or create (if ID not set) the given plan
 *
 * @param {Plan} plan
 *
 * @returns {Promise}
 */
async function saveOrCreatePlan(plan) {
  plan.flip = false;
  if (plan.id && plan.id > 0) {
    // have an id, update
    return plansApi.update(plan.id, plan);
  }
  return plansApi.create(plan);
}

function addUpdatePlan(plan) {
  // if (!this.canConfirmChanges) {
  //   return;
  // }

  // save without line items
  // this is primarily when cloning a plan, we don't want to save the line items immediately
  const planObj = { ...plan, lineItems: [] };
  return saveOrCreatePlan(planObj)
    .then(async (updated) => {
      if (plan.show && plan.id === null) {
        // saved a new plan, so we now have an ID
        // assign it to the line items
        plan.lineItems.forEach((li) => {
          store.dispatch('io/updateLineItem', {
            _uuid: li._uuid,
            lineItem: {
              planId: updated.id,
            },
          });
        });
      }

      // store updated plan
      const newPlan = {
        ...updated,
        // restore line items in unsaved state
        lineItems: plan.lineItems,
        // restore internal vars
        updatedPlan: false,
        show: plan.show,
        _uuid: plan._uuid,
        _selected: plan._selected,
      };
      if (!plan.id) {
        newPlan._new = true;
      }
      store.dispatch('io/updatePlans', { _uuid: newPlan._uuid, plan: newPlan });
      return newPlan;
    })
    .catch((error) => {
      handleError(error, 'Failed to save or update IO. Try again or contact support.');
      store.dispatch('io/updatePlans', { _uuid: plan._uuid, plan: { ...plan, updatedPlan: true } });
    });
}

function deletePlan(plan) {
  if (plan.id) {
    plansApi
      .delete(plan.id)
      .then(() => {
        store.dispatch('io/deletePlan', { _uuid: plan._uuid });
      })
      .catch((error) => {
        handleError(error, 'Failed to delete IO. Try again or contact support.');
      });
  } else {
    store.dispatch('io/deletePlan', { _uuid: plan._uuid });
  }
}

/**
 *
 * @param {*} plan
 * @param {*} lineItems
 * Change of IO status on below conditions.
 * case 1: All LineItems of the IO are in NEW status, IO status would be NEW.
 * case 2: Any of the lineItem of the IO having status 'PENDING ACTIVATION' or greater, IO Status would be 'ACTIVATED'
 * case 3: All LineItems of the IO are in 'ENDED' or 'CANCELED' status, IO status would be 'COMPLETED'.
 */
export function changePlanStatusOnLineItemsBasis(plan, lineItems) {
  let ioStatusChange = null;

  const hasAllLineItemsCanceledOrEnded = !lineItems.some(
    (l) => ![LIS_CANCELED, LIS_ENDED].includes(l.statusId)
  );
  if (hasAllLineItemsCanceledOrEnded && plan.statusId !== IOS_COMPLETED) {
    ioStatusChange = IOS_COMPLETED;
  }

  const hasAllLineItemsNew = !lineItems
    .filter((l) => l.type !== 'CHANGE')
    .some((l) => LIS_NEW !== l.statusId);

  if (hasAllLineItemsNew && isBlank(ioStatusChange) && plan.statusId !== IOS_NEW) {
    ioStatusChange = IOS_NEW;
  }

  if (isBlank(ioStatusChange) && plan.statusId !== IOS_LIVE) {
    ioStatusChange = IOS_LIVE;
  }
  if (!isBlank(ioStatusChange)) {
    const flagOptions = store.get('common/options@flagOptions');
    const flagOption = commonHelper.flagOption(ioStatusChange, flagOptions);
    const flagId = flagOption ? flagOption.id : null;
    store.dispatch('io/updatePlans', {
      _uuid: plan._uuid,
      plan: { statusId: ioStatusChange, flagId },
      touch: false,
    });
    const updatedPlan = store.getters['io/getPlan'](plan.id);
    addUpdatePlan(updatedPlan);
  }
}
/**
 * * this is to create default basic campaign.
 * @param {*} accountId : account id
 * @param {*} advertiserId : advertiser id
 * @returns new campaign.
 */
function createDefaultCampaign(accountId, advertiserId) {
  const campaign = new Plan({
    state: 'IO',
    accountId,
    advertiserId,
    statusId: IOS_DRAFT,
    flightStartDate: null,
    flightEndDate: null,
    updatedPlan: true,
    flip: true,
    flipCampaignInfo: { ...FLIP_CAMPAIGN_INFO_DEFAULT },
    lucidCampaignInfo: { ...LUCID_CAMPAIGN_INFO_DEFAULT },
    brandSafetyLevelId: 3,
    verificationPartners: { value: [1, 2] },
  });

  return campaign;
}
export const validateCampaignName = (name) => {
  if (isBlank(name)) {
    return 'Campaign name missing';
  }
  return '';
};
export const validateFlightDates = (flightStartDate, flightEndDate, type) => {
  if (isBlank(flightStartDate)) {
    if (type === 'start') {
      return 'Campaign start date missing';
    }
    return 'Campaign end date missing';
  }
  if (
    (!isBlank(flightEndDate) || !isBlank(flightStartDate)) &&
    moment(flightStartDate, moment.ISO_8601).isAfter(moment(flightEndDate, moment.ISO_8601))
  ) {
    return 'Campaign start date must not be greater than campaign end date';
  }
  return '';
};
export const validateCampaignInfo = (flipCampaignInfo, nodeName = '') => {
  const validationObj = {};
  let goal = '';
  let conversionWindow = 0;
  let optimization = {};
  let methodology = '';
  if (flipCampaignInfo) {
    goal = flipCampaignInfo?.goal?.name;
    conversionWindow = flipCampaignInfo?.conversionWindow?.value
      ? Number(flipCampaignInfo?.conversionWindow?.value)
      : 0;
    optimization = flipCampaignInfo.optimization;
    methodology = flipCampaignInfo?.methodology?.value;
  }

  if (isBlank(goal)) {
    validationObj.goal = 'Campaign goal missing';
  }
  if (conversionWindow === 0) {
    validationObj.conversionWindow = 'Conversion window must be greater than 0';
  }

  const keys = ['attribution', 'impressions', 'weightage'];
  if (optimization) {
    const missingFields = [];
    keys.forEach((k) => {
      const obj = optimization[k];
      if (isBlank(obj?.value)) {
        const fieldLabel = capitalizeStringSentence(k);
        missingFields.push(fieldLabel);
        validationObj[k] = `${fieldLabel} missing`;
      }
    });

    if (missingFields.length) {
      validationObj.optimization = `${missingFields.join(', ')} missing`;
    }
  }

  if (isBlank(methodology)) {
    validationObj.methodology = 'Attribution methodology missing';
  }

  if (nodeName) {
    return validationObj?.[nodeName] ?? '';
  }
  return validationObj;
};
function validateCampaign(plan) {
  let validationObj = { isValidated: true };

  const campaignName = getPlanFieldValue(plan, 'campaignName');
  const campaignNameError = validateCampaignName(campaignName);
  if (campaignNameError) {
    validationObj.campaignName = campaignNameError;
  }
  const flightStartDate = getPlanFieldValue(plan, 'flightStartDate');
  const flightStartDateError = validateFlightDates(flightStartDate, '', 'start');
  if (flightStartDateError) {
    validationObj.flightStartDate = flightStartDateError;
  }
  const flightEndDate = getPlanFieldValue(plan, 'flightEndDate');
  const flightEndDateError = validateFlightDates(flightEndDate, '', 'end');
  if (flightEndDateError) {
    validationObj.flightEndDate = flightEndDateError;
  }
  if (!isBlank(flightStartDate) && !isBlank(flightEndDate)) {
    if (moment(flightStartDate).isAfter(moment(flightEndDate))) {
      validationObj.flightStartDate =
        'Campaign start date must not be greater than campaign end date';
    }
  }

  const flipCampaignInfo = getPlanFieldValue(plan, 'flipCampaignInfo');
  const flipCampaignInfoErrors = validateCampaignInfo(flipCampaignInfo);
  validationObj = { ...validationObj, ...flipCampaignInfoErrors };
  if (Object.keys(validationObj).length > 1) {
    validationObj.isValidated = false;
  }
  return validationObj;
}

function validateLucidData(plan, activeBrandLiftInfo) {
  const validationObj = { isValidated: true };

  // if competitors and category both are empty then no need to perform validation
  const lucidCampaignInfo = { brandLiftInfo: activeBrandLiftInfo };
  if (isLucidFormDataProvided(lucidCampaignInfo) === 'no') {
    return validationObj;
  }

  const flipCampaignInfo = getPlanFieldValue(plan, 'flipCampaignInfo');
  if (isBlank(flipCampaignInfo) || flipCampaignInfo === null) {
    validationObj.flipCampaignInfo = 'Flip Campaign Info cannot be empty';
  }

  if (activeBrandLiftInfo?.competitors?.length !== 4) {
    validationObj.competitors = 'Exactly 4 competitors required';
  }
  if (isBlank(activeBrandLiftInfo?.category)) {
    validationObj.blCategory = 'Category missing';
  }
  if (isBlank(activeBrandLiftInfo?.type?.id)) {
    validationObj.typeId = 'Product type missing';
  }

  if (Object.keys(validationObj).length > 1) {
    validationObj.isValidated = false;
  }
  return validationObj;
}

function calculateDatesForDashboardSwitch(flightStartDate, flightEndDate, flipCampaignInfo) {
  const endDate = new Date(new Date().setDate(new Date().getDate() - 1));

  const conversionWindowValue = flipCampaignInfo?.conversionWindow?.value
    ? Number(flipCampaignInfo?.conversionWindow?.value)
    : 31;

  const conversionWindowType = flipCampaignInfo?.conversionWindow?.unit?.value;
  let calculatedEndDate =
    isBlank(conversionWindowType) || conversionWindowType === 'days'
      ? moment(flightEndDate).add(conversionWindowValue, 'days')
      : moment(flightEndDate).add(conversionWindowValue, 'hours');

  calculatedEndDate = moment(endDate).isSameOrBefore(calculatedEndDate)
    ? endDate
    : calculatedEndDate;
  let calculatedCompareEndDate = moment(calculatedEndDate).subtract(1, 'days');

  calculatedEndDate = moment(flightStartDate).isSameOrAfter(calculatedEndDate)
    ? flightStartDate
    : calculatedEndDate;
  calculatedCompareEndDate = moment(flightStartDate).isSameOrAfter(calculatedCompareEndDate)
    ? flightStartDate
    : calculatedCompareEndDate;

  const dateObj = {
    startDate: flightStartDate,
    endDate: calculatedEndDate,
    compareStartDate: flightStartDate,
    compareEndDate: calculatedCompareEndDate,
  };
  return dateObj;
}

function getMethodology(methodology) {
  if (methodology === 'first touch') {
    return 'first_touch';
  }
  if (methodology === 'linear') {
    return 'linear';
  }
  if (methodology === 'time decay') {
    return 'time_decay';
  }
  return 'last_touch';
}

export default {
  saveOrCreatePlan,
  addUpdatePlan,
  deletePlan,
  handleError,
  isLucidViable,
  isLucidFormDataProvided,
  calculateImpressionCount,
  fetchBrandLiftCampaigns,
  isLucidCampaignCreated,
  isLucidCampaignLaunched,
  doesPlanHaveCR,
  isFieldChangedInCR,
  getPlanFieldValue,
  getPlanFieldBothValues,
  createDefaultCampaign,
  validateCampaign,
  validateLucidData,
  validateCampaignName,
  validateFlightDates,
  validateCampaignInfo,
  calculateDatesForDashboardSwitch,
  getMethodology,
};
