<template>
  <b-flip-modal
    :header="true"
    :enable-click-out-side="false"
    :header-text="'Flip+ Terms and Conditions'"
    :is-self-serve-modal="true"
    @close="onClickCancel"
  >
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="tnc-container">
          <p class="tnc-subtitle term"><span>Term</span> Duration of Platform Usage</p>
          <p class="tnc-subtitle scope">
            <span>Scope</span> Campaign building, managing, execution, optimization, and reporting
          </p>
          <p class="tnc-subtitle pricing">
            <span>Pricing</span> 20% margin on media and data, Net 30
          </p>
          <p class="tnc-list-heading">Scope of work & Services provided:</p>
          <ul class="tnc-list">
            <li class="tnc-list-text">
              <span>Campaign Building:</span> Digital Remedy will grant Advertiser access to Flip+
              Platform and allow client to create OTT campaigns, select performance goals, add
              targeting, and upload creatives.
            </li>
            <li class="tnc-list-text">
              <span>Campaign Managing:</span> Advertiser will retain ability to pause, cancel, or
              edit their current and future campaigns at any time within the scope allowed by the
              Flip+ platform. Note that this does not include the ability to alter bidding strategy
              or bid price.
            </li>
            <li class="tnc-list-text">
              <span>Media Buying and Optimizing:</span> Digital Remedy will purchase media on behalf
              of Advertiser in accordance with the parameters outlined in Campaign Builder. Digital
              Remedy will optimize campaigns daily toward the goal outline in Campaign Builder.
            </li>
            <li class="tnc-list-text">
              <span>Account Management:</span> Digital Remedy will assign a dedicated account
              manager to assist will all Flip+ campaigns.
            </li>
            <li class="tnc-list-text">
              <span>Reporting:</span> All campaign reporting will be available in Flip+ Platform
              Dashboard. This includes both delivery and performance metrics and updates daily. Ad
              hoc reporting is available upon request and subject to review.
            </li>
            <li class="tnc-list-text">
              <span>Turnaround Times:</span> Campaigns can be expected to go live within 48 hours of
              submission, subject to creative approval. If creatives are rejected, Advertiser will
              be notified within 48 hours of campaign submission. Campaigns can be expected to go
              live within 48 hours of creative resubmission, subject again to creative approval.
            </li>
            <li class="tnc-list-text">
              <span>Under-delivery:</span> Digital Remedy cannot guarantee full delivery of
              campaigns. Account managers will reach out if Advertiser’s campaign is at risk of
              underdelivery.
            </li>
            <li class="tnc-list-text">
              <span>Over-delivery:</span> Advertiser will not be billed for media purchased that
              exceeds Campaign Budget.
            </li>
            <li class="tnc-list-text">
              <span> Make-Goods:</span> In the instance an Advertiser feels their campaign
              expectations have not been met, Advertiser may request a make-good. All make-goods are
              subject to review and are not guaranteed to be approved.
            </li>
            <li class="tnc-list-text">
              <span>Campaign Scope:</span> Digital Remedy will, in no way, offer or exceed
              capabilities outlined in Flip+ Platform. All available media, targeting, creative, and
              reporting capabilities are limited to what is available within the Flip+ Platform.
            </li>
            <li class="tnc-list-text">
              <span>Data and Privacy:</span> All Advertiser pixel, campaign, and reporting data will
              be limited to Advertiser’s instance of Flip+. This data will not be shared with any
              third parties, and is protected under Digital Remedy’s general data privacy and
              protection policy.
            </li>
            <li class="tnc-list-text">
              <span>Platform Discontinuation:</span> If Advertiser wishes to discontinue use of
              Flip+ Platform, they need simply stop running campaigns. Advertiser Account can be
              deleted from Flip+ Platform upon request.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div class="actions-btn">
        <k-button :size="3" label="CLOSE" class="mr-20" @click="onClickCancel" />
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
export default {
  name: 'TncModal',
  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '~/components/elements/b-flip-modal.vue'),
  },
  props: {
    hideConfirmBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    onClickRemove(ev) {
      this.$emit('remove', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
// .action-ftr {
//   display: inline-flex;
//   justify-content: space-between;
//   padding: 12px 0px;
// }
::v-deep .modal-container {
  width: 800px !important;
  padding: 20px 0px 0px 0px !important;
  overflow-x: hidden;
}
::v-deep .modal-header.row.end-xs {
  display: none;
}
.tnc {
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: start;
  }
  &-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &-subtitle {
    margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: 300px;

    & span {
      font-weight: 600;
    }
    &.scope {
      width: 614px;
    }
    &.pricing {
      width: 384px;
    }
  }
  &-list {
    margin-left: 0;
    margin-top: 20px;
    padding-right: 10px;
    list-style-type: none;
    &-text {
      font-size: 14px;
      padding-bottom: 15px;
      & span {
        font-weight: 600;
      }
    }
    &-text::before {
      //content: '\2022';
      font-size: 20px;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
    &-heading {
      text-decoration: underline;
      font-weight: 600;
      font-size: 16px;
      margin-top: 15px;
    }
  }
}
.mr-20 {
  margin-right: 20px;
}
</style>
