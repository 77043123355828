import { get } from 'vuex-pathify';
import planApi from 'adready-api/api/plans';
import { IO_STATE_CHANGE } from 'adready-api/constant';
import { SELF_SERVE_OWNER_EMAIL } from '~/constant';

const computed = {
  allUsers: get('common/allUsers'),
};

const methods = {
  sendEmailNotification(plan, notes) {
    const emailData = {
      emails: [],
      notes: '',
      additionalEmails: [],
      skipNotification: false,
      emailSource: 'FLIPPLUS',
    };

    if (plan.state === IO_STATE_CHANGE) {
      emailData.emails = emailData.emails.concat([SELF_SERVE_OWNER_EMAIL]);
      planApi.updateStatusEmailNotification(plan.id, emailData);
    } else {
      const req = {
        planId: plan.id,
        emailIds: SELF_SERVE_OWNER_EMAIL,
        notes,
        emailSource: 'FLIPPLUS',
      };
      const ownerUser = this.allUsers.find((item) => item.id === plan.ownerId);
      if (ownerUser) {
        req.emailIds = ownerUser.email;
      }
      planApi.export(plan.id, req);
    }
  },
  sendUpdateStatusEmailNotification(plan, notes) {
    const emailData = {
      emails: [],
      notes: notes || '',
      additionalEmails: [],
      skipNotification: false,
      emailSource: 'FLIPPLUS',
    };
    emailData.emails = emailData.emails.concat([SELF_SERVE_OWNER_EMAIL]);
    planApi.updateStatusEmailNotification(plan.id, emailData);
  },
  sendExportEmail(plan, lineItemId, notes) {
    const req = {
      planId: plan.id,
      emailIds: SELF_SERVE_OWNER_EMAIL,
      notes,
      lineItemId,
      emailSource: 'FLIPPLUS',
    };
    const ownerUser = this.allUsers.find((item) => item.id === plan.ownerId);
    if (ownerUser) {
      req.emailIds = ownerUser.email;
    }
    planApi.export(plan.id, req);
  },
};

export default {
  computed,
  methods,
};
