<!-- Flip Pixel Verification section when selected from the menu on the left -->
<template>
  <div class="flip-pixel-verification-container" :class="showPixelVerify() ? 'more-top' : ''">
    <div class="ss-main-wrap">
      <div class="ss-c" style="display: block; padding: 0px 50px 40px;">
        <div style="height: 20px;"></div>
        <div class="cd-pop-hd1" style="margin-top: 30px;">
          {{ pageTitle }}
        </div>

        <div class="flp-top-panels" style="margin-top: 20px;">
          <div class="filter-panel-top top-pan pan1">
            <div class="pan-content-top pan1-content-top">
              <div class="fright-filter-container filters-top">
                <div>
                  <b-select-checkbox
                    :options="categoryOpts"
                    :apply-white-labeling="addWhiteLabeling"
                    header="Categories "
                    style="width: 170px; margin-right: 0; margin-left: 2px;"
                    @selected-options="onSelectCategoryOptions"
                  />
                </div>
                <div>
                  <b-select-checkbox
                    :options="subCategoryOpts"
                    :apply-white-labeling="addWhiteLabeling"
                    header="Subcategories "
                    style="width: 170px; margin-right: 0; margin-left: 5px;"
                    @selected-options="onSelectSubCategoryOptions"
                  />
                </div>
              </div>
            </div>
            <div class="pan-content-top pan1-content-top">
              <b-pixel-datepicker @change="setDatePickerDate" />
            </div>
          </div>
        </div>

        <div class="flp-top-panels" style="margin-top: 20px;">
          <div class="flp-panel top-pan pan1">
            <div class="pan-content pan1-content">
              <div class="flp-panel-title">Today</div>
              <b-numero
                v-if="dataStillLoading === 0"
                class="flp-panel-amt"
                :value="summary.todaysCount"
                type="VISITS"
              />
              <div v-else class="flp-panel-amt"><span class="big">&nbsp;</span></div>
            </div>
            <!-- fpan-content-->
            <div class="pan-content pan1-content">
              <div class="flp-panel-title">Yesterday</div>
              <b-numero
                v-if="dataStillLoading === 0"
                class="flp-panel-amt"
                :value="summary.yesterdaysCount"
                type="VISITS"
              />
              <div v-else class="flp-panel-amt"><span class="big">&nbsp;</span></div>
            </div>
            <div class="pan-content pan1-content">
              <div class="flp-panel-title">Average</div>
              <b-numero
                v-if="dataStillLoading === 0"
                class="flp-panel-amt"
                :value="summary.average"
                type="VISITS"
              />
              <div v-else class="flp-panel-amt"><span class="big">&nbsp;</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-n-filter-container">
        <div class="fright">
          <div class="fright-reverify-container">
            <span class="recent-pixel-activity-txt">Recent Pixel Activity </span>
          </div>
        </div>
        <flip-line-chart
          v-if="!loadingChart && columns.length"
          :columns="columns"
          :tooltip="tooltip"
          :hide-legends="true"
        />
        <div v-if="!loadingChart && !columns.length" class="txt-center">
          No data is available to show
        </div>
        <b-loading-spinner v-if="loadingChart" class="txt-center" />
        <div v-if="!loading && table.length" class="table-wrap" style="margin-top: 40px;">
          <table class="pixel-val-table" style="">
            <tbody class="pvt-body">
              <tr>
                <th class="stat3-header">pixel timestamp (EST)</th>
                <th class="stat3-header">ORDER NUMBER</th>
                <th class="stat3-header">CHECKOUT VALUE</th>
                <th class="stat3-header">category</th>
                <th class="stat3-header">sub category</th>
                <th class="stat3-header">event name</th>
                <th class="stat3-header">event action</th>
                <th class="stat3-header">referrer url</th>
              </tr>
              <tr v-for="(row, ci) in table" :key="ci" class="pvtr">
                <td class="pvtd">
                  <span class="pixelTimeStamp" :title="row.pixelTimeStamp">{{
                    row.pixelTimeStamp
                  }}</span>
                </td>
                <td class="pvtd">
                  <span :title="row.orderNumber">{{ row.orderNumber }}</span>
                </td>
                <td class="pvtd">{{ row.checkoutValue }}</td>
                <td class="pvtd">
                  <span :title="row.category">{{ row.category }}</span>
                </td>
                <td class="pvtd">{{ row.subCategory }}</td>
                <td class="pvtd">{{ row.gaEventName }}</td>
                <td class="pvtd">{{ row.gaEventAction }}</td>
                <td class="pvtd">
                  <span class="gaReferrerUrl" :title="row.gaReferrerUrl">{{
                    row.gaReferrerUrl
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!loading && table.length" class="export-tbl">
          <font-awesome-icon :icon="['fas', 'arrow-to-bottom']" class="fa-w-12" />
          Export
          <font-awesome-icon :icon="['fas', 'chevron-down']" class="fa-w-14 drparw2" />
          <select id="exp-drp" v-model="exportValue" name="exp-drp" @change="download">
            <option v-for="exports in exportOptions" :key="exports.id" :value="exports.id">{{
              exports.name
            }}</option>
          </select>
        </div>
        <div v-if="!loading && table.length" class="pagination">
          <div class="pagination-content">
            Items per page:
            <select v-model="limitRowCount" class="pag-amt">
              <option v-for="value in rowCountLimits" :key="value" :value="value">{{
                value
              }}</option>
            </select>
            {{ showPagerText() }}
            <font-awesome-icon :icon="['fa', 'chevron-left']" class="pointer" @click="gotoPrev" />
            <font-awesome-icon :icon="['fa', 'chevron-right']" class="pointer" @click="gotoNext" />
          </div>
        </div>
        <b-loading-spinner v-if="loading" class="txt-center" />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import moment from 'moment';
import fileDownload from 'js-file-download';
import appConsoleDataLayer from '@/api/app-console-data';
import { PAGINATION_OPTIONS } from '~/constant';
import BPixelDatepicker from '~/components/elements/b-pixel-datepicker.vue';
import { formatDateForAPI } from '~/util/apiDateFormat';
import formatNumber from '~/util/numeral';

export default {
  name: 'FlipPixelVerification',
  components: {
    BNumero: () => import(/* webpackChunkName: "b-numero" */ '~/components/elements/b-numero.vue'),
    BSelectCheckbox: () => import('~/components/elements/b-select-checkbox.vue'),
    BPixelDatepicker,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    FlipLineChart: () =>
      import(/* webpackChunkName: "line-chart" */ '~/components/charts/flip-line-chart.vue'),
  },
  props: {
    pixelId: {
      type: String,
      required: false,
      default: null,
    },
    updateAdvertiser: {
      type: Function,
      required: false,
      default: () => {},
    },
    showPixelVerify: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
    checkPixelVerified: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
  },

  data() {
    return {
      dataStillLoading: 1,
      summary: {},
      table: [],
      totalRowCount: 0,
      rowOffset: 0,
      limitRowCount: 25,
      days: 1,
      category: 'all',
      subcategory: 'all',
      loading: false,
      loadingChart: false,
      subCategoryOpts: [],
      categoryOpts: [],
      rowCountLimits: PAGINATION_OPTIONS,
      distinctData: [],
      exportValue: '',
      columns: [],
      tooltip: [],
      exportOptions: [
        { id: 1000, name: 'Export 1,000 rows' },
        { id: 10000, name: 'Export 10,000 rows' },
        { id: 100000, name: 'Export 100,000 rows' },
      ],
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    pixelDates: get('dashboard/pixelDates'),
    payload() {
      return {
        category: this.category,
        subCategory: this.subcategory,
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        xandrId: this.advertiser ? this.advertiser.xandrAdvertiserId : '',
      };
    },
    pageTitle() {
      return 'Universal Pixel Verification';
    },
    addWhiteLabeling() {
      const isSelfServe =
        this.account?.advertisers?.length === 1 ? this.account?.advertisers[0]?.selfServe : false;
      if (!isSelfServe && this.account?.organization?.whitelabelEnabled) {
        return true;
      }
      return false;
    },
  },
  watch: {
    limitRowCount(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadTableData();
      }
    },
    exportValue(newVal) {
      this.onChangeExportValue(newVal);
    },
  },
  created() {
    this.$on('on-select-advertiser', this.onSelectAdvertiser);
  },
  beforeDestroy() {
    this.$off('on-select-advertiser', this.onSelectAdvertiser);
  },
  async mounted() {
    this.loadSummaryData();
    this.loadTableData();
    this.loadPageData();
    this.loadOptions();
    this.checkPixelVerified();
  },
  methods: {
    async fetchDailyPixelFires(payload) {
      try {
        this.loadingChart = true;
        if (this.advertiser && this.advertiser.id && this.pixelId) {
          const localPayload = {
            ...payload,
          };
          delete localPayload.advertiser;
          delete localPayload.client;
          delete localPayload.xandrId;
          localPayload.startDate = formatDateForAPI(new Date(this.pixelDates.startDate));
          localPayload.endDate = formatDateForAPI(new Date(this.pixelDates.endDate));

          const data = await appConsoleDataLayer.flipDailyPixelFires(
            this.advertiser.id,
            this.pixelId ? this.pixelId : '',
            localPayload
          );
          this.processResults(data, localPayload);
        }
      } catch (err) {
        console.error('error in fetching daily pixel fires ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loadingChart = false;
      }
    },
    async processResults(res) {
      this.loadingChart = false;
      this.totalCount = res.total;
      const days = ['days'];
      const last7Days = ['Pixel Fires'];
      const tooltip = [];

      res.forEach((br) => {
        days.push(br.key);
        last7Days.push(br.value);
        const tooltipRows = [];
        const currentDate = moment(br.key, 'YYYY-MM-DD').format('MM/DD/YYYY');
        tooltipRows.push({
          meta: { css: 'lctt-wrap-type1' },
          data: [currentDate, formatNumber(br.value)],
        });

        tooltip.push(tooltipRows);
      });

      this.columns = [days, last7Days];
      this.tooltip = tooltip;
    },
    async download() {
      try {
        await this.fetchData(this.payload, 0, this.exportValue, 'download');
      } catch (err) {
        console.error('error downloading file ->', err);
        throw err;
      }
    },
    async onChangeExportValue(val) {
      this.exportValue = val;
    },
    onSelectAdvertiser() {
      this.category = '';
      this.subcategory = '';
      this.categoryOpts = [];
      this.subCategoryOpts = [];
      this.table = [];
      this.loadSummaryData();
      this.loadTableData();
      this.loadPageData();
      this.loadOptions();
    },
    async loadPageData() {
      this.loadTableData(this.payload);
      this.loadSummaryData(this.payload);
      this.fetchDailyPixelFires(this.payload);
    },
    async loadSummaryData(payload) {
      try {
        this.summary = null;
        this.dataStillLoading = 1;
        if (this.advertiser && this.advertiser.id && this.pixelId) {
          const localPayload = {
            ...payload,
          };
          delete localPayload.advertiser;
          delete localPayload.client;
          delete localPayload.xandrId;
          localPayload.startDate = formatDateForAPI(new Date(this.pixelDates.startDate));
          localPayload.endDate = formatDateForAPI(new Date(this.pixelDates.endDate));
          const data = await appConsoleDataLayer.flipPixelFiresSummary(
            this.advertiser.id,
            this.pixelId ? this.pixelId : '',
            localPayload
          );
          this.summary = data;
        }
      } catch (err) {
        this.summary = {};
        console.error('error loading table ->', err);
        throw err;
      } finally {
        if (!this.summary) {
          this.summary = {};
        }
        this.dataStillLoading = 0;
      }
    },
    async loadTableData() {
      try {
        this.fetchData(this.payload, this.rowOffset, this.limitRowCount);
      } catch (err) {
        this.rows = [];
        console.error('error loading table ->', err);
        throw err;
      }
    },
    async fetchData(payload, offset = 0, limit = 20, action = 'display') {
      try {
        if (this.advertiser && this.pixelId) {
          payload = {
            ...payload,
            offset,
            limit,
          };
          const opts = {
            headers: {
              Accept: 'application/json',
            },
            respondHeaders: true,
          };
          if (action === 'download') {
            this.loading = false;
            opts.headers.Accept = 'text/csv';
            opts.responseType = 'blob';
          } else {
            this.loading = true;
            this.table = [];
          }
          payload.startDate = formatDateForAPI(new Date(this.pixelDates.startDate));
          payload.endDate = formatDateForAPI(new Date(this.pixelDates.endDate));
          const res = await appConsoleDataLayer.flipPixelFires(
            this.advertiser.id,
            this.pixelId ? this.pixelId : '',
            payload,
            opts
          );
          if (action === 'download') {
            fileDownload(res.data, this.genCSVFileName());
            return;
          }

          this.totalRowCount = parseInt(res.headers['x-total-count'], 10);
          res.result = res.result || [];

          this.table = res.result;
          this.$emit('pixelVerifyTableDataChange', {
            table: this.table,
          });
        }
      } catch (err) {
        console.error('error in fetching data ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
      }
    },

    async loadOptions() {
      try {
        this.categoryOpts = [];
        this.subCategoryOpts = [];

        if (this.advertiser && this.pixelId) {
          const data = await appConsoleDataLayer.flipPixelCategories(
            this.advertiser.id,
            this.pixelId,
            {
              ...this.payload,
              startDate: formatDateForAPI(new Date(this.pixelDates.startDate)),
              endDate: formatDateForAPI(new Date(this.pixelDates.endDate)),
            }
          );
          this.distinctData = data.filter((value, index) => {
            return (
              index ===
              data.findIndex((obj) => {
                return obj.category === value.category;
              })
            );
          });
          this.distinctData.forEach((row) => {
            this.categoryOpts.push({
              value: row.category,
              checked: true,
            });
            if (row.children) {
              row.children.forEach((childrow) => {
                this.subCategoryOpts.push({
                  value: childrow.category,
                  checked: true,
                });
              });
            }
          });
        }
      } catch (err) {
        console.error('error loading pixel summary ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    showPagerText() {
      const startRowIndex = this.rowOffset + 1;
      let endRowIndex = this.rowOffset + this.limitRowCount;
      endRowIndex = endRowIndex > this.totalRowCount ? this.totalRowCount : endRowIndex;
      return `${startRowIndex} - ${endRowIndex} of ${this.totalRowCount}`;
    },

    async gotoNext() {
      if (this.rowOffset + this.limitRowCount >= this.totalRowCount) {
        return;
      }

      try {
        this.rowOffset += this.limitRowCount;
        await this.loadTableData(this.payload);
      } catch (err) {
        console.error('error going to next page on report builder ->', err);
        throw err;
      }
    },

    async gotoPrev() {
      if (this.rowOffset <= 0) {
        return;
      }
      try {
        this.rowOffset -= this.limitRowCount;
        await this.loadTableData(this.payload);
      } catch (err) {
        console.error('error going to prev page on report builder ->', err);
        throw err;
      }
    },

    async onSelectCategoryOptions(options) {
      this.categoryOpts = JSON.parse(JSON.stringify(options));
      this.category = '';

      this.category = this.categoryOpts
        .filter((opt) => opt.checked)
        .map((val) => {
          return `${val.value}`;
        })
        .toString();
      let filteredData = this.distinctData;
      this.subCategoryOpts = [];
      if (this.category === '' || this.category.split(',').length === this.categoryOpts.length) {
        this.category = 'all';
      } else {
        filteredData = this.distinctData.filter((row) => this.category.includes(row.category));
      }
      filteredData.forEach((row) => {
        row?.children?.forEach((childrow) => {
          this.subCategoryOpts.push({
            value: childrow.category,
            checked: true,
          });
        });
      });
      this.subcategory = 'all';
      if (this.category !== '') {
        await this.loadPageData(this.payload);
      }
    },
    setDatePickerDate(data) {
      const datePickerPayload = {
        startDate: data.dates[0],
        endDate: data.dates[1],
        dateRangeOption: data.dateRangeOption,
      };
      this.loadOptions();
      this.$store.set('dashboard/pixelDates', datePickerPayload);
      this.fetchData(this.payload, this.rowOffset, this.limitRowCount);
      this.loadSummaryData();
      this.fetchDailyPixelFires();
    },
    async onSelectSubCategoryOptions(options) {
      this.subCategoryOpts = JSON.parse(JSON.stringify(options));
      this.subcategory = '';
      this.subcategory = this.subCategoryOpts
        .filter((opt) => opt.checked)
        .map((val) => {
          return `${val.value}`;
        })
        .toString();

      if (
        this.subcategory === '' ||
        this.subcategory.split(',').length === this.subCategoryOpts.length
      ) {
        this.subcategory = 'all';
      }
      if (this.subcategory !== '') {
        await this.loadPageData(this.payload);
      }
    },
    isPixelDataAvailable() {
      return this.table.length > 0;
    },
    genCSVFileName() {
      return `${'pixel-activity-export'}-${moment(new Date()).format('yyyy-MM-DD')}.csv`;
    },
  },
};
</script>

<style lang="scss" scoped>
.export-tbl {
  display: inline-block;
  font-size: 12px;
  color: #999;
  cursor: pointer;
  margin-top: 10px;
}
.export-tbl:hover {
  color: var(--primarycolor);
}
.drparw2 {
  margin-left: 15px;
  font-size: 10px;
}
#exp-drp {
  opacity: 0;
  position: relative;
  margin-left: -65px;
}

.intip4[data-tool-tip]:hover::after {
  bottom: -100% !important;
}
.flip-pixel-verification-container {
  // min-height: 840px;
  // overflow-y: scroll;
}
.flip-pixel-verification-container.more-top {
  // top: 132px !important;
  // margin-top: 72px;
}
.txt-align-center {
  width: 100%;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.txt-align-center-data {
  width: 100%;
  font-size: 8px;
  color: #53585c;
  text-align: right;
}
.ss-c {
  transition: padding 0.3s ease;
}
.cd-pop-hd1 {
  font-family: 'Manrope', sans-serif;
  font-size: 1.6em;
  font-weight: 500;
  line-height: 1;
  color: var(--primarycolor);
  letter-spacing: -0.02em;
}
.flp-panel-amt {
  margin: 20px 0px;
  ::v-deep span.big {
    font-size: 35px;
    font-weight: 400;
    line-height: 1.1em;
  }
  ::v-deep span.small {
    font-size: 20px;
  }
}
.flp-panel-title {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.pan1-content {
  display: inline-block;
  min-width: 110px;
}
.top-pan {
  width: 100%;
}
.flp-top-panels .pan1,
.pan2,
.pan3,
.pan4,
.pan5 {
  display: inline-flex;
  justify-content: space-between;
}
.flp-panel {
  position: relative;
  z-index: 1;
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 30px 0 30px;
  color: #444;
  border-radius: 0px;
}
.flp-top-panels {
  display: flex;
  justify-content: space-between;
}

.panels-row2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.ss-main-wrap {
  width: calc(100% - 350px);
  max-width: 960px;
  padding-bottom: 180px;
  margin-top: 52px;
  margin-left: 350px;
  vertical-align: baseline;
}

.iptd,
.ipth {
  display: none;
}
.pixel-val-table,
.pvt-body {
  width: 100%;
  .stat3-header {
    text-align: center;
  }
}
.pixel-val-table th {
  position: inherit;
  padding: 2em 0.7em 2em 1.1em;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  background-color: #3b3d48;
}
.pixel-val-table td {
  position: relative;
  padding: 0.6em 0.3em 0.6em 0.3em;
  font-size: 12px;
  color: #646464;
  text-align: center;
  vertical-align: middle;
  box-shadow: inset 1px 0px 0px 0px rgb(0 0 0 / 7%);
  span {
    overflow: hidden;
    width: 80px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: pre;
    &.pixelTimeStamp {
      width: 150px !important;
    }
    &.gaReferrerUrl {
      width: 200px !important;
    }
  }
}
.pixel-val-table tr {
  border-bottom: 1px solid #e3e3e3;
}

.ejson,
.dateet,
.advstr {
  display: none;
}

.pvt-body {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
}

.pagination-content {
  display: inline-block;
}
.pag-amt {
  position: relative;
  cursor: pointer;
  width: 49px;
  display: inline-block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #999;
  margin-right: 6px;
  padding-bottom: 2px;
  font-family: 'Manrope', sans-serif;
}

.pag-left,
.pag-right {
  margin-right: 8px;
  margin-left: 8px;
  cursor: pointer;
}
.pointer {
  margin: 0 0.25rem;
  cursor: pointer;
}
.pointer:hover {
  color: var(--primarycolor);
}
.pagination {
  margin-top: 10px;
  font-size: 13px;
  color: #999;
  display: inline-block;
  position: relative;
  margin-top: 10px;
  float: right;
}
.table-n-filter-container {
  padding: 0 50px 40px;
}
.fright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  .fright-reverify-container {
    display: flex;
    align-items: center;
  }
  .recent-pixel-activity-txt {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #444;
    letter-spacing: 0.02em;
  }
  // .fright-filter-container {
  //   display: flex;
  // }
}
.filter-panel-top {
  position: relative;
  z-index: 2;
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0 0 0;
  color: #444;
  border-radius: 0px;
  .fright-filter-container {
    display: inline-flex;
  }
}

.selected-opt {
  color: black;
}
::v-deep span[data-tool-tip]::before {
  transition: unset;
}
.cursor-default {
  cursor: default !important;
}
::v-deep .dropdown {
  .dropdown-menu {
    background-color: #fff !important;
    .norm-option {
      color: #222;
    }
  }
  .select {
    border: 1px solid #222 !important;

    &.open {
      background-color: #fff !important;
      .selected-opt-header {
        color: #222 !important;
      }
    }
    .selected-opt-header {
      color: #222;
    }
    .selected-opt {
      color: #222;
    }
    &.open {
      .droparw {
        color: #fff;
      }
      .selected-opt-header {
        color: #fff;
      }
      .selected-opt {
        color: #fff;
      }
    }
    &.closed {
      .droparw {
        color: #222;
      }
    }
  }
}
.table-wrap {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #a7a0a0;
}

.pixel-not-verified {
  margin-left: 20px;
  font-size: 15px;
  font-weight: 700;
  color: #ff0000;
  vertical-align: middle;
}
.pixel-verified {
  margin-top: 4px;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 700;
  color: #218021;
  vertical-align: middle;
}
.txt-center {
  text-align: center;
}
</style>
