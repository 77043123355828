<template>
  <div class="account-setup-wrapper">
    <PixelVerificationTopBar
      v-if="showPixelVerify()"
      class="pix-ver-top-bar"
      :update-advertiser="updateAdvertiserAndEmail"
      :check-pixel-verified="checkPixelVerified"
    />
    <div v-if="!accountSetupLoading">
      <div class="new-campaign-wrap">
        <div class="ss-left-nav" :class="showPixelVerify() ? 'more-top' : ''">
          <AccSetupLeftNavBar
            :advertiser-data="advertiserData"
            :handle-change-section="handleChangeSection"
          />
        </div>
        <div v-if="currentSectionEquals('ACCOUNT_SETUP')" class="ss-main-wrap">
          <p id="acc-information-account-setup" class="acc-setup-title">Account Setup</p>
          <AccInformation
            :advertiser-data="advertiserData"
            :update-advertiser="updateAdvertiserAndEmail"
            :is-readonly="isExternalUser"
            @handleShowGenericModal="handleShowGenericModal"
          />
          <div id="acc-setup-payment-account-setup" class="sec-spacer"></div>
          <AccSetupPaymentMethod
            :advertiser-data="advertiserData"
            :update-advertiser="updateAdvertiserAndEmail"
            :is-readonly="isExternalUser"
            @toggleTnCModal="toggleTnCModal"
            @handleShowGenericModal="handleShowGenericModal"
          />
          <div id="acc-add-users-account-setup" class="sec-spacer"></div>
          <AccAddUsers
            :advertiser-data="advertiserData"
            :is-readonly="isExternalUser"
            :current-user-id="currentUser.id"
            @handleShowGenericModal="handleShowGenericModal"
            @handleShowConfirmBoxModal="handleShowConfirmBoxModal"
            @handleShowReInviteModal="handleShowReInviteModal"
          />
          <div class="sec-spacer"></div>
          <AccPixelSetup
            :advertiser-data="advertiserData"
            :handle-change-section="handleChangeSection"
          />
        </div>
        <div v-if="currentSectionIncludes('PIXEL_SETUP')" class="ss-pixel-wrap">
          <FlipPixelCode
            v-if="currentSectionEquals('PIXEL_SETUP_CODE')"
            :pixel-id="universalPixelId"
            :advertiser-data="advertiserData"
            :update-advertiser="updateAdvertiser"
            :is-current-user-external="isCurrentUserExt"
          />
          <FlipPixelVerification
            v-if="currentSectionEquals('PIXEL_SETUP_VERIFICATION')"
            :pixel-id="universalPixelId"
            :update-advertiser="updateAdvertiserAndEmail"
            :show-pixel-verify="showPixelVerify"
            :check-pixel-verified="checkPixelVerified"
            @pixelVerifyTableDataChange="pixelVerifyTableDataChange"
          />
        </div>
      </div>
    </div>
    <TncModal v-if="showTncModal" @cancel="showTncModal = false" />
    <ConfirmationModal
      v-if="showGenericModalPayload.showModal"
      :message="showGenericModalPayload.message"
      :show-confirm-btn="false"
      :cancel-btn-text="true"
      :header-text="showGenericModalPayload.title"
      positive-button-label="CLOSE"
      :is-alert="true"
      @close="showGenericModalPayload.showModal = false"
      @onPositiveButtonClick="showGenericModalPayload.showModal = false"
    />

    <ConfirmationModal
      v-if="showConfirmBoxPayload.showModal"
      :message="removeMessage()"
      :show-confirm-btn="true"
      :cancel-btn-text="true"
      :header-text="'Remove User'"
      positive-button-label="REMOVE"
      negative-button-label="CLOSE"
      :is-alert="false"
      @close="showConfirmBoxPayload.showModal = false"
      @onPositiveButtonClick="showConfirmBoxPayload.onClickConfirmSubmit"
      @onNegativeButtonClick="showConfirmBoxPayload.showModal = false"
    />

    <ConfirmationModal
      v-if="showReInvitePayload.showModal"
      :message="reInviteMessage()"
      :show-confirm-btn="true"
      :cancel-btn-text="true"
      header-text="Send Invite"
      positive-button-label="SEND"
      negative-button-label="CLOSE"
      :is-alert="false"
      @close="showReInvitePayload.showModal = false"
      @onPositiveButtonClick="showReInvitePayload.onClickConfirmReInviteSubmit"
      @onNegativeButtonClick="showReInvitePayload.showModal = false"
    />
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import _ from 'underscore';
import TncModal from '~/pages/TncModal.vue';
import store from '~/store';
import AccSetupLeftNavBar from '~/components/accountSetup/AccSetupLeftNavBar.vue';
import AccInformation from '~/components/accountSetup/AccInformation.vue';
import AccSetupPaymentMethod from '~/components/accountSetup/AccSetupPaymentMethod.vue';
import AccAddUsers from '~/components/accountSetup/AccAddUsers.vue';
import advertiserApi from '~/api/advertiser';
import AccPixelSetup from '~/components/accountSetup/AccPixelSetup.vue';
import FlipPixelCode from '~/pages/FlipPixelCode.vue';
import FlipPixelVerification from '~/pages/FlipPixelVerification.vue';
import PixelVerificationTopBar from '~/pages/PixelVerificationTopBar.vue';
import authzMixin from '~/components/mixins/authz-mixin';
import flipPixelApi from '~/api/flipPixel';
import ConfirmationModal from '~/components/campaigns/confirmation-modal.vue';
import { ACCOUNT_UPDATE_EMAIL_RECIPIENTS, PIXEL_INSTALLATION_GUIDE_URL } from '~/constant';

export default {
  name: 'AccountSetupScreen',
  components: {
    PixelVerificationTopBar,
    TncModal,
    ConfirmationModal,
    AccSetupLeftNavBar,
    AccInformation,
    AccSetupPaymentMethod,
    AccAddUsers,
    AccPixelSetup,
    FlipPixelCode,
    FlipPixelVerification,
  },
  mixins: [authzMixin],

  data() {
    return {
      accountSetupLoading: false,
      showTncModal: false,
      showGenericModalPayload: {
        showModal: false,
        message: '',
      },
      showConfirmBoxPayload: {
        showModal: false,
        type: '',
        onClickConfirmSubmit: () => {},
      },
      showReInvitePayload: {
        showModal: false,
        email: '',
        onClickConfirmReInviteSubmit: () => {},
      },
      advertiserData: {},
      currentSection: 'ACCOUNT_SETUP',
      isPixelVerified: false,
      table: [],
      isAccountSetupCompleted: false,
      isCurrentUserExt: true,
    };
  },

  computed: {
    currentUser: get('common/currentUser'),
    advertiser: get('common/advertiser'),
    universalPixelId: sync('common/universalPixelId'),
    selectedSubMenuOption: sync('common/selectedSubMenuOption'),
    theCurrentSection() {
      return isBlank(this.selectedSubMenuOption) ? this.currentSection : this.selectedSubMenuOption;
    },
  },
  async mounted() {
    // accountInfoAvailable, paymentInfoAvailable are determined by the server.
    // Other flags are set by external users, NOT internal.
    const role = this.currentUser?.role?.name;
    this.isCurrentUserExt = role
      ? role !== 'INTERNAL_ADMIN' && role !== 'INTERNAL_SUB_ADMIN' && role !== 'INTERNAL_USER'
      : false;
    this.accountSetupLoading = true;
    try {
      const apiResponse = await advertiserApi.getAdvertiser(this.advertiser.id);
      this.advertiserData = { ...apiResponse };
      this.isAccountSetupCompleted = this.hasCompleteAccountSetupInfo(this.advertiserData);
    } catch (err) {
      console.error('error handling Account Setup get Advertiser ->', err);
    }
    this.loadFlipPixels();
    this.accountSetupLoading = false;
  },
  created() {
    window.addEventListener('scroll', this.isAddUserInViewPort);
  },
  destroyed() {
    window.removeEventListener('scroll', this.isAddUserInViewPort);
  },

  methods: {
    hasCompleteAccountSetupInfo(advData) {
      return this.hasCompleteAccountInfo(advData) && this.hasCompleteAccountPaymentInfo(advData);
    },

    hasCompleteAccountInfo(advData) {
      return (
        !isBlank(advData.name) &&
        !isBlank(advData.industry) &&
        !isBlank(advData.companySize) &&
        !isBlank(advData.annualBudget) &&
        !isBlank(advData.domainName)
      );
    },

    hasCompleteAccountPaymentInfo(advData) {
      return (
        !isBlank(advData.advertiserPaymentMethod.paymentType) &&
        !isBlank(advData.advertiserPaymentMethod.billingAddress) &&
        !isBlank(advData.advertiserPaymentMethod.billingContactPerson) &&
        !isBlank(advData.advertiserPaymentMethod.billingContactEmail) &&
        !isBlank(advData.advertiserPaymentMethod.billingContactPhone) &&
        !isBlank(advData.advertiserPaymentMethod.termsAgreed)
      );
    },
    reInviteMessage() {
      const msg = `Are you sure you want to send invite to ${this.showReInvitePayload.email} ?`;
      return msg;
    },
    removeMessage() {
      const msg = `Are you sure you want to remove ${this.showConfirmBoxPayload.type} ?`;
      return msg;
    },
    toggleTnCModal(val) {
      this.showTncModal = val;
    },
    currentSectionEquals(section) {
      return this.theCurrentSection === section;
    },
    currentSectionIncludes(section) {
      return this.theCurrentSection.includes(section);
    },
    showPixelVerify() {
      // User is in pix ver section, Advt is a SS advt., table has data, pix is NOT verified
      return (
        this.currentSectionEquals('PIXEL_SETUP_VERIFICATION') &&
        this.advertiser.selfServe &&
        this.isPixelDataAvailable() &&
        !this.checkPixelVerified()
      );
    },
    pixelVerifyTableDataChange(args) {
      this.table = args.table;
    },
    isPixelDataAvailable() {
      return this.table.length > 0;
    },

    checkPixelVerified() {
      this.isPixelVerified =
        (this.advertiser?.pixelSetup?.pixelAdded && this.advertiser?.pixelSetup?.pixelVerified) ||
        false;
      return this.isPixelVerified;
    },
    isAddUserInViewPort() {
      const el = document.getElementById('acc-add-users-account-setup');
      if (el) {
        const rect = el?.getBoundingClientRect();
        const isVisible =
          rect.top <= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        if (isVisible && this.isCurrentUserExt) {
          if (!this.advertiserData?.accountSetup?.userInfoAvailable) {
            this.updateAdvertiser(
              { ...this?.advertiserData?.accountSetup, userInfoAvailable: true },
              'accountSetup'
            );
          }
        }
      }
    },
    handleShowGenericModal(val) {
      this.showGenericModalPayload = val;
    },
    handleShowConfirmBoxModal(val) {
      this.showConfirmBoxPayload = val;
    },
    handleShowReInviteModal(val) {
      this.showReInvitePayload = val;
    },
    async updateAdvertiserAndEmail(val, type) {
      try {
        await this.updateAdvertiser(val, type);
        await this.sendEmail();
      } catch (err) {
        console.error('error handling Account Setup update Advertiser and email ->', err);
      }
    },
    async updateAdvertiser(val, type) {
      try {
        const advData = { ...this.advertiserData, [type]: val };
        const updatedAdvertiserResponse = await advertiserApi.editAdvertiser(
          this.advertiser.id,
          advData
        );
        this.advertiserData = { ...updatedAdvertiserResponse };
        store.dispatch('common/updateSelectedAdvertiser', {
          ...updatedAdvertiserResponse,
          isAdvUpdatedInAccSetup: true,
        });
      } catch (err) {
        console.error('error handling Account Setup update Advertiser ->', err);
        throw err;
      }
    },
    async sendEmail() {
      try {
        let emailSubject = 'New Flip Plus Account Setup Completed';
        if (this.isAccountSetupCompleted) {
          emailSubject = `EDIT: ${emailSubject}`;
        } else {
          this.isAccountSetupCompleted = this.hasCompleteAccountSetupInfo(this.advertiser);
        }
        if (this.isAccountSetupCompleted) {
          advertiserApi.advertiserUpdateEmailNotification(this.advertiser.id, {
            emails: ACCOUNT_UPDATE_EMAIL_RECIPIENTS,
            emailSubject,
          });
        }
      } catch (err) {
        console.error('error sending Account Setup update email ->', err);
        throw err;
      }
    },
    handleChangeSection(val) {
      if (val === 'PIXEL_SETUP_INSTALLATION') {
        window.open(PIXEL_INSTALLATION_GUIDE_URL, '_blank');
        // If pixel guide clicked, and current user is external, set the flag.
        if (
          this.advertiserData &&
          !this.advertiserData.pixelSetup.pixelGuide &&
          this.isCurrentUserExt
        ) {
          const temp = { ...this.advertiserData.pixelSetup, pixelGuide: true };
          this.updateAdvertiser(temp, 'pixelSetup');
        }
      } else {
        this.selectedSubMenuOption = '';
        this.currentSection = val;
      }
    },
    loadFlipPixels() {
      if (isBlank(this.advertiserData.xandrAdvertiserId)) {
        this.universalPixelId = null;
        return;
      }
      flipPixelApi
        .flipPixels(this.advertiserData.id)
        .then((pixels) => {
          if (pixels && _.isArray(pixels)) {
            const universalPixel = pixels.find((p) => p.conversion_type === 'Universal');
            if (universalPixel) {
              this.universalPixelId = universalPixel.flip_id;
            }
          }
        })
        .catch((err) => {
          this.universalPixelId = null;
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
html {
  height: 100%;
}
body {
  min-height: unset;
  margin: unset;
}
::-webkit-scrollbar {
  width: 0px !important;
  height: 7px;
}
</style>

<style lang="scss" scoped>
.ss-left-nav.more-top {
  .ss-left-nav-content {
    top: 132px !important;
  }
}
.ss-main-wrap.more-top {
  margin-top: 72px;
}
.ss-left-nav-content {
  font-family: 'Manrope', sans-serif;
  padding: 42px;
}
.acc-setup {
  &-title {
    font-size: 1.6em;
    color: var(--selfserveprimarycolor);
    font-weight: 500;
    margin-bottom: 30px;
  }
}
.account-setup-wrapper {
  font-family: 'Manrope', sans-serif;
  line-height: 1.7;
  font-weight: 500;
  color: #48525c;
}
.new-campaign-wrap {
  display: flex;
  width: 100%;
  min-width: 1000px;
  max-width: 100%;
  padding: 40px 0px 0px 0;
  margin: 0 auto;
  background-color: #ffffff;
}
.ss-left-nav {
  left: 0px;
  top: 52px;
  min-width: 350px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.ss-main-wrap {
  min-width: calc(100% - 350px);
  padding: 38px 70px 38px 50px;
  overflow-y: scroll;
  z-index: 1;
}
.ss-pixel-wrap {
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  width: 960px;
}
::v-deep .ss-pixel-wrap .flip-pixel-verification-container .ss-main-wrap,
::v-deep .ss-pixel-wrap .flip-pixel-code-container .ss-main-wrap {
  width: unset;
  max-width: unset;
  padding-bottom: unset;
  margin-top: unset;
  margin-left: unset;
  vertical-align: unset;
}
.ss-footer {
  height: 73px;
  background-color: #ebf0f4;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 3;
  margin: 0;
  position: fixed;
}
.sec-spacer {
  width: 90%;
  padding-top: 60px;
  border-bottom: 1px dashed #ddd;
  margin-bottom: 60px;
}
.setupPageloading {
  opacity: 0.8;
  pointer-events: none;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}

.pix-ver-top-bar {
  display: inline-block;
}
</style>
