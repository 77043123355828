<template>
  <div class="nocamp">
    <div class="top-alert-block">
      <div class="nocamp-left">
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="fa-w-18 top-alert-icon" />
        {{ warningMessage }}
      </div>
      <div class="nocamp-right">
        <button class="mvc-btn btn-size3" @click="buttonClick('warning-action-button-click')">
          {{ warningActionButtonTitle }}
        </button>
      </div>
    </div>
    <div class="flp-top-panels-title-txt nocamp-title">{{ pageTitle }}</div>
    <div class="nocamp-body">
      <img src="assets/campaign-placeholder.png" />
      <div class="nocamp-txt">{{ emptyMessage }}</div>
      <button class="mvc-btn btn-size3" @click="buttonClick('page-action-button1-click')">
        {{ pageActionButton1Title }}
      </button>
      <button
        v-if="pageActionButton2Title"
        class="mvc-btn btn-size3"
        style="margin-top: 15px;"
        @click="buttonClick('page-action-button2-click')"
      >
        {{ pageActionButton2Title }}
      </button>
      <div style="height: 70px;"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    warningMessage: { require: false, type: String, default: '' },
    warningActionButtonTitle: { require: false, type: String, default: '' },
    pageTitle: { require: false, type: String, default: '' },
    emptyMessage: { require: false, type: String, default: '' },
    pageActionButton1Title: { require: false, type: String, default: '' },
    pageActionButton2Title: { require: false, type: String, default: '' },
  },
  methods: {
    buttonClick(event) {
      this.$emit(event);
    },
  },
};
</script>
<style lang="scss" scoped>
.nocamp {
  margin-top: 60px;
  font-family: 'Manrope', sans-serif;
  color: var(--selfserveovertext);
  .mvc-btn {
    font-family: 'Manrope', sans-serif;
    background-color: var(--selfserveprimarycolor) !important;
    &.btn-size3 {
      min-width: 100px;
      font-size: 12px;
      padding: 10px 18px;
    }
    &:hover {
      color: #fff;
      background-color: #555 !important;
    }
  }
}
.flp-top-panels-title-txt {
  display: inline-block;
  padding-top: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 16px;
  color: #2d313a;
}
.nocamp-title {
  margin: 40px 40px 35px 40px;
  border-bottom: 1px solid #eaeaea;
  padding: 0px 0px 35px 0px;
  width: calc(100% - 80px);
}
.nocamp-body {
  width: 100%;
  height: calc(100vh - 310px);
  text-align: center;
  padding: 0px 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nocamp-body img {
  width: 300px;
  height: 200px;
}
.nocamp-txt {
  margin: 10px 0px 50px;
  color: #2c2e38;
  font-size: 15px;
}
.top-alert-block {
  background-color: #ebf0f3;
  padding: 10px 40px;
  width: 100%;
  overflow: auto;
  vertical-align: top;
}
.nocamp-left {
  width: 70%;
  float: left;
  display: inline-block;
  font-size: 14px;
  padding: 10px 0px;
  color: #2c2e38;
}
.nocamp-right {
  width: 29%;
  float: right;
  display: inline-block;
  text-align: right;
}
.top-alert-icon {
  color: #52555d;
  margin-right: 6px;
}
</style>
