<template>
  <div id="acc-pixel-account-setup">
    <p class="acc-pixelSetup-title">Pixel Setup</p>
    <p :class="`acc-pixelSetup-desc ${isPixelVerified || isPixelAdded ? 'hide-pixel' : ''}`">
      {{ message }}
    </p>
    <div :class="`${isPixelAdded ? '' : 'hide-pixel'}`">
      <k-button :size="3" label="PIXEL SETUP" @click="handleChangeSection('PIXEL_SETUP_CODE')" />
    </div>
    <div v-if="showInternalSetupButton">
      <k-button :size="3" label="INTERNAL SETUP" @click="handleInternalSetupClick" />
    </div>
    <p v-if="isPixelAdded && !isPixelVerified" class="acc-pixelSetup-desc-error">
      <font-awesome-icon :icon="['fas', 'exclamation']" class="acc-pixelSetup-error-icon" />
      {{ message }}
    </p>
  </div>
</template>

<script>
import { LAYOUT_TYPES } from '~/constant';
import authzMixin from '~/components/mixins/authz-mixin';

export default {
  name: 'AccPixelSetup',
  components: {},
  mixins: [authzMixin],
  props: {
    advertiserData: {
      type: Object,
      required: true,
      default: () => {},
    },
    handleChangeSection: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  computed: {
    getPixelSetup() {
      return this.advertiserData ? this.advertiserData.pixelSetup : undefined;
    },
    isPixelAdded() {
      const pixelSetup = this.getPixelSetup;
      return pixelSetup && pixelSetup.pixelAdded;
    },
    isPixelVerified() {
      const pixelSetup = this.getPixelSetup;
      return pixelSetup?.pixelVerified;
    },
    showInternalSetupButton() {
      return this.isInternalAdmin || this.isInternalSubAdmin;
    },
    message() {
      if (this.isPixelAdded) {
        return 'No pixel data detected yet. Please complete the flip pixel setup.';
      }
      return `Your account information is being verified. This can take up to 24 hours. Once verified, you'll be able to generate and place your pixel. Check back soon.`;
    },
  },
  methods: {
    handleInternalSetupClick() {
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.ADMIN);
    },
  },
};
</script>

<style lang="scss" scoped>
.acc-pixelSetup {
  color: #48525c;
  &-title {
    font-size: 20px;
    margin-bottom: 25px;
  }
  &-desc {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    width: 852px;
  }
  &-desc-error {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #ff0202;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    bottom: 10px;
  }
  &-error-icon {
    margin: 5px;
  }
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.hide-pixel {
  display: none;
}
</style>
