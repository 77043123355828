<template>
  <div :class="`${isReadonly ? 'readonly-add-users' : ''}`">
    <p class="acc-users-title">Users</p>
    <div>
      <p id="accUserTab" class="acc-users-tab">
        Users
      </p>
      <div class="acc-users-table-cont">
        <k-button v-if="!isReadonly" :size="3" label="ADD A USER" @click="handleAddUser" />
        <table class="acc-users-table">
          <thead class="acc-users-table-heading">
            <tr>
              <th class="acc-users-table-heading-name">Name</th>
              <th class="acc-users-table-heading-email">Email</th>
              <th class="acc-users-table-heading-advertiser">Advertiser</th>
              <th class="acc-users-table-heading-role">Role</th>
              <th class="acc-users-table-heading-action">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(apl, apli) in usersList"
              :key="apli + apl.readonly + 'usersList' + '_' + `${Math.random()}`"
              class="acc-users-table-row"
            >
              <td>
                <div class="table-cell-container" :class="{ 'text-cells': apl.readonly }">
                  <div :class="[`${apl.readonly ? 'readonlyInput' : ''}`]">
                    <div v-if="apl.readonly" :title="apl.userName" class="acc-users-table-row-text">
                      {{ apl.userName }}
                    </div>
                    <k-text-input
                      v-else
                      :value="apl.userName"
                      :max-length="255"
                      :required="false"
                      :readonly="apl.readonly"
                      @change="(val) => changeUserVal({ value: val, index: apli })"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="table-cell-container" :class="{ 'text-cells': apl.readonly }">
                  <div :class="[`${apl.readonly ? 'readonlyInput' : ''}`]">
                    <div v-if="apl.readonly" :title="apl.email" class="acc-users-table-row-text">
                      {{ apl.email }}
                    </div>
                    <k-text-input
                      v-else
                      :value="apl.email"
                      :readonly="apl.readonly"
                      :required="false"
                      :max-length="255"
                      @change="(val) => changeUserVal({ value: val, index: apli, type: 'email' })"
                    />
                  </div>
                </div>
              </td>
              <td class="acc-users-table-row-text">
                <div :title="advertiserData.name" class="table-cell-container text-cells">
                  {{ advertiserData.name }}
                </div>
              </td>
              <td class="acc-users-table-row-text user-admin role-row">
                <div class="table-cell-container text-cells role-container">
                  <k-select
                    :options="['Admin', 'User']"
                    :readonly="apl.readonly"
                    :multiple="false"
                    :value="getRoleValue(apl)"
                    @input="(val) => changeUserVal({ value: val, index: apli, type: 'user' })"
                  />
                </div>
                <!-- {{ apl.role.name === 'EXTERNAL_USER' ? 'User': apl.role.name === '' '' }} -->
              </td>
              <td class="action-column">
                <div class="table-cell-container action-table-cell">
                  <div
                    v-if="apl.showSaveIcon && !isReadonly"
                    :class="`${isSaveLoading ? 'disabled' : ''}`"
                  >
                    <span class="intip4" data-tool-tip="Save User">
                      <font-awesome-icon
                        :icon="['fas', 'save']"
                        @mousedown="handleSaveClick(apli)"
                      />
                    </span>
                    <span class="intip4" data-tool-tip="Discard Changes">
                      <font-awesome-icon
                        :icon="['fas', 'times-square']"
                        @mousedown="handleCancelClick(apli)"
                      />
                    </span>
                  </div>
                  <div
                    v-if="!apl.showSaveIcon && !isReadonly"
                    :class="`${isSaveLoading ? 'disabled' : ''}`"
                  >
                    <span class="intip4" data-tool-tip="Edit User">
                      <font-awesome-icon
                        :icon="['fas', 'pencil']"
                        @mousedown="handleEditUser(apli)"
                      />
                    </span>
                    <span
                      v-if="usersList.length > 1 && apl.id != currentUserId"
                      class="intip4"
                      data-tool-tip="Remove User"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'trash']"
                        @mousedown="removeUser(apl.id, apl.roleId)"
                      />
                    </span>
                    <span
                      v-if="!apl.inviteAccepted"
                      class="intip4 re-invite-container"
                      data-tool-tip="Re-Invite User"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'envelope']"
                        @mousedown="reInviteUser(apl)"
                      />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-35"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { get } from 'vuex-pathify';
import userApi from '~/api/user';
import usersApi, { userRolesApi } from 'adready-api/api/user';
import forklift from '~/components/mixins/forklift-mixin';
import { isBlank } from 'adready-api/helpers/common';
import { ROLE_ID_EXTERNAL_USER } from '~/constant';

export default {
  name: 'AccAddUser',

  props: {
    advertiserData: {
      type: Object,
      required: true,
      default: {},
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentUserId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  mixins: [forklift],

  data() {
    return {
      usersList: [],
      confirmationObj: {},
      confirmationReInviteObj: null,
      tempUsersObj: {},
      isSaveLoading: false,
    };
  },
  computed: {
    allUsers: get('common/allUsers'),
  },

  mounted() {
    try {
      this.loadAccountAdvertiserUsers();
      this.loadAllUsers(true);
    } catch (err) {
      console.error('error handling Account Setup get user API ->', err);
    }
  },
  methods: {
    getRoleValue(apl) {
      if (apl.roleId) {
        return apl.roleId === 12 ? 'Admin' : 'User';
      } else {
        return apl.role.name === 'EXTERNAL_SUB_ADMIN'
          ? 'Admin'
          : apl.role.name === 'EXTERNAL_USER'
          ? 'User'
          : '';
      }
    },
    handleAddUser() {
      const newUser = {
        provider: 'LOCAL',
        organizationId: this.advertiserData.organizationId,
        userApplications: [{ applicationId: 4 }],
        enabled: false,
        newUser: true,
        email: null,
        userName: null,
        readonly: false,
        showSaveIcon: true,
        roleId: 13,
        role: { canCreateRole: [], id: 13, name: 'EXTERNAL_USER', permissions: [] },
      };
      this.usersList.push(newUser);
    },
    removeUser(userIdToRemove, roleId) {
      const userType = roleId == 12 ? 'admin' : 'user';
      this.onClickConfirm({
        type: userType,
        value: { advertiserId: this.advertiserData.id, userId: userIdToRemove },
      });
    },
    onClickConfirm(item) {
      this.$emit('handleShowConfirmBoxModal', {
        showModal: true,
        type: item.type,
        onClickConfirmSubmit: this.onClickConfirmSubmit,
      });
      this.confirmationObj = item;
    },
    onClickConfirmSubmit() {
      if (this.confirmationObj.type === 'user' || this.confirmationObj.type === 'admin') {
        this.onDeleteAdvertiser(this.confirmationObj.value);
      }
      this.$emit('handleShowConfirmBoxModal', {
        showModal: false,
        type: '',
        onClickConfirmSubmit: () => {},
      });
    },
    async onDeleteAdvertiser(advertiser) {
      await usersApi.removeUser(advertiser.userId).then(() => {
        this.loadAccountAdvertiserUsers();
      });
    },
    async loadAccountAdvertiserUsers() {
      if (this.advertiserData?.accountId) {
        const acctUsers = await userApi.listUsers(this.advertiserData?.accountId);
        const advertUsers = await userApi.listAdvertiserUsers(this.advertiserData?.id);
        const allUsers = [...acctUsers, ...advertUsers];
        if (allUsers.length > 0) {
          const allowedRoles = [
            'INTERNAL_ADMIN',
            'INTERNAL_SUB_ADMIN',
            'INTERNAL_USER',
            'ACCOUNT_MANAGER',
            'CLIENT_ADMIN',
            'CLIENT_MANAGER',
            'CLIENT_USER',
          ];
          this.usersList = allUsers
            .map((u) => {
              if (u.role && !allowedRoles.includes(u.role.name)) {
                return { ...u, readonly: true, roleId: u.role.id };
              }
            })
            .filter((item) => item);
        }
      }
    },
    handleEditUser(userIndex) {
      this.usersList[userIndex].showSaveIcon = true;
      this.usersList[userIndex].readonly = false;
      this.tempUsersObj[userIndex] = { ...this.usersList[userIndex] };
      this.$forceUpdate();
    },
    reInviteUser(user) {
      this.showMsg = null;
      this.onClickConfirmReInvite({
        email: user.email,
        value: user,
      });
    },
    onClickConfirmReInvite(item) {
      this.$emit('handleShowReInviteModal', {
        showModal: true,
        email: item.email,
        onClickConfirmReInviteSubmit: this.onClickConfirmReInviteSubmit,
      });
      this.confirmationReInviteObj = item;
    },
    onClickConfirmReInviteSubmit() {
      this.sendInvite(this.confirmationReInviteObj.value, true);
      this.$emit('handleShowReInviteModal', {
        showModal: false,
        email: '',
        onClickConfirmReInviteSubmit: () => {},
      });
    },
    async sendInvite(user, isNotify) {
      try {
        await usersApi.sendInvite(user.id);
        if (isNotify) {
          this.$emit('handleShowGenericModal', {
            showModal: true,
            title: 'Add User',
            message: 'User invited successfully.',
          });
        }
      } catch (err) {
        console.error(`error sending invite to ${user.email} (id: ${user.id}) user ->`, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    handleCancelClick(userIndex) {
      if (this.usersList[userIndex].newUser) {
        this.usersList.splice(userIndex, 1);
      } else {
        this.usersList[userIndex] = { ...this.tempUsersObj[userIndex] };
        this.usersList[userIndex].readonly = true;
        this.usersList[userIndex].showSaveIcon = false;
      }
      this.$forceUpdate();
    },
    handleSaveClick(userIndex) {
      setTimeout(async () => {
        this.isSaveLoading = true;
        const userValues = this.usersList[userIndex];
        if (isBlank(userValues.email)) {
          this.$emit('handleShowGenericModal', {
            showModal: true,
            title: 'Add User',
            message: 'Please enter email id',
          });
          this.isSaveLoading = false;
          return;
        }
        if (isBlank(userValues.userName)) {
          this.$emit('handleShowGenericModal', {
            showModal: true,
            title: 'Add User',
            message: 'Please enter username',
          });
          this.isSaveLoading = false;

          return;
        }
        if (
          !userValues.email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          this.$emit('handleShowGenericModal', {
            showModal: true,
            title: 'Add User',
            message: 'Please enter valid email id',
          });
          this.isSaveLoading = false;
          return;
        }

        try {
          const roleId = this.usersList[userIndex].roleId;
          if (this.usersList[userIndex].newUser) {
            const userFoundWithSameEmail =
              !isBlank(this.allUsers) &&
              this.allUsers.some(
                (u) =>
                  !isBlank(u.email) &&
                  !isBlank(userValues.email) &&
                  u.email.toUpperCase() === userValues.email.toUpperCase()
              );

            if (userFoundWithSameEmail) {
              this.$emit('handleShowGenericModal', {
                showModal: true,
                title: 'Add User',
                message: `User already exists with ${userValues.email}`,
              });
              this.isSaveLoading = false;
              return;
            }
            const createdUser = await usersApi.create(this.usersList[userIndex]);
            if (createdUser && createdUser.id) {
              this.usersList[userIndex] = {
                ...this.usersList[userIndex],
                ...createdUser,
                readonly: true,
                roleId,
              };
            }
            await userRolesApi.createGlobalRoles([createdUser.id, roleId], {
              userId: createdUser.id,
              roleId,
            });
            if (this.usersList[userIndex].roleId === ROLE_ID_EXTERNAL_USER) {
              const userAdvertiser = {
                userId: createdUser.id,
                advertiserId: this.advertiserData.id,
              };
              await usersApi.mapAdvertisers(createdUser.id, [userAdvertiser]);
            }
            this.loadAccountAdvertiserUsers();
            this.loadAllUsers(true);
          } else {
            await usersApi
              .update(userValues.id, JSON.parse(JSON.stringify(userValues)))
              .then((resp) => {
                this.usersList[userIndex] = { ...resp, readonly: true };
              });
            if (userValues.role.id !== roleId) {
              this.usersList[userIndex].roleId = roleId;
              await userRolesApi.createGlobalRoles([userValues.id, roleId], {
                userId: userValues.id,
                roleId,
              });
            }
            this.loadAccountAdvertiserUsers();
            this.loadAllUsers(true);
          }
          if (!this.usersList[userIndex].inviteAccepted) {
            this.sendInvite(this.usersList[userIndex]);
          }
          this.isSaveLoading = false;
        } catch (err) {
          console.error('error update user api update or new ->', err);
          this.isSaveLoading = false;

          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        }
      }, 0);
    },
    changeUserVal(obj) {
      const { type, value, index } = obj;
      if (this.usersList[index]) {
        if (type === 'email') {
          this.usersList[index].email = value;
        } else if (type === 'user') {
          this.usersList[index].roleId = value === 'Admin' ? 12 : 13;
        } else {
          this.usersList[index].userName = value;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tbody {
  top: 16px;
  border-top: 1px dashed #dae9ef;
  .acc-users-table-row:first-child {
    .table-cell-container {
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 172px;
      &.action-table-cell {
        width: 80px;
        padding-right: 0;
      }
      &.role-container {
        width: 110px;
      }
    }
  }
  .table-cell-container {
    padding-right: 8px;
    &.text-cells {
      padding-top: 3px;
    }
  }
}
.readonly-add-users {
  pointer-events: none;
}
.acc-users {
  &-title {
    font-size: 20px;
    color: #48525c;
    margin-bottom: 25px;
  }
  &-tab {
    background: #f0f4f5;
    width: max-content;
    padding: 8px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 13px;
  }
  &-table {
    &-heading {
      th {
        width: 172px;
        padding: 8px 8px 15px 0;
        overflow: hidden;
        font-size: 13px;
        font-weight: 600;
        color: #48525c;
        vertical-align: middle;
      }
      &-name {
        width: 380px !important;
      }
      &-email {
        width: 430px !important;
      }
      &-advertiser {
        width: 370px !important;
      }
      &-action {
        width: 300px !important;
      }
    }
    &-cont {
      padding: 35px;
      width: 852px;
      background: #f0f4f5;
    }
    &-row {
      td {
      }
      &-text {
        font-size: 13px !important;
        font-weight: 500;
        color: #48525c !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 172px;
        &.role-row {
          width: 110px;
        }
        .table-cell-container {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 172px;
        }
      }
    }
  }
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
::v-deep .form-input .form-input-field {
  font-size: 13px !important;
  font-weight: 500;
  color: #48525c !important;
  padding: 5px 5px 5px 3px !important;
  border: none !important;
  background: #fff;
  max-width: 250px !important;
  font-family: 'Manrope';
  margin-bottom: 5px;
}
::v-deep .readonlyInput .form-input .form-input-field {
  background: unset !important;
}
::v-deep .intip4 {
  position: relative;
  margin-right: 10px;
  top: 0;
  svg {
    color: #84929a !important;
    width: 0.8em !important;
    &:hover {
      color: var(--selfserveprimarycolor) !important;
    }
  }
}
::v-deep .intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 27px;
  left: -25px;
  z-index: 99;
  display: block;
  width: max-content;
  white-space: break-spaces;
  padding: 4px;
  font-size: 12px;
  line-height: 1.2em;
  color: #fff;
  background-color: rgba(44, 48, 54, 0.98);
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}

.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
}
.mt-35 {
  margin-top: 35px;
}
.disabled {
  opacity: 0.25;
  pointer-events: none;
}
::v-deep .v-select.vs--single.vs--unsearchable.form-input-field {
  padding-bottom: 1px !important;
  padding-top: 1px !important;
  font-family: 'Manrope';
  margin-bottom: 5px !important;
  min-height: unset !important;
  min-width: 70px;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  font-size: 13px !important;
  font-weight: 500 !important;
  font-family: 'Manrope' !important;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background: #fff;
  min-height: unset !important;
}
::v-deep .form-input-wrap .form-click {
  min-height: unset !important;
  background: #fff;
  padding-bottom: 3px;
  padding-top: 3px;
}
::v-deep .form-input-wrap .form-click .selected-text {
  font-size: 13px !important;
  font-weight: 500 !important;
  font-family: 'Manrope' !important;
}
::v-deep .form-input-wrap .form-click.disabled .selected-text {
  padding-left: 0;
}
::v-deep .user-admin .form-input-wrap {
  width: unset;
  margin-right: 10px;
}
::v-deep .user-admin .form-input-wrap .form-click.disabled {
  opacity: 1;
  color: unset;
  background-color: transparent !important;
}
::v-deep .user-admin .form-input-wrap .form-click.disabled .action-icons {
  display: none;
}
</style>
