<template>
  <b-flip-modal :header="true" :is-self-serve-modal="true" @close="onClickCancel">
    <div slot="modal-body" class="modal-wrapper-box" @mousedown="onClickOutside">
      <b-loading-spinner v-if="isLoading" class="txt-center" />
      <div v-if="!isLoading" class="modal-container did-mediatype">
        <div class="publisher-modal-heading-container">
          <div class="publisher-modal-heading">
            {{ modalPayload.publisherName }} DIDs/Media Type
          </div>
          <div>
            <b-radio-tab-group
              :initial-group="toggleOptions"
              class="publisher-toggle-options"
              @update="togglePublisherOptions"
            />
          </div>
        </div>
        <div
          v-if="toggleOptions.find((opt) => opt.active).id === 'did'"
          class="modal-table-container"
        >
          <k-button
            :size="4"
            label="Add New Rule"
            :disabled="isDisabledAddNewDidRule()"
            @click="onAddNewDIDRule"
          />
          <table class="detail-table">
            <thead>
              <th class="com-title">DID</th>
              <th class="com-title">Last 30 Days Imps</th>
              <th class="com-title">Last 30 Days CPM</th>
              <th class="com-title">CTV IP% Pass</th>
              <th class="com-title">actions</th>
            </thead>
            <tbody>
              <tr v-for="(data, index) in didMediaTypeData.deals" :key="data.id">
                <td class="sub-li did">
                  <span v-if="data.readonly && data.dealId" :title="data.dealId">
                    {{ data.dealId }}</span
                  >
                  <k-flip-select
                    v-else
                    ref="dealDropdownOptions"
                    :options="getDealDropdownOptions()"
                    label-key="value"
                    :taggable="true"
                    :multiple="false"
                    :value="data.dealId"
                    :searchable="true"
                    label="Select Deal"
                    :class="`deal-cont ${data.deal && data.deal.dealId ? '' : 'empty-cont'}`"
                    @input="(value) => changeDidValue({ value, index })"
                  />
                </td>
                <td class="sub-li lmns">
                  <span
                    v-if="data.deal && data.deal.totalImpressionsLast30Days"
                    :title="data.deal.totalImpressionsLast30Days"
                  >
                    {{ formatNumberAsFullNumber(data.deal.totalImpressionsLast30Days, 'VISITS') }}
                  </span>
                </td>
                <td class="sub-li cpm">
                  <span v-if="data.deal && data.deal.cpm" :title="data.deal.cpm">
                    {{ formatNumberAsFullNumber(data.deal.cpm, 'CURRENCY') }}
                  </span>
                </td>
                <td class="sub-li ctvIp">
                  <span v-if="data.deal && data.deal.pctBadIp" :title="data.deal.pctBadIp">
                    {{ formatNumberAsFullNumber(data.deal.pctBadIp, 'RAW_PERCENTAGE') }}
                  </span>
                </td>
                <td class="sub-li">
                  <div class="button-box sub-li-item">
                    <div v-if="data.readonly" class="box px-2">
                      <span class="intip4 action-btn" data-tool-tip="Edit DID">
                        <font-awesome-icon :icon="['fas', 'pencil']" @click="onEditDid(index)" />
                      </span>
                      <span class="intip4 action-btn" data-tool-tip="Delete DID">
                        <font-awesome-icon
                          :icon="['fas', 'trash']"
                          @click="showConfirmModal('did', index)"
                        />
                      </span>
                    </div>
                    <div v-if="!data.readonly" class="box px-2">
                      <span
                        :class="dontSave ? 'disabled' : ''"
                        class="intip4 action-btn"
                        data-tool-tip="Save DID"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'save']"
                          @click="onSaveDid(data.dealId)"
                        />
                      </span>
                      <span
                        class="intip4 action-btn"
                        data-tool-tip="Discard Changes"
                        @click="onDiscardDidChanges(index)"
                      >
                        <font-awesome-icon :icon="['fas', 'times']" />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="toggleOptions.find((opt) => opt.active).id === 'media_type'"
          class="modal-table-container"
        >
          <k-button
            :size="4"
            label="Add New Rule"
            :disabled="isDisabledAddNewMediaTypeRule()"
            @click="onAddNewMediaTypeRule"
          />
          <div v-if="error" class="error">{{ error }}</div>
          <table class="detail-table">
            <thead>
              <th class="com-title">Media Type</th>
              <th class="com-title">Product Type</th>
              <th class="com-title">Site Domain</th>
              <th class="com-title">actions</th>
            </thead>
            <tbody>
              <tr v-for="(data, index) in didMediaTypeData.mediaTypes" :key="data.id">
                <td class="sub-li mediaType">
                  <b-select
                    ref="mediaTypeDropdown"
                    component-id="mediaTypeDropdown"
                    :disabled="data.readonly"
                    :options="filteredProductCategories"
                    :selected="
                      data.productCategory && data.productCategory.name
                        ? data.productCategory.name
                        : ''
                    "
                    @change="
                      (val) => changeMediaType({ value: val, index, node: 'productCategory' })
                    "
                  />
                </td>
                <td class="sub-li productType">
                  <b-select
                    ref="productTypeDropdown"
                    component-id="productTypeDropdown"
                    :disabled="data.readonly"
                    :options="getProductTypes(data.productCategory.id, data.productType.id)"
                    :selected="data.productType.name ? data.productType.name : ''"
                    @change="(val) => changeMediaType({ value: val, index, node: 'productType' })"
                  />
                </td>
                <td class="sub-li siteDomain">
                  <span v-if="data.readonly" :title="data.siteDomain">{{ data.siteDomain }}</span>
                  <k-text-input
                    v-else
                    :value="data.siteDomain"
                    :max-length="255"
                    :required="false"
                    :readonly="data.readonly"
                    @keyup="(val) => changeMediaType({ value: val, index, node: 'siteDomain' })"
                  />
                </td>
                <td class="sub-li">
                  <div class="button-box sub-li-item">
                    <div v-if="data.readonly" class="box px-2">
                      <span class="intip4 action-btn" data-tool-tip="Edit Media Type">
                        <font-awesome-icon
                          :icon="['fas', 'pencil']"
                          @click="onEditMediaType(index)"
                        />
                      </span>
                      <span class="intip4 action-btn" data-tool-tip="Delete Media Type">
                        <font-awesome-icon
                          :icon="['fas', 'trash']"
                          @click="showConfirmModal('mediaType', index)"
                        />
                      </span>
                    </div>
                    <div v-if="!data.readonly" class="box px-2">
                      <span class="intip4 action-btn" data-tool-tip="Save Media Type">
                        <font-awesome-icon
                          :icon="['fas', 'save']"
                          @click="onSaveMediaType(index)"
                        />
                      </span>
                      <span
                        class="intip4 action-btn"
                        data-tool-tip="Discard Changes"
                        @click="onDiscardMediaType(index)"
                      >
                        <font-awesome-icon :icon="['fas', 'times']" />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="publisherConfirmModal.show" class="confirm-modal-publisher">
        <FlipConfirmationModal
          width="400px"
          :type="publisherConfirmModal.text"
          :custom-heading="publisherConfirmModal.customHeading"
          :is-cancel="true"
          :is-remove="publisherConfirmModal.remove"
          @cancel="onCancel()"
          @remove="onDeleteTypeModal(publisherConfirmModal)"
        />
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import KFlipSelect from '@/adready-vue/components/elements/k-flip-select.vue';
import { isBlank } from 'adready-api/helpers/common';
import mediaPublisherApi from '@/api/media-publisher';
import publisherDealApi from '@/api/publisher-deal-api';
import publisherMediaTypeApi from '@/api/publisher-mediaType-api';
import dealsApi from '@/api/deal-api';
import BRadioTabGroup from '~/components/elements/b-radio-tab-group.vue';
import BSelect from '~/components/elements/b-select.vue';
import KButton from '~/components/elements/k-button.vue';
import forklift from '~/components/mixins/forklift-mixin';
import FlipConfirmationModal from './FlipConfirmationModal.vue';
import { formatNumberAsFullNumber } from '~/util/numeral';

export default {
  name: 'PubDidMediaTypeModal',
  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '~/components/elements/b-flip-modal.vue'),
    BRadioTabGroup,
    KButton,
    BSelect,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    FlipConfirmationModal,
    KFlipSelect,
  },
  mixins: [forklift],

  props: {
    modalPayload: {
      type: Object,
      required: true,
    },
    mediaPublisherData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      masterDealsOption: [],
      filteredProductCategories: [],
      dealOptions: [],
      tempMediaTypeData: {},
      didMediaTypeData: {},
      isLoading: false,
      tempDidsData: {},
      mediaTypeOptions: [],
      selectedDealIds: [],
      publisherConfirmModal: { show: false, text: '', customHeading: '', remove: false },
      toggleOptions: [
        {
          id: 'did',
          text: 'DIDs',
          active: true,
        },
        {
          id: 'media_type',
          text: 'Media Types',
          active: false,
        },
      ],
      didsData: [],
      mediaTypeData: [],
      formatNumberAsFullNumber,
      errorMsg: null,
      editDid: false,
      dontSave: false,
    };
  },
  computed: {
    currentAccountId: get('common/currentAccountId'),
    productCategories: get('common/productCategories'),
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
  },

  async mounted() {
    this.isLoading = true;
    await this.getDidMediaTypeData();
    await this.getDealOptions();
    await this.loadProductCategories();
    this.computeSelectedDealsAndMediaTypes();
    this.isLoading = false;
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    getDealDropdownOptions() {
      return this.dealOptions.map((d) => `${d.dealId}`);
    },
    computeSelectedDealsAndMediaTypes() {
      this.selectedDealIds = [];
      this.filteredProductCategories = [];
      this.mediaPublisherData.forEach((mediaData) => {
        this.selectedDealIds.push(
          ...(new Set(mediaData?.deals?.map((d) => d?.deal?.dealId)) ?? [])
        );
      });
      this.productCategories.forEach((category) => {
        this.filteredProductCategories.push(category);
      });
      if (this.selectedDealIds.length > 0) {
        this.dealOptions = this.masterDealsOption.filter(
          (d) => !this.selectedDealIds.includes(d.dealId)
        );
      }
    },
    showConfirmModal(type, index) {
      this.publisherConfirmModal = {
        show: true,
        text:
          type === 'did'
            ? `Are you sure you want to remove ${this.didMediaTypeData.deals[index]?.dealId ?? ''}?`
            : `Are you sure you want to remove ${this.didMediaTypeData.mediaTypes[index]
                ?.productCategory?.name ?? ''}?`,
        customHeading: type === 'did' ? 'Remove Deal' : 'Remove Media Type',
        remove: true,
        type,
        index,
      };
    },
    async getDealOptions() {
      const { data: options } = await dealsApi.getDeals();
      this.masterDealsOption = options;
      this.$forceUpdate();
    },
    onCancel() {
      this.publisherConfirmModal = { show: false, text: '', customHeading: '' };
    },
    onDeleteTypeModal(payload) {
      if (payload.type === 'did') {
        this.onDeleteDid(payload.index);
      } else if (payload.type === 'mediaType') {
        this.onDeleteMediaType(payload.index);
      }
      this.publisherConfirmModal = { show: false, text: '', customHeading: '' };
    },
    async getDidMediaTypeData() {
      const { data: publisherData } = await mediaPublisherApi.getMediaPublisherById(
        this.modalPayload.id
      );
      this.didMediaTypeData = { ...publisherData };
      this.makeAllRowsReadonly();
    },
    getProductTypes(mediaTypeId) {
      if (this.productCategories.length > 0) {
        const selectedMedia = this.productCategories.find((pd) => pd.id === mediaTypeId);

        if (selectedMedia) {
          return [...selectedMedia.productTypes];
        }
      }
      return [];
    },
    makeAllRowsReadonly() {
      this.didMediaTypeData?.deals?.forEach((deal) => {
        deal.readonly = true;
        return deal;
      });
      this.didMediaTypeData?.mediaTypes?.forEach((mediaType) => {
        mediaType.readonly = true;
        return mediaType;
      });
      this.didMediaTypeData.readonly = true;
      this.didMediaTypeData.hide = false;
    },

    onClickOutside(e) {
      const refArray = ['mediaTypeDropdown', 'productTypeDropdown'];
      if (e?.composedPath()?.filter((p) => p?.id?.includes('mediaTypeDropdown'))?.length > 0) {
        if (this?.$refs?.productTypeDropdown?.length > 0) {
          this?.$refs?.productTypeDropdown?.forEach((productRef) => productRef?.closeDropdown());
        } else {
          this?.$refs?.productTypeDropdown?.closeDropdown();
        }
        return;
      }
      if (e?.composedPath()?.filter((p) => p?.id?.includes('productTypeDropdown'))?.length > 0) {
        if (this?.$refs?.mediaTypeDropdown?.length > 0) {
          this?.$refs?.mediaTypeDropdown?.forEach((mediaRef) => mediaRef?.closeDropdown());
        } else {
          this?.$refs?.mediaTypeDropdown?.closeDropdown();
        }
        return;
      }
      refArray.forEach((ref) => {
        if (
          !(e.target.parentNode.className === 'norm-option') &&
          !(e.target.parentNode.className === 'dropdown-menu')
        ) {
          if (this?.$refs?.[ref]?.length > 0) {
            this?.$refs?.[ref]?.forEach((refVal) => refVal?.closeDropdown());
          } else if (this?.$refs?.[ref]?.closeDropdown) {
            this?.$refs?.[ref]?.closeDropdown();
          }
        }
      });
    },

    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    togglePublisherOptions(payload) {
      this.toggleOptions = payload;
    },
    async changeDidValue(payload) {
      const { index, value } = payload;
      const d = this.dealOptions.find((deal) => deal.dealId === +value);
      if (d === undefined) {
        this.didMediaTypeData.deals[index] = {
          ...this.didMediaTypeData.deals[index],
          dealId: +value,
          mediaPublisherId: this.didMediaTypeData.id,
          deal: null,
        };
      } else {
        this.didMediaTypeData.deals[index] = {
          ...this.didMediaTypeData.deals[index],
          dealId: +value,
          deal: this.dealOptions.find((deal) => deal.dealId === +value),
          mediaPublisherId: this.didMediaTypeData.id,
        };
      }
      this.dontSave = false;
      this.$forceUpdate();
    },

    onEditDid(index) {
      if (!this.isDisabledAddNewDidRule()) {
        this.tempDidsData = {};
        this.tempDidsData = JSON.parse(JSON.stringify({ ...this.didMediaTypeData.deals[index] }));
        this.didMediaTypeData.deals[index].readonly = false;
        this.didMediaTypeData.deals[index].isEdit = true;
        this.editDid = true;
        this.dontSave = false;
        this.$forceUpdate();
      }
    },
    async onDeleteDid(index) {
      try {
        this.isLoading = true;
        await publisherDealApi.deletePublisherDeal(this.didMediaTypeData.deals[index].id);
        this.didMediaTypeData.deals.splice(index, 1);
        const { data: newDidData } = await mediaPublisherApi.updatePublisher(
          this.didMediaTypeData.id,
          {
            ...this.didMediaTypeData,
          }
        );
        this.didMediaTypeData = {
          ...newDidData,
          mediaTypes: newDidData?.mediaTypes?.map((md) => ({ ...md, readonly: true })) || [],
        };
        this.didMediaTypeData?.deals?.forEach((deals) => {
          deals.readonly = true;
          return deals;
        });
        this.didMediaTypeData.readonly = true;
        this.didMediaTypeData.hide = false;
        this.$emit('update-deal-payload', this.modalPayload, this.didMediaTypeData);
        this.computeSelectedDealsAndMediaTypes();
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
    async onCreateDid(did, mId) {
      this.editDid = false;
      try {
        this.isLoading = true;
        await publisherDealApi.createPublisherDeal({
          dealId: did,
          mediaPublisherId: mId,
        });
        this.getDidMediaTypeData();
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
    async onSaveDid(value) {
      if (!Number.isInteger(value)) {
        this.getDidMediaTypeData();
        this.$emit('update-deal-payload', this.modalPayload, this.modalPayload);
        return;
      }
      this.dontSave = true;
      const { data } = await mediaPublisherApi.mediaPublisher();
      const result = data.filter((deal) => deal.deals.some((d) => d.dealId === +value));

      if (!isBlank(result)) {
        this.publisherConfirmModal = {
          show: true,
          text: `The deal Id ${value} is already mapped to the publisher ${result[0].publisherName}.`,
          customHeading: 'Duplicate Deal Id',
          remove: false,
        };

        if (!this.editDid) {
          this.didMediaTypeData.deals.splice(0, 1);
        }
        this.dontSave = true;
        this.editDid = false;
        this.makeAllRowsReadonly();
        this.getDidMediaTypeData();
        this.$emit('update-deal-payload', this.modalPayload, this.modalPayload);
        this.isLoading = false;
      } else {
        this.didMediaTypeData.deals.forEach((d) => {
          if (isBlank(d.deal) && !d.isEdit && d.dealId === +value) {
            // new did entered which is not there in deal publisher table
            this.isLoading = true;
            this.onCreateDid(d.dealId, d.mediaPublisherId);
            this.dontSave = true;
            this.makeAllRowsReadonly();
            this.$emit('update-deal-payload', this.modalPayload, this.didMediaTypeData);
            this.isLoading = false;
          } else if (d.deal?.dealId === +value || d.isEdit) {
            this.dontSave = false;
          }
        });
        if (!this.dontSave) {
          try {
            this.isLoading = true;
            const { data: newDidData } = await mediaPublisherApi.updatePublisher(
              this.didMediaTypeData.id,
              {
                ...this.didMediaTypeData,
              }
            );
            this.didMediaTypeData = { ...newDidData };
            this.makeAllRowsReadonly();
            this.$emit('update-deal-payload', this.modalPayload, this.didMediaTypeData);
            this.computeSelectedDealsAndMediaTypes();
            this.isLoading = false;
          } catch (e) {
            console.error(e);
            this.isLoading = false;
          }
        }
      }
    },
    onDiscardDidChanges(index) {
      if (!this.didMediaTypeData.deals[index]?.isNew) {
        this.didMediaTypeData.deals[index] = this.tempDidsData;
        this.tempDidsData = {};
        this.didMediaTypeData.deals[index].readonly = true;
      } else {
        this.didMediaTypeData.deals.splice(index, 1);
      }
      this.$forceUpdate();
    },
    isDisabledAddNewMediaTypeRule() {
      let isDisabled = false;
      this.didMediaTypeData.mediaTypes.forEach((mt) => {
        if (!mt.readonly) {
          isDisabled = true;
        }
      });
      return isDisabled;
    },
    isDisabledAddNewDidRule() {
      let isDisabled = false;
      this.didMediaTypeData?.deals?.forEach((deal) => {
        if (!deal.readonly) {
          isDisabled = true;
        }
      });
      return isDisabled;
    },
    onEditMediaType(index) {
      if (!this.isDisabledAddNewMediaTypeRule()) {
        this.tempMediaTypeData = {};
        this.tempMediaTypeData = JSON.parse(
          JSON.stringify({ ...this.didMediaTypeData.mediaTypes[index] })
        );
        this.didMediaTypeData.mediaTypes[index].readonly = false;
        this.$forceUpdate();
      }
    },
    onDiscardMediaType(index) {
      this.errorMsg = null;
      if (!this.didMediaTypeData.mediaTypes[index]?.isNew) {
        this.didMediaTypeData.mediaTypes[index] = this.tempMediaTypeData;
        this.tempMediaTypeData = {};
        this.didMediaTypeData.mediaTypes[index].readonly = true;
      } else {
        this.didMediaTypeData.mediaTypes.splice(index, 1);
      }
      this.$forceUpdate();
    },
    async onDeleteMediaType(index) {
      try {
        this.isLoading = true;
        await publisherMediaTypeApi.deletePublisherMediaType(
          this.didMediaTypeData.mediaTypes[index].id
        );
        this.didMediaTypeData.mediaTypes.splice(index, 1);
        const { data: newDidData } = await mediaPublisherApi.updatePublisher(
          this.didMediaTypeData.id,
          {
            ...this.didMediaTypeData,
          }
        );
        this.didMediaTypeData = { ...newDidData };
        this.makeAllRowsReadonly();
        this.$emit('update-deal-payload', this.modalPayload, this.didMediaTypeData);
        this.computeSelectedDealsAndMediaTypes();
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
    async onSaveMediaType() {
      let dontSave = false;
      this.didMediaTypeData.mediaTypes.forEach((md) => {
        if (!md.productCategory.id || !md.productType.id) {
          dontSave = true;
          this.errorMsg = 'Media Type and Product Type are mandatory fields';
        }
      });

      if (!dontSave) {
        const selectedMediaTypes = {};
        this.mediaPublisherData
          .filter((mp) => mp.id !== this.didMediaTypeData.id)
          .forEach((mp) => {
            mp?.mediaTypes?.forEach((m) => {
              const sd = isBlank(m.siteDomain) ? '' : m.siteDomain;
              const key = `${m.productCategory.id}_${m.productType.id}_${sd}`;
              selectedMediaTypes[key] = mp.publisherName;
            });
          });

        this.didMediaTypeData.mediaTypes.forEach((m) => {
          const sd = isBlank(m.siteDomain) ? '' : m.siteDomain;
          const key = `${m.productCategory.id}_${m.productType.id}_${sd}`;
          if (Object.keys(selectedMediaTypes).includes(key)) {
            dontSave = true;
            const pub = selectedMediaTypes[key] || '';
            this.errorMsg = `The combination of Media Type, Product Type and Site Domain is already in use by ${pub}`;
          } else {
            selectedMediaTypes[key] = this.modalPayload.publisherName;
          }
        });
      }

      if (!dontSave) {
        try {
          this.isLoading = true;
          const { data: newDidData } = await mediaPublisherApi.updatePublisher(
            this.didMediaTypeData.id,
            {
              ...this.didMediaTypeData,
            }
          );
          this.didMediaTypeData = { ...newDidData };
          this.makeAllRowsReadonly();
          this.$emit('update-deal-payload', this.modalPayload, this.didMediaTypeData);
          this.computeSelectedDealsAndMediaTypes();
          this.isLoading = false;
          this.errorMsg = null;
        } catch (e) {
          console.error(e);
          this.isLoading = false;
          this.errorMsg = 'Unable to save rule.';
        }
      }
    },
    changeMediaType(payload) {
      const { node, index, value } = payload;
      if (node === 'productCategory') {
        this.didMediaTypeData.mediaTypes[index].productType = {};
        this.didMediaTypeData.mediaTypes[index][node] = {
          id: value,
          name: this.filteredProductCategories.find((pct) => pct.id === value).name,
        };
      } else if (node === 'productType') {
        const productCategoryId = this.didMediaTypeData.mediaTypes[index]?.productCategory?.id;
        const productTypes =
          this.productCategories.find((pd) => pd.id === productCategoryId)?.productTypes || [];
        const selectedProduct = productTypes.find((product) => product.id === +value) || {};
        this.didMediaTypeData.mediaTypes[index][node] = {
          ...selectedProduct,
        };
      } else {
        this.didMediaTypeData.mediaTypes[index][node] = isBlank(value) ? '' : value.toLowerCase();
      }
      this.$forceUpdate();
    },
    onAddNewDIDRule() {
      this.didMediaTypeData.deals.unshift({
        deal: {
          cpm: '',
          dealId: 0,
          pctBadIp: '',
          totalImpressionsLast30Days: '',
        },
        readonly: false,
        isNew: true,
        mediaPublisherId: this.didMediaTypeData.id,
      });
      this.dontSave = true;
    },
    onAddNewMediaTypeRule() {
      this.didMediaTypeData.mediaTypes.unshift({
        mediaPublisherId: this.didMediaTypeData.id,
        productCategory: {},
        productType: {},
        siteDomain: '',
        readonly: false,
        isNew: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .cdp-top.selfServeContainer {
  border: unset;
  height: unset;
  padding: unset;
}
::v-deep .modal-wrapper.selfServBox {
  width: 800px;
}
::v-deep .modal-wrapper.selfServBox .modal-wrapper-box {
  padding-bottom: 100px;
}
::v-deep .btn-outline {
  border: none !important;
}
::v-deep .btn-outline .k-btn.btn-size-3 {
  background-color: transparent;
  color: #555 !important;
  span {
    color: #555 !important;
    &:hover {
      color: #fff !important;
    }
  }
}
::v-deep .k-btn.btn-size-3 span.label {
  font-size: 12px !important;
  font-family: 'Manrope';
}
::v-deep .k-btn span.label {
  color: var(--adminovertext);
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .selfServBox .modal-container .modal-footer {
  background: unset;
  height: unset;
  padding: unset;
}
::v-deep .sub-li .form-input.form-number-input .form-input-field {
  padding: 3px;
  min-height: unset;
  color: #cad1d6 !important;
  font-size: 14px;
  background-color: #323239;
}
::v-deep .sub-li .form-input {
  margin: unset;
}
::v-deep .sub-li .form-input.form-number-input .form-input-arrow-container {
  display: none;
}
.publisher-modal-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  .publisher-modal-heading {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
  }
}
::v-deep .publisher-toggle-options .radio-tab-wrap {
  padding: 7px;
}
.modal-table-container {
  margin-top: 20px;
}
.detail-table {
  width: 95%;
  // margin: 20px 8px 5px 8px;
  .com-title {
    // font-size: 15px;
    font-weight: 400;
    line-height: 1.1em;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    font-size: 18px;
    color: #cad1d6;
    margin-bottom: 20px;
    margin-left: 12px !important;
    margin-right: 30px !important;
    padding: 10px 10px 10px 0;
    min-width: 120px;
    span {
      text-transform: capitalize;
    }
    &:last-child {
      text-align: center;
    }
    &:first-child {
      min-width: 167px;
    }
  }
  .sub-li {
    margin-left: 12px !important;
    margin-right: 30px !important;
    padding: 10px 10px 10px 0;
    margin-top: 15px;
    font-size: 14px;
    vertical-align: middle;
    &.siteDomain span {
      width: 150px;
    }
    &.lmns span {
      width: 100px;
      line-height: 15px;
    }
    &.cpm span {
      width: 80px;
    }
    &.ctvIp span {
      width: 60px;
    }
    span {
      &.intip4.action-btn {
        overflow: unset;
        margin-right: 20px;
      }
      color: rgba(130, 130, 140, 0.8);
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      line-height: 0.75rem;

      //overflow: hidden;
    }
  }
}
.box.px-2 {
  // display: flex;
  // justify-content: space-evenly;
  text-align: center;
}
::v-deep .sub-li .dropdown-menu {
  max-height: 100px;
}
@media screen {
  * {
    box-sizing: border-box;
  }
  .intip4 {
    position: relative;
    top: 0;
  }
  // .intip4.action-btn {
  //   color: #fff;
  // }
  .intip4[data-tool-tip]::after {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 99;
    display: block;
    min-width: 120px;
    padding: 10px 12px;
    margin-left: -50px;
    font-size: 12px;
    line-height: 1.2em;
    text-align: center;
    pointer-events: none;
    content: attr(data-tool-tip);
    border-radius: 3px;
    transition: ease 0.3s;
    transform: scale(0);
    transform-origin: bottom center;
  }
  .intip4[data-tool-tip]::after {
    width: auto;
    margin-left: -70px;
    font-size: 12px;
    font-weight: 400;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
  }
  .intip4[data-tool-tip]:hover::after {
    bottom: 20px;
    transform: scale(1);
  }
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #fff;
}
::v-deep .did .form-input-wrap {
  // background-color: #fff;
  // cursor: pointer;
  // border: 1px solid #cccccc;
  // min-height: 20px;
  // border-radius: unset;
  // position: relative;
  .form-click .selected-text {
    font-size: inherit;
  }
}

::v-deep .form-input .form-input-field.v-select.multiple-input-field .vs__selected-options {
  padding: 10px;
}
::v-deep .form-input .form-input-field.v-select .vs__selected-options .vs__selected {
  background-color: unset;
}

::v-deep .form-input .form-input-field.v-select.vs--open .vs__dropdown-toggle {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  background: var(--adminprimarycolor) !important;
  color: #fff !important;
  font-weight: 300;
  font-size: 13px;
  font-family: 'Manrope', sans-serif;
  padding: 3px 7px;
  margin: 0px 4px 1px 10px;
  border-radius: 2px;
  display: inline-block;
  border: unset;
  opacity: 1;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: rgba(255, 255, 255, 0.8);
}

body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--selected {
  display: none;
  background-color: #d9d8d8;
  color: #525b65;
}
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
  color: #525b65;
  background-color: #d9d8d8;
}
body .vs__dropdown-menu .vs__dropdown-option:hover {
  background: #d9d8d8;
}

body .vs__dropdown-hover:active {
  background: #717171 !important;
  box-shadow: inset 0 0 10px #000000 !important;
}
body .vs__dropdown-hover span:active {
  color: white;
}
::v-deep .did-mediatype {
  //overflow: hidden;
  .modal-body .form-click {
    padding: 0 11px 0 0 !important;
    margin: 0 0 0px !important;
    color: #cad1d6;
    background-color: #323239;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid hsla(0, 0%, 66.7%, 0.1);
  }
}
::v-deep .did-mediatype .form-input .form-input-field.v-select .vs__search {
  color: inherit;
  font-size: inherit;
}
::v-deep .did-mediatype .sub-li.did .form-input .form-input-field {
  width: 100% !important;
  padding: 0 !important;
}
::v-deep .did-mediatype .sub-li.did .form-input .form-input-field.v-select .vs__dropdown-toggle {
  color: inherit !important;
  // background-color: transparent !important;
  background-color: #323239 !important;
}
::v-deep .sub-li.did .form-input-wrap.deal-cont .form-click {
  padding: unset !important;
  margin: unset !important;
  border: unset !important;
}
::v-deep .sub-li.did .form-input-wrap {
  width: 100%;
  padding: 0;
  float: left;
  height: 30px;
  font-size: 0.875rem;
  display: block;
  min-height: 40px;
  color: #cad1d6;
  background-color: #323239;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgba(170, 170, 170, 0.1);
}
::v-deep .sub-li.did .form-input-wrap .selected-text {
  color: #fff !important;
  font-weight: 300;
  font-size: 13px;
  font-family: 'Manrope', sans-serif;
  padding: 3px 7px;
  margin: 0px 4px 1px 10px;
  border-radius: 2px;
  display: inline-block;
  border: unset;
  opacity: 1;
}
::v-deep .sub-li.did .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  background: transparent !important;
}
::v-deep .sub-li.did .form-input-wrap.empty-cont .selected-text {
  width: 120px;
  background-color: unset;
}
::v-deep .confirm-modal-publisher .modal-wrapper-box {
  padding-bottom: 0 !important;
  display: flex !important;
  align-items: center !important;
}
::v-deep .confirm-modal-publisher .selfServBox .modal-container {
  min-height: 30px !important;
  /* min-width: 300px !important; */
  max-width: unset !important;
  min-width: unset !important;
  width: 100% !important;
  color: #a1a5af !important;
}
::v-deep .confirm-modal-publisher .modal-mask .selfServBox .modal-container .modal-footer {
  background-color: unset !important;
  text-align: center !important;
}
::v-deep .confirm-modal-publisher .modal-mask .selfservbox .modal-container .container-text {
  text-align: center;
}
::v-deep .modal-wrapper.selfServBox .modal-wrapper-box {
  overflow-x: hidden !important;
}
::v-deep .sub-li.did .form-input .form-input-field.v-select.vs--open .vs__dropdown-toggle {
  box-shadow: none !important;
  outline: none !important;
}
::v-deep .sub-li.did .form-input .form-input-field {
  border: 0 !important;
  min-height: unset !important;
}
::v-deep .sub-li.did .form-input .modal-body .form-input .form-input-field.v-select {
  min-height: unset !important;
  height: unset !important;
}
::v-deep .sub-li.siteDomain .form-input .form-input-field {
  width: 150px;
  text-transform: lowercase;
  padding: 3px;
  color: #cad1d6;
  font-size: 14px;
  background-color: #323239;
}
.disabled {
  pointer-events: none;
}
</style>
