import { get, sync } from 'vuex-pathify';

import moment from 'moment';
import { inert, isBlank } from 'adready-api/helpers/common';
import planApi from 'adready-api/api/plans';
import lineItemsApi from 'adready-api/api/line-items';
import { Plan } from 'adready-api/model/plan';
import lineItemHelpers from '~/helpers/line-item-helpers';
import ioHelpers from '~/helpers/io-helpers';
import {
  LAYOUT_TYPES,
  ENUM_IS_WATCH_STATS,
  SELF_SERVE_OWNER_EMAIL,
  SELF_SERVE_OWNER_ID,
  FLIP_SELECTED_MENU_PAGE,
} from '~/constant';
import forklift from '~/components/mixins/forklift-mixin';
import emailMixin from '~/components/mixins/email-mixin';

const computed = {
  activeItem: get('common/activeItem'),
  currentUserId: get('common/currentUserId'),
  advertiser: get('common/advertiser'),
  account: get('common/account'),
  user: get('common/currentUser'),
  allUsers: get('common/allUsers'),
  plan: sync('common/plan'),
  orgLineItems: sync('common/orgLineItems'),
  expandedPlanId: sync('common/expandedPlanId'),
};
const methods = {
  ...forklift.methods,
  ...emailMixin.methods,
  addNewCampaignButtonClick() {
    const campaign = ioHelpers.createDefaultCampaign(this.account.id, this.advertiser.id);
    campaign.contactUserId = this.currentUserId;
    campaign.customUserId = this.currentUserId;
    const ownerUser = this.allUsers.find((item) => item.email === SELF_SERVE_OWNER_EMAIL);
    if (!isBlank(ownerUser)) {
      campaign.ownerId = ownerUser.id;
    } else {
      campaign.ownerId = SELF_SERVE_OWNER_ID;
    }
    const newLineItem = lineItemHelpers.createDefaultAdGroup('', campaign);
    this.plan = campaign;
    this.expandedPlanId = undefined;
    this.$store.dispatch('common/updateActiveItem', {});
    this.$store.dispatch('common/addLineItem', newLineItem);
    this.orgLineItems = [newLineItem];
    this.$store.dispatch('common/setRefreshCampaignValidation', true);
    this.changeSelectedMenuOption(LAYOUT_TYPES.NEW_CAMPAIGNS);
  },
  changeSelectedMenuOption(option, subOption, elementId = '') {
    if (option === LAYOUT_TYPES.FLIPPIXEL) {
      this.$store.dispatch('common/setIsWatchStats', ENUM_IS_WATCH_STATS);
    }
    if (option !== LAYOUT_TYPES.BL_CAMPAIGN_SETUP && option !== LAYOUT_TYPES.ADMIN) {
      localStorage.setItem(FLIP_SELECTED_MENU_PAGE, option);
    }
    this.$store.dispatch(
      'common/setSelectedMenuOptions',
      isBlank(subOption) ? option : { menu: option, subMenu: subOption, elementId }
    );
    this.fireTopLevelMenuSelection(option);
  },
  fireTopLevelMenuSelection(option) {
    const gaEv = {
      item: option,
    };
    this.fireGAEvent('flp_tlpu_selection', gaEv, false);
  },
  validatePlan() {
    const errorObj = {};
    const campaignName = ioHelpers.getPlanFieldValue(this.plan, 'campaignName');
    if (isBlank(campaignName)) {
      errorObj.campaignName = 'Campaign name missing';
    }

    const flightStartDate = ioHelpers.getPlanFieldValue(this.plan, 'flightStartDate');
    if (isBlank(flightStartDate)) {
      errorObj.flightStartDate = 'Campaign start date missing';
    }
    const flightEndDate = ioHelpers.getPlanFieldValue(this.plan, 'flightEndDate');
    if (isBlank(flightEndDate)) {
      errorObj.flightEndDate = 'Campaign end date missing';
    }
    if (!isBlank(flightStartDate) && !isBlank(flightEndDate)) {
      if (moment(flightStartDate).isAfter(moment(flightEndDate))) {
        errorObj.flightStartDate = 'Campaign Start Date must not be greater than campaign end date';
      }
    }

    const flipCampaignInfo = ioHelpers.getPlanFieldValue(this.plan, 'flipCampaignInfo');
    if (!isBlank(flipCampaignInfo) && isBlank(flipCampaignInfo.goal.name)) {
      errorObj.goal = 'Campaign goal missing';
    }
    return errorObj;
  },
  validateLineItem(lineItem) {
    const errorObj = {};
    const name = lineItemHelpers.getLineItemFieldValue(lineItem, 'name');
    if (isBlank(name)) {
      errorObj.name = 'Ad group name missing';
    }
    const audienceName = lineItemHelpers.getLineItemFieldValue(lineItem, 'audienceName');
    if (isBlank(audienceName)) {
      errorObj.audienceName = 'Audience Name missing';
    }
    const lineItemBudget = lineItemHelpers.getLineItemFieldValue(lineItem, 'lineItemBudget');
    if (isBlank(lineItemBudget)) {
      errorObj.lineItemBudget = 'Ad group budget missing';
    } else if (lineItemBudget < 5000) {
      errorObj.lineItemBudget = 'Minimum budget required is $5,000';
    } else if (lineItemBudget > 100000) {
      errorObj.lineItemBudget = 'Maximum budget should be less than $100,000';
    }

    const flightStartDate = lineItemHelpers.getLineItemFieldValue(lineItem, 'flightStartDate');
    if (isBlank(flightStartDate)) {
      errorObj.flightStartDate = 'Ad group start date missing';
    }
    const flightEndDate = lineItemHelpers.getLineItemFieldValue(lineItem, 'flightEndDate');
    if (isBlank(flightEndDate)) {
      errorObj.flightEndDate = 'Ad group end date missing';
    }
    if (!isBlank(flightStartDate) && !isBlank(flightEndDate)) {
      if (moment(flightStartDate).isAfter(moment(flightEndDate))) {
        errorObj.flightStartDate =
          'Ad group Start Date should not be greater than ad group end date';
      }
    }
    if (this.isFirstAndThirdPartySelected(lineItem)) {
      errorObj.targetings_first_party =
        'Either First Party Targeting or Third Party Targeting should be selected';
    }

    const mediaData = lineItemHelpers.getLineItemFieldValue(lineItem, 'mediaData');
    let mergedMediaData = [];
    if (mediaData) {
      mergedMediaData = [...mediaData.os, ...mediaData.streamers, ...mediaData.networks];
    }
    if (mergedMediaData.length < 6 || mergedMediaData.length > 12) {
      errorObj.publisher_os =
        'Please select between 6 and 12 media publishers for optimized performance.';
    }
    return errorObj;
  },
  isFirstAndThirdPartySelected(lineItem) {
    const targetings = lineItemHelpers.getLineItemFieldValue(lineItem, 'targetings');
    const firstPartyTargetingValue = lineItemHelpers.fetchFirstPartyTargetingValue(targetings);
    const behavioralTargetingValue = lineItemHelpers.fetchBehavioralTargetingValue(targetings);
    const genderValue = lineItemHelpers.fetchDemographicTargetingValue(targetings, 'gender');
    const ageValue = lineItemHelpers.fetchDemographicTargetingValue(targetings, 'age');
    const incomeValue = lineItemHelpers.fetchDemographicTargetingValue(targetings, 'income');
    const isDemoTargetingSelected = !isBlank(`${genderValue}${ageValue}${incomeValue}`);
    const isBehavioralTargetingSelected = !isBlank(behavioralTargetingValue);
    const isFirstPartyTargetingSelected = !isBlank(firstPartyTargetingValue);
    return (
      (isDemoTargetingSelected || isBehavioralTargetingSelected) && isFirstPartyTargetingSelected
    );
  },
  validateLucidData(expectedImpressionsCount) {
    const errorObj = {};
    if (
      isBlank(expectedImpressionsCount) ||
      expectedImpressionsCount < 1000000 ||
      expectedImpressionsCount > 2147483647
    ) {
      errorObj.expectedImpressionsCount =
        'Expected Impressions Count must be between 1M to 2147483647';
    }

    const flipCampaignInfo = this?.plan?.flipCampaignInfo;
    if (isBlank(flipCampaignInfo) || flipCampaignInfo === null) {
      errorObj.flipCampaignInfo = 'Flip Campaign Info cannot be empty';
    }
    const conversionWindow = flipCampaignInfo?.conversionWindow?.value
      ? Number(flipCampaignInfo?.conversionWindow?.value)
      : 0;
    if (conversionWindow === 0) {
      errorObj.conversionWindow = 'Conversion Window value must be greater than 0';
    }
    if (this.activeBrandLiftInfo?.competitors?.length !== 4) {
      errorObj.competitors = 'Exactly 4 competitor tags required';
    }
    if (isBlank(this.activeBrandLiftInfo?.category)) {
      errorObj.blCategory = 'Category missing';
    }
    if (isBlank(this.activeBrandLiftInfo?.type?.id)) {
      errorObj.typeId = 'Product type missing';
    }
    return errorObj;
  },
  saveOrUpdateCRPlan(planToUpdate) {
    const plan = new Plan(inert(planToUpdate));
    plan.lineItems = [];
    if (plan.id) {
      planApi.update(planToUpdate.id, planToUpdate).then((updatedPlan) => {
        const orgPlan = this.plans.find((p) => p.id === updatedPlan.parentId);
        const inertPlan = orgPlan.inertClone();
        inertPlan.crPlans.push(updatedPlan);
        this.$store.dispatch('common/updatePlan', { plan: inertPlan });
        this.sendEmailNotification(updatedPlan);
      });
    } else {
      planApi.create(plan).then((updatedPlan) => {
        const orgPlan = this.plans.find((p) => p.id === updatedPlan.parentId);
        const inertPlan = orgPlan.inertClone();
        inertPlan.crPlans.push(updatedPlan);
        this.$store.dispatch('common/updatePlan', { plan: inertPlan });
        this.sendEmailNotification(updatedPlan);
      });
    }
  },

  saveOrUpdateCRLineItems(l) {
    if (l.id) {
      lineItemsApi
        .update(
          {
            planId: l.planId,
            id: l.id,
          },
          l
        )
        .then(() => {
          const orgLineItem = this.orgLineItems.find((l1) => l1.id === l.parentId);
          const inertLi = orgLineItem.inertClone();
          this.$store.dispatch('common/updateOrgLineItem', { lineItem: inertLi });
        });
    } else {
      lineItemsApi.create(l.planId, l).then((updatedLineItem) => {
        const orgLineItem = this.orgLineItems.find((l1) => l1.id === l.parentId);
        const inertLi = orgLineItem.inertClone();
        inertLi.crLineItems.push(updatedLineItem);
        this.$store.dispatch('common/updateOrgLineItem', { lineItem: inertLi });
      });
    }
  },
  updateOrgLineItems(lineItems) {
    if (!isBlank(lineItems)) {
      const liPromises = lineItems.map((l) => {
        return lineItemHelpers.onClickLineConfirmChanges(l);
      });
      Promise.all(liPromises).then(() => {
        console.log('lineItems updated');
      });
    }
  },
  async saveOrUpdatePlan(planToUpdate, callback) {
    const plan = new Plan(inert(planToUpdate));
    const { lineItems } = plan;
    plan.lineItems = [];
    if (this.plan.id) {
      this.expandedPlanId = this.plan.id;
      planApi.update(planToUpdate.id, planToUpdate).then((updatedPlan) => {
        if (!isBlank(lineItems)) {
          const liPromises = lineItems.map((l) => {
            l.planId = updatedPlan.id;
            return lineItemHelpers.onClickLineConfirmChanges(l);
          });

          Promise.all(liPromises).then((lis) => {
            updatedPlan.lineItems = lis;
            if (callback) {
              callback(updatedPlan);
            }
          });
        } else if (callback) {
          callback(updatedPlan);
        }
      });
    } else {
      plan.source = 'FLIPPLUS';
      await planApi.create(plan).then((updatedPlan) => {
        this.$store.dispatch('common/updatePlan', {
          plan: { planToUpdate, id: updatedPlan.id },
        });
        plan.source = updatedPlan.source;
        this.expandedPlanId = updatedPlan.id;
        if (!isBlank(lineItems)) {
          const liPromises = lineItems.map((l) => {
            l.planId = updatedPlan.id;
            return lineItemHelpers.onClickLineConfirmChanges(l);
          });

          Promise.all(liPromises).then((lis) => {
            updatedPlan.lineItems = lis;
            if (callback) {
              callback(updatedPlan);
            }
          });
        } else if (callback) {
          callback(updatedPlan);
        }
      });
    }
  },
};
export default {
  computed,
  methods,
};
