<template>
  <b-flip-modal
    :header="true"
    :header-text="headerText"
    :is-self-serve-modal="true"
    @close="$emit('close')"
  >
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="modal-header">
          <span class="container-text" v-html="message"></span>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div v-if="isAlert" class="actions-btn">
        <k-button
          :size="3"
          :label="positiveButtonLabel"
          class="mr-20"
          @click="$emit('onPositiveButtonClick')"
        />
      </div>
      <div v-else class="actions-btn">
        <k-button
          :size="3"
          :label="positiveButtonLabel"
          class="mr-20"
          @click="$emit('onPositiveButtonClick')"
        />
        <k-button
          :size="3"
          :label="negativeButtonLabel"
          class="btn-outline"
          @click="$emit('onNegativeButtonClick')"
        />
      </div>
    </div>
  </b-flip-modal>
</template>
<script>
export default {
  name: 'ConfirmationModal',

  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '~/components/elements/b-flip-modal.vue'),
  },

  props: {
    message: {
      type: String,
      required: true,
    },
    headerText: {
      required: false,
      type: String,
      default: null,
    },
    positiveButtonLabel: {
      required: false,
      type: String,
      default: 'YES',
    },
    negativeButtonLabel: {
      required: false,
      type: String,
      default: 'NO',
    },
    isAlert: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-outline {
  border: none !important;
}
::v-deep .btn-outline .k-btn.btn-size-3 {
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #555 !important;
  span {
    color: #555 !important;
    &:hover {
      color: #fff !important;
    }
  }
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Manrope';
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.mr-20 {
  margin-right: 20px;
}
</style>
