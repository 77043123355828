<template>
  <b-flip-modal v-if="showModal" :header="false" @close="onClickClose">
    <div slot="modal-body">
      <div class="header-txt">Add Dashboard URL for {{ accountData.name }}</div>
      <br />
      <div v-if="responseMessage" class="row between-xs response-msg">
        {{ responseMessage }}
      </div>
      <div v-if="error" class="error">{{ error }}</div>
      <div class="inp-wrap">
        <p class="inp-title">Title</p>
        <k-text-input
          ref="Title"
          :value="dashboardUrl.title"
          @change="onChangeTitle"
          @focusin="hasFocus = true"
          @focusout="hasFocus = false"
        />
      </div>
      <div class="inp-wrap">
        <p class="inp-title">Link</p>
        <k-text-input
          :value="dashboardUrl.link"
          :required="true"
          @change="onChangeLink"
          @focusin="hasFocus = true"
          @focusout="hasFocus = false"
        />
      </div>
      <br />
      <k-button :size="4" label="Save" @click="save" />
      <br />
      <br />
      <div
        v-for="(url, index) in accountData.dashboardUrls"
        :key="index"
        class="row around-xs action"
      >
        <tr>
          <td>
            {{ getTitle(url.title) }}
            |
            {{ url.link }}
          </td>
          <td v-if="url.id">
            <span class="intip4" data-tool-tip="Edit Dashboard URL">
              <font-awesome-icon :icon="['fas', 'pencil']" @click="onClickEdit(url)" />
            </span>
          </td>
          <td v-if="url.id">
            <span class="intip4" data-tool-tip="Delete">
              <font-awesome-icon :icon="['fas', 'trash']" @click="onDelete(url)" />
            </span>
          </td>
        </tr>
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
import _ from 'underscore';
import { isBlank } from 'adready-api/helpers/common';
import accountApi from 'adready-api/api/account';
import BFlipModal from '~/components/elements/b-flip-modal.vue';

export default {
  name: 'AddDashboardURL',
  components: {
    BFlipModal,
  },

  props: {
    accountObj: {
      required: true,
      type: Object,
    },
    showModal: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dashboardUrl: {},
      responseMessage: null,
      isDeleted: false,
      errorMsg: null,
    };
  },
  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
  },

  asyncComputed: {
    accountData: {
      default: {},
      async get() {
        if (this.accountObj.id) {
          return accountApi.account(this.accountObj.id);
        }
        return {};
      },
    },
  },
  methods: {
    onClickClose() {
      this.$emit('onClickDashboardModalClose');
    },
    getTitle(title) {
      if (isBlank(title)) {
        return 'Title Not Set';
      }
      return title;
    },
    onChangeTitle(val) {
      this.dashboardUrl.title = val;
    },
    onChangeLink(val) {
      this.dashboardUrl.link = val;
    },
    onDelete(url) {
      this.isDeleted = true;
      this.accountData.dashboardUrls = this.accountData.dashboardUrls.filter(
        (u) => u.id !== url.id
      );
      this.save();
    },
    onClickEdit(url) {
      this.dashboardUrl = { ...url };
    },

    save() {
      if (isBlank(this.dashboardUrl.link) && !this.isDeleted) {
        this.errorMsg = 'Please Enter Dashboard URL';
        return;
      }
      if (isBlank(this.accountData.dashboardUrls)) {
        this.accountData.dashboardUrls = [];
      }
      if (this.dashboardUrl.id) {
        const url = this.accountData.dashboardUrls.find((u) => u.id === this.dashboardUrl.id);
        Object.assign(url, this.dashboardUrl);
      } else if (!_.isEmpty(this.dashboardUrl)) {
        this.dashboardUrl.accountId = this.accountObj.id;
        this.accountData.dashboardUrls.push(this.dashboardUrl);
      }

      accountApi.update(this.accountObj.id, this.accountData).then(() => {
        this.$emit('onClickDashboardModalClose', this.accountObj.id);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  color: white;
  padding: 5px;
}
.inp-title {
  margin-right: 6px;
  margin-top: 8px;
  display: inline-block;
  font-size: 0.875rem;
  color: #cad1d6;
  letter-spacing: 0.07em;
  float: left;
  line-height: 20px;
}

.error {
  padding: 5px;
  font-weight: bold;
  color: $alert;
}
.header-txt {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.inp-wrap {
  position: relative;
  display: flex;
  .inp-icon {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 40px;
    height: 34px;
    padding: 0.8rem;
    background-color: var(--adminprimarycolor);
    border-radius: 0px 3px 3px 0px;
    color: #ffffff;
  }
}
::v-deep .form-input-field {
  float: left;
  height: 30px;
  font-size: 0.875rem;
  display: block;
  min-height: 40px;
  padding: 8px 10px;
  color: #cad1d6;
  background-color: #323239;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid hsla(0, 0%, 66.7%, 0.1);
}
::v-deep .k-btn span.label {
  color: var(--adminovertext);
}
</style>
