<template>
  <!-- Deven: Start -->
  <div class="top-alert-block">
    <div class="ta-left">
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="fa-w-18 top-alert-icon" />
      Please verify that the pixel data in the table below is as expected. Note it can take up to 90
      minutes for pixel data to populate after pixel placement.
    </div>
    <div class="ta-right">
      <div class="mvc-btn btn-size3" style="min-width: 100px;" @click="verifyClicked">
        Verify
      </div>
    </div>
    <ConfirmationModal
      v-if="showConfirmBoxModal"
      :message="verifyMessage()"
      :show-confirm-btn="true"
      :cancel-btn-text="true"
      header-text="Verify Pixel"
      positive-button-label="VERIFY"
      negative-button-label="CANCEL"
      :is-alert="false"
      @close="onClickModalCancel"
      @onNegativeButtonClick="onClickModalCancel"
      @onPositiveButtonClick="onClickConfirmSubmit"
    />
  </div>
  <!-- Deven: End -->
</template>

<script>
import { get } from 'vuex-pathify';
import ConfirmationModal from '~/components/campaigns/confirmation-modal.vue';
import { PIXEL_INSTALLATION_GUIDE_URL } from '~/constant';

export default {
  name: 'PixelVerificationTopBar',
  components: {
    ConfirmationModal,
  },
  props: {
    updateAdvertiser: {
      type: Function,
      required: false,
      default: () => {},
    },
    checkPixelVerified: {
      type: Function,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      showConfirmBoxModal: false,
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
  },
  methods: {
    verifyMessage() {
      return `Please verify that data coming in looks as expected. If not, cancel and <br /> refer to the <a href="${PIXEL_INSTALLATION_GUIDE_URL}" target="_blank" > pixel installation guide <svg class="svg-inline--fa fa-external-link fa-w-16 sm-icon-inline" aria-hidden="true" focusable="false" data-prefix="far" data-icon="external-link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M497.6,0,334.4.17A14.4,14.4,0,0,0,320,14.57V47.88a14.4,14.4,0,0,0,14.69,14.4l73.63-2.72,2.06,2.06L131.52,340.49a12,12,0,0,0,0,17l23,23a12,12,0,0,0,17,0L450.38,101.62l2.06,2.06-2.72,73.63A14.4,14.4,0,0,0,464.12,192h33.31a14.4,14.4,0,0,0,14.4-14.4L512,14.4A14.4,14.4,0,0,0,497.6,0ZM432,288H416a16,16,0,0,0-16,16V458a6,6,0,0,1-6,6H54a6,6,0,0,1-6-6V118a6,6,0,0,1,6-6H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V304A16,16,0,0,0,432,288Z"></path></svg> </a> for help.`;
    },
    verifyClicked() {
      this.showConfirmBoxModal = true;
    },
    onClickModalCancel() {
      this.showConfirmBoxModal = false;
      // this.showSSHeader();
    },
    onClickConfirmSubmit() {
      // this.showSSHeader();
      this.updateAdvertiserPixelSetup(true);
      this.showConfirmBoxModal = false;
      this.checkPixelVerified();
    },
    async updateAdvertiserPixelSetup(val) {
      if (!this.advertiser.pixelSetup) {
        this.advertiser.pixelSetup = {};
      }
      this.advertiser.pixelSetup.pixelVerified = val;

      const { pixelSetup } = this.advertiser;

      await this.updateAdvertiser(pixelSetup, 'pixelSetup');
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-on .top-alert-block {
  display: block;
}
.top-alert-block {
  position: fixed;
  top: 59px;
  z-index: 9;
  display: block;
  width: 100%;
  padding: 10px 40px;
  overflow: auto;
  vertical-align: top;
  background-color: #ebf0f3;
}
.ta-left {
  display: inline-block;
  float: left;
  width: 70%;
  padding: 10px 0px;
  font-size: 14px;
}
.ta-right {
  display: inline-block;
  float: right;
  width: 29%;
  text-align: right;
}
.top-alert-icon {
  color: #52555d;
  margin-right: 6px;
}
.mvc-btn {
  font-family: 'Manrope', sans-serif;
  background-color: var(--selfserveprimarycolor) !important;
  color: var(--pixelovertext);
  &.btn-size3 {
    min-width: 100px;
    font-size: 12px;
    padding: 10px 18px;
  }
  &:hover {
    color: #fff;
    background-color: #555 !important;
  }
}
</style>
