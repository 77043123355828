import { ApiCrud } from 'adready-api/api/api-crud';
import http from 'adready-api/api/http';

const publisherDealApi = new ApiCrud(http.AXIOS, {
  deletePublisherDeal: 'DELETE publisherDeal/:id',
  createPublisherDeal: 'POST publisherDeal',
  updatePublisherDeal: 'PUT publisherDeal/:id',
});

export default publisherDealApi;
