<template>
  <div class="ss-left-nav-content">
    <div class="ss-nav-title">SETUP CHECKLIST</div>
    <div class="ss-lnav-links">
      <div
        v-for="(checkListOpt, index) in accountChecklist"
        :key="`checkListOpt_${index}`"
        :class="`ss-lnav-link ${checkListOpt.isDisabled ? 'disable-opt' : ''}`"
      >
        <div>
          <font-awesome-icon
            :icon="[`${checkListOpt.isSolidLeftIcon ? 'fas' : 'far'}`, `${checkListOpt.leftIcon}`]"
            class="lnav-icon"
          />
          <div
            class="ss-lnav-txt"
            @click="
              handleCheckOptClick(
                checkListOpt.route,
                checkListOpt.isDisabled,
                checkListOpt.elementId
              )
            "
          >
            <p>
              {{ checkListOpt.title }}
            </p>
          </div>
          <font-awesome-icon
            :icon="['far', `${checkListOpt.rightIcon}`]"
            :class="`lnav-icon2 ${checkListOpt.isActive ? 'active' : ''}`"
          />
        </div>
        <div v-if="checkListOpt.subOptions" class="ss-lnav-subOpt-cont">
          <p
            v-for="(subOptions, index) in checkListOpt.subOptions"
            :key="`subOptionsList_${index}`"
            :class="`ss-lnav-subTxt ${checkListOpt.isDisabled ? 'disable-sub-opt' : ''}`"
            @click="handleCheckOptClick(subOptions.route, checkListOpt.isDisabled)"
          >
            {{ subOptions && subOptions.title }}
            <font-awesome-icon
              :icon="[subOptions.isSolidLeftIcon ? 'fas' : 'far', `${subOptions.rightIcon}`]"
              :class="
                `lnav-icon2 ${subOptions.isActive ? 'active' : ''} ${
                  subOptions.rightIcon === 'exclamation' ? 'error-icon' : ''
                }`
              "
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import { get } from 'vuex-pathify';
import { ACC_SETUP_NAV_BAR_OPTIONS } from '~/constant';

export default {
  name: 'AccSetupLeftNavBar',
  components: {},

  props: {
    advertiserData: {
      type: Object,
      required: true,
      default: () => {},
    },
    handleChangeSection: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      accountChecklist: ACC_SETUP_NAV_BAR_OPTIONS,
      isPixelVerificationClicked: false,
    };
  },
  computed: {
    selectedElementId: get('common/selectedElementId'),
  },
  watch: {
    immediate: true,
    advertiserData: {
      handler(nv, ov) {
        if (!isEqual(nv, ov)) {
          this.checkForActiveSections(nv);
        }
      },
    },
  },
  mounted() {
    this.checkForActiveSections(this.advertiserData);
    if (this.selectedElementId) {
      this.handleCheckOptClick('ACCOUNT_SETUP', false, this.selectedElementId);
    }
  },
  methods: {
    checkForActiveSections(nv) {
      if (nv?.accountSetup?.accountInfoAvailable) {
        this.accountChecklist[0].isActive = true;
      } else {
        this.accountChecklist[0].isActive = false;
      }
      if (nv?.accountSetup?.paymentInfoAvailable) {
        this.accountChecklist[1].isActive = true;
      } else {
        this.accountChecklist[1].isActive = false;
      }
      if (nv?.accountSetup?.userInfoAvailable) {
        this.accountChecklist[2].isActive = true;
      } else {
        this.accountChecklist[2].isActive = false;
      }
      if (nv?.pixelSetup?.pixelAdded) {
        this.accountChecklist[3].isDisabled = false;
      } else {
        this.accountChecklist[3].isDisabled = true;
      }
      if (nv?.pixelSetup?.pixelAdded && nv?.pixelSetup?.pixelCode) {
        this.accountChecklist[3].subOptions[0].isDisabled = false;
        this.accountChecklist[3].subOptions[0].isActive = true;
      } else {
        this.accountChecklist[3].subOptions[0].isDisabled = true;
        this.accountChecklist[3].subOptions[0].isActive = false;
      }
      if (nv?.pixelSetup?.pixelAdded && nv?.pixelSetup?.pixelGuide) {
        this.accountChecklist[3].subOptions[1].isDisabled = false;
        this.accountChecklist[3].subOptions[1].isActive = true;
      } else {
        this.accountChecklist[3].subOptions[1].isDisabled = true;
        this.accountChecklist[3].subOptions[1].isActive = false;
      }
      if (nv?.pixelSetup?.pixelAdded && nv?.pixelSetup?.pixelVerified) {
        this.accountChecklist[3].isActive = true;
        this.accountChecklist[3].subOptions[2].isActive = true;
        this.accountChecklist[3].subOptions[2].rightIcon = 'check';
        this.accountChecklist[3].subOptions[2].isSolidLeftIcon = false;
      } else {
        if (this.isPixelVerificationClicked) {
          this.accountChecklist[3].subOptions[2].rightIcon = 'exclamation';
          this.accountChecklist[3].subOptions[2].isSolidLeftIcon = true;
        }
        this.accountChecklist[3].isActive = false;
        this.accountChecklist[3].subOptions[2].isActive = false;
      }
    },
    handleCheckOptClick(val, isDisabled, elementId) {
      if (isDisabled) {
        return;
      }
      if (val === 'PIXEL_SETUP_VERIFICATION') {
        this.isPixelVerificationClicked = true;
      }
      if (elementId) {
        const el = document.getElementById(elementId);
        if (el) {
          setTimeout(() => {
            el.scrollIntoView({ behavior: 'smooth' });
          }, 20);
        }
      }
      this.checkForActiveSections(this.advertiserData);
      this.handleChangeSection(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';

.ss-left-nav-content {
  font-family: 'Manrope', sans-serif;
  padding: 48px 45px;
  overflow: unset;
  height: 100%;
  position: fixed;
  top: 60px;
  width: 350px;
}
.disable-opt {
  opacity: 0.6;
  pointer-events: none;
}
.disable-sub-opt {
  pointer-events: none;
}
.ss-lnav-txt {
  font-size: 14px;
  color: #48525c;
  margin: 0px 15px 0px 10px;
  font-weight: 500;
  display: inline-block;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
  cursor: pointer;
  &.active {
    color: var(--selfserveprimarycolor) !important;
  }
  &:hover {
    cursor: pointer;
    color: var(--selfserveprimarycolor);
    .lnav-icon2 {
      color: var(--selfserveprimarycolor);
    }
  }
}
.ss-lnav-subTxt {
  font-size: 12px;
  color: #9c9c9c;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.ss-lnav-subOpt-cont {
  margin-left: 45px;
}
.ss-nav-title {
  text-transform: uppercase;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: #48525c;
  display: flex;
}
.ss-lnav-links {
  margin-top: 20px;
  position: relative;
  .ss-lnav-link {
    align-items: center;
    grid-gap: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
    &.active {
      color: var(--selfserveprimarycolor);
    }
    &:last-child {
      position: absolute;
      top: 100%;
    }
  }
}

.lnav-icon {
  font-size: 16px;
  color: var(--selfserveprimarycolor);
  width: 25px;
  position: relative;
  left: -6px;
}
.lnav-icon2 {
  font-size: 16px;
  color: #d6d8d9;
  &.active {
    color: var(--selfserveprimarycolor);
  }
  &.error-icon {
    color: var(--primaryred);
    margin-left: 2px;
  }
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
</style>
