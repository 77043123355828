<template>
  <div :class="[{ focused: isFocused }, { filled: isFilled }, 'form-input']">
    <label v-if="name !== ''" :for="name" :class="{ 'required-label': showRequired }">{{
      placeholderText
    }}</label>
    <textarea
      ref="input"
      :name="name"
      class="form-input-field"
      :required="isRequired"
      :maxlength="maxLength"
      :value="internalValue"
      :placeholder="isFocused && placeholder"
      :rows="rows"
      :cols="cols"
      :readonly="readonly"
      @change="onChange"
      @focusin="isFocused = true"
      @focusout="onFocusOut"
      @keyup="onKeyUp"
      @keydown="onKeyDown"
      @paste="onPaste"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import _ from 'underscore';
import S from 'underscore.string';

import validationsMixin from '../mixins/validations-mixin';
import autofocusMixin from '../mixins/autofocus-mixin';

export default {
  name: 'KTextArea',

  mixins: [validationsMixin, autofocusMixin],

  props: {
    name: {
      required: false,
      type: String,
      default: null,
    },
    label: {
      required: false,
      type: String,
      default: null,
    },
    placeholder: {
      required: false,
      type: String,
      default: null,
    },
    value: {
      required: false,
      type: [String, Number, Array],
      default: null,
    },
    maxLength: {
      required: false,
      type: Number,
      default: 524288,
    },
    rows: {
      required: false,
      type: Number,
      default: 4,
    },
    cols: {
      required: false,
      type: Number,
      default: 50,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    allowedCharacters: {
      required: false,
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isFocused: false,
      internalValue: null,
    };
  },

  computed: {
    isFilled() {
      return !_.isEmpty(this.internalValue);
    },

    placeholderText() {
      if (!_.isEmpty(this.label)) {
        return this.label;
      }
      if (!_.isEmpty(this.placeholder)) {
        return this.placeholder;
      }
      if (_.isEmpty(this.name)) {
        return '';
      }
      // FooBar -> Foo Bar
      return S(this.name)
        .humanize()
        .words()
        .map((s) => s[0].toUpperCase() + s.slice(1))
        .join(' ');
    },

    // whether or not the field should be marked as required
    isRequired() {
      return this.required;
    },
  },

  watch: {
    value(val) {
      this.setInternalValue(val);
    },

    isRequired() {
      this.$nextTick(() => {
        // Since we mark the field as required based on some extra logic, we
        // need to revalidate whenever this flag changes. Specifically, since we
        // load options dynamically, often after the form element is loaded,
        // this flag will trigger the html5 constraint validation to run.
        this.onValidation();
      });
    },
  },

  mounted() {
    this.setInternalValue(this.value);
  },

  methods: {
    setInternalValue(val) {
      this.internalValue = val !== null ? val.toString() : val;
      this.$nextTick(() => {
        // It appears that due to the extra indirection between setting the
        // internal value which then updates the input's value, we need to wait
        // a tick before running our validation against the input element.
        this.onValidation();
      });
    },

    onChange(ev) {
      this.setInternalValue(ev.target.value);
      this.$emit('change', ev.target.value);
      this.$emit('input', ev.target.value);
    },

    onKeyDown(ev) {
      if (this.allowedCharacters && !this.isSystemKey(ev)) {
        if (this.allowedCharacters.indexOf(ev.key) < 0) {
          ev.preventDefault();
        }
      }
      this.$emit('keydown', ev.target.value);
    },

    onKeyUp(ev) {
      if (this.allowedCharacters && !this.isSystemKey(ev)) {
        if (this.allowedCharacters.indexOf(ev.key) < 0) {
          ev.preventDefault();
        }
      }
      this.$emit('keyup', ev.target.value);
    },

    onPaste(ev) {
      if (this.allowedCharacters) {
        const paste = (ev.clipboardData || window.clipboardData).getData('text');
        for (let i = 0; i < paste.length; i++) {
          if (this.allowedCharacters.indexOf(paste[i]) < 0) {
            ev.preventDefault();
            return;
          }
        }
      }
      this.$emit('paste', ev.target.value);
    },

    isSystemKey(ev) {
      // 91: Meta, 17: Control, 18: Alt, 8: Backspace, 16: Shift
      if (
        ev.keyCode === 91 ||
        ev.keyCode === 17 ||
        ev.keyCode === 18 ||
        ev.keyCode === 8 ||
        ev.keyCode === 16
      ) {
        return true;
      }
      return false;
    },

    onBlur(ev) {
      this.$emit('blur', ev.target.value);
    },
    onFocusOut(ev) {
      this.isFocused = false;
      this.$emit('focusout', ev.target.value);
    },
  },
};
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
