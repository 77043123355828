import moment from 'moment';
import { LineItem } from 'adready-api/model/line-item';
import lineItemsApi from 'adready-api/api/line-items';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import _ from 'underscore';
import {
  LIS_DRAFT,
  LIS_CHANGE_NEW,
  LIS_CHANGE_PENDING_ACTIVATION,
  MT_VIDEO,
  PROD_OTT,
  TAR_BEHAVIOURAL_SEGMENT,
  TAR_AUDIENCE_FIRST_PARTY_TARGETING,
  TAR_DEMOGRAPHIC_TARGETING,
  TAR_CONTEXTUAL_TARGETING,
  DT_CTV,
  LIT_LINE,
} from 'adready-api/constant';
import deepcloneHelpers from 'adready-api/helpers/deepclone-helpers';
import store from '~/store';
import {
  DEFAULT_DEVICE_TYPES,
  DEFAULT_FREQUENCY_RECENCY,
  DEFAULT_DAY_PARTING,
  TAR_PUBLISHER_FLIP_OTT_TARGETING,
} from '~/constant';
import { dateDiff, toFixedNumber } from '~/util/utility-functions';
import { handleError } from '~/helpers/global/event-helpers';
import { isStatusReadonly } from '~/helpers/line-item-status-helpers';

/**
 * create or update the given line item
 * @param {*} planId
 * @param {*} lineItem
 */
export function saveLineItem(planId, lineItem) {
  // save the line item
  if (lineItem.id && lineItem.id > 0) {
    // have an id, update this lineitem
    return lineItemsApi.update(
      {
        planId,
        id: lineItem.id,
      },
      lineItem
    );
  }
  return lineItemsApi.create(planId, lineItem);
}

/**
 *
 * Add and update the lineItem and save it in vue store.
 * @param {*} planId
 * @param {*} lineItem
 *
 */
function addOrUpdateLineItem(planId, lineItem) {
  return saveLineItem(planId, lineItem).then((updated) => {
    if (isBlank(lineItem.id)) {
      store.dispatch('common/addLineItem', updated);
    } else {
      updated._uuid = lineItem._uuid;
      store.dispatch('common/updateLineItem', {
        _uuid: lineItem._uuid,
        touch: false,
        lineItem: new LineItem(updated),
      });
    }
    return updated;
  });
}
function doesLineItemHaveCR(lineItem) {
  if (isBlank(lineItem) || isBlank(lineItem.crLineItems)) {
    return false;
  }
  const crLineItem = lineItem.crLineItems.find((crl) =>
    [LIS_CHANGE_NEW, LIS_CHANGE_PENDING_ACTIVATION].includes(crl.statusId)
  );
  return crLineItem;
}
function isFieldChangedInCR(lineItem, field) {
  if (!doesLineItemHaveCR(lineItem)) {
    return false;
  }
  if (isBlank(lineItem.crLineItems[0][field])) {
    return false;
  }
  const original = JSON.stringify(lineItem[field]);
  const changed = JSON.stringify(lineItem.crLineItems[0][field]);
  if (typeof changed === 'string' && changed <= 0) {
    return false;
  }

  return original !== changed;
}
function getLineItemFieldValue(lineItem, field, ignoreChanges = false) {
  if (ignoreChanges) {
    return lineItem[field];
  }
  return isFieldChangedInCR(lineItem, field) ? lineItem.crLineItems[0][field] : lineItem[field];
}
function getLineItemFieldBothValues(lineItem, field, orgLineItems) {
  let value = '';
  if (field === 'budgetGroup' && lineItem.budgetGroup) {
    const temp = [];
    temp.push(lineItem?.budgetGroup?.budgetGroupId);
    value = {
      originalValue: 'Flexible',
      changedValue: 'Flexible',
      originalBudgetValue: lineItem?.budgetGroup?.budget,
      changedBudgetValue: lineItem?.budgetGroup?.budget,
      originalBudgetGroupId: temp,
    };
  } else {
    value = { originalValue: lineItem[field] };
  }
  if (!isBlank(orgLineItems) && !isBlank(lineItem.id)) {
    let parentLineItem = orgLineItems.find((l) => l.id === lineItem.id);
    if (!isBlank(lineItem.parentId) && !isBlank(lineItem.crLineItems)) {
      const crLineItem = lineItem.crLineItems.find((l1) =>
        [LIS_CHANGE_NEW, LIS_CHANGE_PENDING_ACTIVATION].includes(l1.statusId)
      );
      if (crLineItem) {
        parentLineItem = deepcloneHelpers.mergeLineItem(parentLineItem, crLineItem);
      }
    }
    if (parentLineItem) {
      value.changedValue =
        field === 'budgetGroup' && lineItem.budgetGroup ? 'Flexible' : lineItem[field];
      value.originalValue =
        field === 'budgetGroup' && lineItem.budgetGroup ? 'Flexible' : parentLineItem[field];
    }
  }
  return value;
}

function getSelectedMediaPublishers(publisherNames, mediaPublisherCPMData) {
  if (isBlank(publisherNames)) {
    return [];
  }
  if (isBlank(mediaPublisherCPMData)) {
    return [];
  }

  const selectedMediaPublishers = publisherNames.reduce(function(result, o) {
    const publisher = mediaPublisherCPMData.find((p) => p.publisherName === o);
    if (publisher) {
      result.push(publisher);
    }
    return result;
  }, []);

  return selectedMediaPublishers;
}
function isTargetingValueProvided(targetings, type) {
  const targeting = targetings.find((t) => t.targetableElementId === type);
  if (!targeting) {
    return false;
  }

  let flag;

  switch (type) {
    case TAR_BEHAVIOURAL_SEGMENT:
      return targeting?.value?.dropDownValue?.length > 0;
    case TAR_CONTEXTUAL_TARGETING:
      return targeting?.value?.length > 0;

    case TAR_DEMOGRAPHIC_TARGETING:
      flag = false;
      if (targeting.value) {
        const keys = ['gender', 'age', 'income'];
        const filteredKeys = Object.keys(targeting?.value).filter((k) => keys.includes(k));
        filteredKeys.forEach((key) => {
          if (targeting?.value[key]?.length > 0) {
            flag = true;
          }
        });
        return flag;
      }
      return flag;
    case TAR_AUDIENCE_FIRST_PARTY_TARGETING:
      return targeting?.value?.uploaded;

    default:
      return false;
  }
}
function fetchPublisherTargeting(targetings) {
  if (isBlank(targetings)) {
    return [];
  }
  // targetableElementId for Flip OTT targeting = 40
  const targeting = targetings.find(
    (t) => t.targetableElementId === TAR_PUBLISHER_FLIP_OTT_TARGETING
  );
  if (!targeting) {
    return [];
  }

  return targeting.value && targeting.value.precreated && _.isArray(targeting.value.precreated)
    ? targeting.value.precreated
    : [];
}
function calculateCPMAddOns(deviceTypes, targetings) {
  let val = 0.0;
  if (deviceTypes?.length === 1 && deviceTypes?.[0].deviceTypeId === DT_CTV) {
    val += 1.0;
  }
  if (
    isTargetingValueProvided(targetings, TAR_DEMOGRAPHIC_TARGETING) ||
    isTargetingValueProvided(targetings, TAR_BEHAVIOURAL_SEGMENT) ||
    isTargetingValueProvided(targetings, TAR_AUDIENCE_FIRST_PARTY_TARGETING)
  ) {
    val += 1.25;
  }
  if (isTargetingValueProvided(targetings, TAR_CONTEXTUAL_TARGETING)) {
    val += 1.25;
  }
  return toFixedNumber(val, 2);
}
function calculateCPM(publisherNames, mediaPublisherCPMData) {
  const selectedMediaPublishers = getSelectedMediaPublishers(publisherNames, mediaPublisherCPMData);
  if (isBlank(selectedMediaPublishers)) {
    return 0;
  }

  const cpmValues = selectedMediaPublishers.map((publisher) => publisher.avgCPM || 0);
  const cpmSum = cpmValues.reduce((s, cpm) => {
    return s + cpm;
  }, 0);
  if (!isBlank(cpmValues)) {
    const val = (cpmSum / cpmValues.length) * 1.25;
    return toFixedNumber(val, 2);
  }
  return 0;
}
function calculateImpressions(cpm, budget) {
  const val = cpm > 0 ? (budget * 1000) / cpm : 0;
  return toFixedNumber(val, 0);
}
function calculateLineItemCPM(lineItem, mediaPublisherCPMData) {
  if (lineItem) {
    const { deviceTypes, targetings } = lineItem;
    const publisherNames = fetchPublisherTargeting(targetings);
    let cpm = calculateCPM(publisherNames, mediaPublisherCPMData);
    cpm += calculateCPMAddOns(deviceTypes, targetings);
    return cpm;
  }
  return 0;
}
function calculateLineItemCPMAndImpressions(lineItem, mediaPublisherCPMData) {
  // Get CPM.
  const cpm = isBlank(lineItem) ? 0 : calculateLineItemCPM(lineItem, mediaPublisherCPMData);
  let budget =
    !isBlank(lineItem.lineItemBudget) && lineItem.lineItemBudget > 0
      ? lineItem.lineItemBudget
      : lineItem.budgetGroup?.budget;
  let bT = 'overall';
  // If budget type is 'overall' then the budget is correct value.
  // If budget type is 'daily', then multiply by number of days.
  const btOK = lineItem.budgetType && lineItem.budgetType.value;
  if (btOK) {
    bT = lineItem.budgetType.value;
    const btDaily = bT === 'daily';
    const datesOK = lineItem.flightStartDate && lineItem.flightEndDate;
    if (btDaily && datesOK) {
      let days = dateDiff(lineItem.flightStartDate, lineItem.flightEndDate);
      if (days < 0) days *= -1;
      budget *= days;
    }
  }
  const impressions = calculateImpressions(cpm, budget);
  return { cpm, impressions };
}
function fetchDemographicTargetingValue(targetings, key) {
  if (isBlank(targetings)) {
    return '';
  }

  // targetableElementId for Demographic targeting = 28
  const targeting = targetings.find((t) => t.targetableElementId === TAR_DEMOGRAPHIC_TARGETING);
  if (!targeting) {
    return '';
  }

  return targeting.value && targeting.value[key] && _.isArray(targeting.value[key])
    ? targeting.value[key].join(', ')
    : '';
}
function fetchBehavioralTargetingValue(targetings) {
  if (isBlank(targetings)) {
    return '';
  }

  // targetableElementId for Behavioral targeting = 4
  const targeting = targetings.find((t) => t.targetableElementId === TAR_BEHAVIOURAL_SEGMENT);
  if (!targeting) {
    return '';
  }

  return targeting.value &&
    targeting.value.dropDownValue &&
    _.isArray(targeting.value.dropDownValue)
    ? targeting.value.dropDownValue.join(', ')
    : '';
}
function fetchFirstPartyTargetingValue(targetings) {
  if (isBlank(targetings)) {
    return '';
  }

  // targetableElementId for First Party targeting = 6
  const targeting = targetings.find(
    (t) => t.targetableElementId === TAR_AUDIENCE_FIRST_PARTY_TARGETING
  );
  if (!targeting) {
    return '';
  }

  return targeting.value && targeting.value.uploaded ? targeting.value.uploaded.name : '';
}
function fetchPublisherTargetingValue(targetings) {
  if (isBlank(targetings)) {
    return '';
  }

  const targeting = fetchPublisherTargeting(targetings);
  return _.isArray(targeting) ? targeting.join(', ') : '';
}

function generateLineItemName(lineItem, parentLineItem, replace) {
  const account = store.get('common/account');
  const advertiser = store.get('common/advertiser');
  const startDate = lineItem.flightStartDate;
  const endDate = lineItem.flightEndDate;
  let dateString;
  if (replace && (isBlank(startDate) || isBlank(endDate))) {
    dateString = '<Select Dates>';
  } else {
    dateString = commonHelper.startEndDateString(startDate, endDate);
  }

  const productCategories = store.get('common/productCategories');
  const categoryId = lineItem.productCategoryId;
  const productCategory = productCategories.find((pc) => pc.id === categoryId);
  let productCategoryName = '';
  if (productCategory) {
    productCategoryName = productCategory.name;
  } else {
    productCategoryName = replace ? '<Select Media Type>' : '';
  }

  const { productTypeId } = lineItem;
  const productType = productCategory
    ? productCategory.productTypes.find((pt) => pt.id === productTypeId)
    : null;
  let productTypeName = '';
  if (productTypeId) {
    if (productType) {
      productTypeName = productType.name;
    }
  }
  if (isBlank(productTypeName)) {
    productTypeName = replace ? '<Select Product Type>' : '';
  }

  let adreadyId = parentLineItem ? parentLineItem.adreadyId : lineItem.adreadyId;
  if (isBlank(adreadyId)) {
    adreadyId = replace ? '<AdReady ID>' : '';
  }

  let { name } = lineItem;
  if (isBlank(name)) {
    name = replace ? '<Enter Line Item Name>' : '';
  }
  let advertiserName = '<Select Advertiser>';
  if (!isBlank(advertiser)) {
    advertiserName = advertiser.name;
  }

  const parts = [];
  const { siteRetargeting } = lineItem;
  if (siteRetargeting) {
    parts.push(account.abbreviation, advertiserName, name, 'Site Retargeting', productCategoryName);
  } else {
    parts.push(account.abbreviation, advertiserName, name, productCategoryName);
  }

  parts.push(dateString, adreadyId);

  return parts.join(' - ');
}

/**
 *
 * This function will create adreadyId for lineItem;
 * @param {*} id
 * @returns Adready Id
 */
export const createAdreadyId = (id) => {
  const account = store.get('common/account');
  return `${account.prefix}${commonHelper.zeroPad(id, 7)}`;
};

const onClickLineConfirmChanges = (line) => {
  const creatives = line.creatives.filter((c) => c !== null && !c.isEmpty());
  const kpis = line.kpis.filter((k) => !commonHelper.isEmptyOrNull(k.kpiId));

  const newLi = { ...line, creatives, kpis, updatedLineItem: false };

  if (newLi.id && _.isEmpty(newLi.adreadyId)) {
    // upgrade path to set adreadyId on next save
    newLi.adreadyId = createAdreadyId(newLi.id);
  }
  newLi.externalName = generateLineItemName(newLi); // generate name, potentially without a proper ID

  store.dispatch('common/updateLineItem', { _uuid: line._uuid, lineItem: newLi });

  return saveLineItem(line.planId, newLi)
    .then((updated) => {
      // merge updated record into store
      if (newLi.id || !_.isEmpty(newLi.adreadyId)) {
        // already had an id, just update the store
        const sortedCreatives = _.sortBy(updated.creatives, 'id');
        updated.creatives = sortedCreatives;
        updated.updatedLineItem = false;
        updated._uuid = line._uuid;
        store.dispatch('common/updateLineItem', {
          _uuid: line._uuid,
          lineItem: updated,
        });
        return updated;
      }
      // first save of line, generate adreadyId/external_name
      const lineItemNameUpdate = { ...updated };
      let { adreadyId } = updated;
      if (_.isEmpty(updated.adreadyId)) {
        // no existing id, generate it
        adreadyId = createAdreadyId(updated.id);
        lineItemNameUpdate.adreadyId = adreadyId;
      }
      // lineItemNameUpdate.externalName = this.generateLineItemName(lineItemNameUpdate);

      // save it
      return saveLineItem(line.planId, lineItemNameUpdate)
        .then((updatedNew) => {
          updatedNew.updatedLineItem = false;
          const sortedCreatives = _.sortBy(updated.creatives, 'id');
          updatedNew.creatives = sortedCreatives;
          updatedNew._uuid = line._uuid;
          store.dispatch('common/updateLineItem', {
            _uuid: line._uuid,
            lineItem: updatedNew,
          });
          return updatedNew;
        })
        .catch((error) => {
          handleError(error, 'Error in save LineItem');
          store.dispatch('common/updateLineItem', { ...newLi, updatedLineItem: true });
        });
    })
    .catch((error) => {
      handleError(error, 'Error in save LineItem');
      store.dispatch('common/updateLineItem', { ...newLi, updatedLineItem: true });
    });
};

/**
 * this is to create default basic ad group.
 * @param {*} name : ad group name
 * @param {*} campaign : campaign/IO object
 * @returns new AdGroup
 */
function createDefaultAdGroup(name, campaign) {
  const adGroup = new LineItem({
    name,
    flightStartDate: null,
    flightEndDate: null,
    planId: campaign.id,
    updatedLineItem: true,
    statusId: LIS_DRAFT,
    budgetType: { id: 1, value: 'overall', desc: 'Overall budget.' },
    type: LIT_LINE,
    flip: true,
    incrementality: true,
    productCategoryId: MT_VIDEO,
    productTypes: [{ productTypeId: PROD_OTT, categoryPath: 'Video / OTT' }],
    contactUserId: campaign.contactUserId,
    customUserId: campaign.customUserId,
    billingType: 'FIRST_PARTY',
    price: 0.2,
    pricingType: 'FIXED_MARGIN',
    deviceTypes: DEFAULT_DEVICE_TYPES,
    siteRetargeting: true,
    frequencyRecency: { enabled: true, ...DEFAULT_FREQUENCY_RECENCY },
    dayParting: { enabled: true, ...DEFAULT_DAY_PARTING },
  });

  return adGroup;
}

function createCloneLineItem(lineItem) {
  const clone = deepcloneHelpers.cloneLineItem(lineItem, false, false, LIS_DRAFT, undefined, false);
  clone.name = `Copy of ${lineItem.name}`;
  return clone;
}
export const validateAdGroupName = (name) => {
  if (isBlank(name)) {
    return 'Ad group name missing';
  }
  return '';
};
export const validateAdGroupBudget = (lineItemBudget) => {
  if (lineItemBudget === 'Flexible' || lineItemBudget === 0) {
    return '';
  }
  if (isBlank(lineItemBudget)) {
    return 'Ad group budget missing';
  }
  if (lineItemBudget < 5000) {
    return 'Minimum budget required is $5,000';
  }
  if (lineItemBudget > 100000) {
    return 'Maximum budget required is $100,000';
  }
  return '';
};
export const validateFlightDates = (flightStartDate, flightEndDate, type) => {
  if (isBlank(flightStartDate)) {
    if (type === 'start') {
      return 'Ad group start date missing';
    }
    return 'Ad group end date missing';
  }
  if (
    !isBlank(flightEndDate) &&
    moment(flightStartDate, moment.ISO_8601).isAfter(moment(flightEndDate, moment.ISO_8601))
  ) {
    return 'Ad group start date must not be greater than Ad group end date';
  }
  return '';
};
export const validatePublisherTargeting = (selectedPublishers) => {
  if (selectedPublishers.length < 6 || selectedPublishers.length > 12) {
    return 'Please select between 6 and 12 media publishers for optimized performance.';
  }
  return '';
};
function resetIdsOfLineItem(lineItem) {
  // recurse to nested objects
  if (!isBlank(lineItem.creatives)) {
    lineItem.creatives.forEach((creative) => {
      creative.newUUID(true);
    });
  }
  if (lineItem.note && lineItem.note.newUUID) {
    lineItem.note.newUUID(true);
  }
  if (!isBlank(lineItem.kpis)) {
    lineItem.kpis.forEach((kpi) => {
      kpi.newUUID(true);
    });
  }
  if (!isBlank(lineItem.deviceTypes)) {
    lineItem.deviceTypes.forEach((dt) => {
      // simple type
      dt.id = null;
    });
  }
  if (!isBlank(lineItem.trackingPixels)) {
    lineItem.trackingPixels.forEach((tp) => {
      // non-standard obj layout
      tp.idKey = null;
    });
  }
  if (!isBlank(lineItem.targetings)) {
    lineItem.targetings.forEach((targeting) => {
      targeting.id = null;
    });
  }
  return lineItem;
}

function isAdGroupReadonly(lineItem) {
  return isStatusReadonly(lineItem.statusId);
}

function validateAdGroupInfo(lineItem) {
  const validationObj = { isValidated: true };

  const name = getLineItemFieldValue(lineItem, 'name');
  const adGroupNameError = validateAdGroupName(name);
  if (adGroupNameError) {
    validationObj.name = adGroupNameError;
  }

  const lineItemBudget = getLineItemFieldValue(lineItem, 'lineItemBudget');
  const lineItemBudgetError = validateAdGroupBudget(lineItemBudget);
  if (lineItemBudgetError) {
    validationObj.lineItemBudget = lineItemBudgetError;
  }
  const flightStartDate = getLineItemFieldValue(lineItem, 'flightStartDate');
  const flightStartDateError = validateFlightDates(flightStartDate, '', 'start');
  if (flightStartDateError) {
    validationObj.flightStartDate = flightStartDateError;
  }

  const flightEndDate = getLineItemFieldValue(lineItem, 'flightEndDate');
  const flightEndDateError = validateFlightDates(flightEndDate, '');
  if (flightEndDateError) {
    validationObj.flightEndDate = flightEndDateError;
  }
  if (!isBlank(flightStartDate) && !isBlank(flightEndDate)) {
    if (moment(flightStartDate).isAfter(moment(flightEndDate))) {
      validationObj.flightStartDate =
        'Ad group start date should not be greater than ad group end date';
    }
  }

  const targetings = getLineItemFieldValue(lineItem, 'targetings');
  const selectedPublishers = fetchPublisherTargeting(targetings) || [];
  const publisherTargetingError = validatePublisherTargeting(selectedPublishers);
  if (publisherTargetingError) {
    validationObj.publishers = publisherTargetingError;
  }
  if (Object.keys(validationObj).length > 1) {
    validationObj.isValidated = false;
  }
  return validationObj;
}

function validateAdGroupAds(lineItem) {
  const validationObj = { isValidated: true };

  const creatives = getLineItemFieldValue(lineItem, 'creatives');
  if (creatives.length <= 0) {
    validationObj.creatives = 'Please upload at least one creative file';
  }

  if (Object.keys(validationObj).length > 1) {
    validationObj.isValidated = false;
  }
  return validationObj;
}

function isFirstAndThirdPartySelected(lineItem) {
  const targetings = getLineItemFieldValue(lineItem, 'targetings');
  const firstPartyTargetingValue = fetchFirstPartyTargetingValue(targetings);
  const behavioralTargetingValue = fetchBehavioralTargetingValue(targetings);
  const genderValue = fetchDemographicTargetingValue(targetings, 'gender');
  const ageValue = fetchDemographicTargetingValue(targetings, 'age');
  const incomeValue = fetchDemographicTargetingValue(targetings, 'income');
  const isDemoTargetingSelected = !isBlank(`${genderValue}${ageValue}${incomeValue}`);
  const isBehavioralTargetingSelected = !isBlank(behavioralTargetingValue);
  const isFirstPartyTargetingSelected = !isBlank(firstPartyTargetingValue);
  return (
    (isDemoTargetingSelected || isBehavioralTargetingSelected) && isFirstPartyTargetingSelected
  );
}
export const validateAudienceName = (audienceName) => {
  if (isBlank(audienceName)) {
    return 'Audience name missing';
  }
  return '';
};
function validateAdGroupTargetings(lineItem) {
  const validationObj = { isValidated: true };
  const audienceName = getLineItemFieldValue(lineItem, 'audienceName');
  const audienceNameError = validateAudienceName(audienceName);

  if (audienceNameError) {
    validationObj.audienceName = audienceNameError;
  }

  if (isFirstAndThirdPartySelected(lineItem)) {
    validationObj.targetings_first_party =
      'Either first party targeting or third party targeting should be selected';
  }

  if (Object.keys(validationObj).length > 1) {
    validationObj.isValidated = false;
  }
  return validationObj;
}

function validateEntireAdGroup(lineItem) {
  let validationObj = {};
  validationObj = {
    ...validateAdGroupInfo(lineItem),
    ...validateAdGroupTargetings(lineItem),
    ...validateAdGroupAds(lineItem),
  };
  if (validationObj.isValidated) {
    delete validationObj.isValidated;
  }

  validationObj.isValidated = !Object.keys(validationObj).length;
  return validationObj;
}

export default {
  addOrUpdateLineItem,
  saveLineItem,
  getSelectedMediaPublishers,
  calculateCPM,
  calculateImpressions,
  calculateLineItemCPM,
  calculateLineItemCPMAndImpressions,
  doesLineItemHaveCR,
  isFieldChangedInCR,
  getLineItemFieldValue,
  getLineItemFieldBothValues,
  fetchDemographicTargetingValue,
  fetchBehavioralTargetingValue,
  fetchFirstPartyTargetingValue,
  fetchPublisherTargeting,
  fetchPublisherTargetingValue,
  onClickLineConfirmChanges,
  createDefaultAdGroup,
  createCloneLineItem,
  isTargetingValueProvided,
  calculateCPMAddOns,
  resetIdsOfLineItem,
  isAdGroupReadonly,
  validateAdGroupInfo,
  validateAdGroupAds,
  isFirstAndThirdPartySelected,
  validateAdGroupTargetings,
  validateEntireAdGroup,
  validateAdGroupName,
  validateAdGroupBudget,
  validateFlightDates,
  validatePublisherTargeting,
  validateAudienceName,
};
