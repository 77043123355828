<template>
  <div id="ADMIN_SETTINGS" class="d-board">
    <div class="container admin-account-container">
      <!-- Tabs -->
      <div class="tabs-container">
        <div class="mid-area">
          <k-nav-tabs
            class="nav-tabs"
            :menu="filteredTabs"
            :active="currentTab"
            @selected-menu="updateActiveMenu"
          />
        </div>
      </div>
      <Accounts v-if="currentTab.toLowerCase() === 'accounts'"></Accounts>
      <Users v-if="currentTab.toLowerCase() === 'users'"></Users>
      <PubManagementSystem v-if="currentTab.toLowerCase() === 'publishers'" />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import KNavTabs from '@/components/elements/k-nav-tabs.vue';
import forklift from '~/components/mixins/forklift-mixin';
import Accounts from './Accounts.vue';
import Users from './Users.vue';
import { scrollToElement } from '~/util/utility-functions';
import PubManagementSystem from './PubManagementSystem.vue';
import authzMixin from '~/components/mixins/authz-mixin';

export default {
  name: 'Admin',
  components: {
    Accounts,
    Users,
    KNavTabs,
    PubManagementSystem,
  },
  mixins: [forklift, authzMixin],
  data() {
    return {
      currentTab: 'accounts',
      tabItems: [
        {
          name: 'Accounts',
          to: '',
        },
        {
          name: 'Users',
          to: '',
        },
        {
          name: 'Publishers',
          to: '',
        },
      ],
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    logoFile() {
      return null;
    },
    filteredTabs() {
      if (this.isInternalAdmin) {
        return this.tabItems;
      }
      if (this.isExternalAdmin) {
        // If external admin, return only the first two tabs
        return this.tabItems.slice(0, 2);
      }
      // You can add additional conditions or a default case if needed
      return [];
    },
  },
  mounted() {
    scrollToElement('ADMIN_SETTINGS');
    if (!this.advertiser) {
      return;
    }
    this.loadAllUsers(true);
  },
  methods: {
    updateActiveMenu(payload) {
      this.currentTab = payload;
    },
    onClickTab(type) {
      this.currentTab = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-container {
  width: 100%;
  min-width: 900px;
  max-width: 900px;
  padding: 65px 40px 20px 0px;
  margin: 0 auto;
  .mid-area {
    position: relative;
  }
}
.tabletabs {
  display: flex;
  .tabletab {
    background-color: #000;
    padding: 17px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px 4px 0px 0px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .selectedtab {
    background-color: #ffffff;
    color: #000;
  }
  .io-tab-actions,
  .line-item-tab-actions {
    max-width: 710px;
    margin-left: 0;
    div {
      max-width: 350px;
    }
  }
}

::v-deep .io-tab-actions {
  .selectedtab .k-btn {
    color: #ffffff !important;
    background-color: $grey_3 !important;
  }
  .k-btn.btn-size-4 {
    border-radius: 3px;
  }
}

.tabletabs {
  display: inline-block;
  margin-right: 12px;
}
.container > * {
  display: inherit;
  width: 1100px;
  margin: 0 auto;
  font-family: 'Source Sans Pro', sans-serif !important;
}
</style>
<style lang="scss">
label {
  cursor: default;
}
.text-white {
  color: white;
  opacity: 0.75;
}
.modal-wrapper {
  p {
    font-size: 13px;
    line-height: 25px;
    border-bottom: 1px solid rgba(170, 170, 170, 0.1);
    text-align: left;
  }
}
.accounts-container {
  .list-box {
    top: 13px;
    min-height: 250px;
    .user-select {
      .dropdown {
        min-width: 730px;
        margin-right: 0 !important;
      }
    }
  }
}
.modal-wrapper-box {
  .form-group {
    .dropdown {
      min-width: 480px !important;
      margin-right: 0 !important;
      line-height: 20px !important;
    }
  }
}
body .vs__dropdown-menu {
  color: #cad1d6 !important;
  background-color: #323239 !important;
}
.vs__dropdown-option {
  color: #cad1d6 !important;
  font-size: 14px !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}
.vs__dropdown-option--highlight {
  background-color: rgba(1, 171, 183, 0.4) !important;
  color: #cad1d6 !important;
}
.vs__dropdown-option--selected {
  background-color: rgba(255, 255, 255, 0.04) !important;
}
.accounts-container .dropdown {
  line-height: 20px !important;
}
.vs__selected {
  color: #01abb7 !important;
  background-color: rgba(78, 78, 78, 0.4) !important;
}
.vs__deselect {
  fill: rgb(147 147 147) !important;
}
.checkbox-btn {
  margin-left: 122px;
  height: 35px;
  span {
    font-size: 14px;
    color: #0c0c0c;
    letter-spacing: 0.07em;
  }
  svg {
    width: 15px;
    height: 15px;
    &.checkbox-inactive {
      color: #cad1d6;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      background-color: #e9edf0;
      path {
        fill: #999;
      }
    }
  }
}
::v-deep .accounts-container .form-input .form-input-field.v-select .vs__search {
  font-size: 14px !important;
}
::v-deep .accounts-container .form-input .form-input-field.v-select .vs__selected-options {
  padding: 0 6px !important;
}
::v-deep
  .accounts-container
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__selected {
  padding: 0 6px !important;
  margin: 0 4px !important;
}
::v-deep .accounts-container .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #323239 !important;
}
.add-btn {
  cursor: pointer;
}
.modal-header {
  svg {
    cursor: pointer;
  }
}
.modal-container .error {
  padding: 10px;
  font-size: 13px;
  color: rgba(252, 66, 66);
  letter-spacing: 0.9px;
}
.modal-container .success {
  padding: 10px;
  font-size: 13px;
  color: rgba(107, 207, 24);
  letter-spacing: 0.9px;
}
.txt-align-center {
  display: inline-grid;
  align-items: center;
  width: 100%;
  min-height: 65px;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.modal-container {
  .checkbox-btn {
    span {
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
    }
  }
  .errorBox {
    border: 1px solid #fc4242 !important;
  }
  .required {
    color: #fc4242;
  }
  .form-input-wrap.errorBox {
    height: 43px !important;
    border: 1px solid #fc4242 !important;
  }
}
.modal-mask {
  max-width: none;
}
.sm2 {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  width: 100%;
  text-align: left;
  color: #fff;
}
.sm1 {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: none;
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: 30px;
}
.mg-tp-20 {
  margin-top: 20px;
}
</style>
