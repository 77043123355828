<template>
  <div :class="[{ focused: isFocused }, { filled: isFilled }, 'form-input']">
    <label v-if="labelText" :for="name" :class="{ 'required-label': showRequired }">{{
      labelText
    }}</label>
    <input
      ref="input"
      v-bind="$props"
      :name="name"
      :type="type"
      class="form-input-field"
      :min="min"
      :required="required"
      :maxlength="maxLength"
      :placeholder="isFocused && placeholder"
      :readonly="readonly"
      :tabindex="tabindex"
      @change="onChange"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @keyup="onKeyUp"
      @blur="onBlur"
    />
    <div class="icon-right">
      <font-awesome-icon v-if="haveIcon" :icon="icon" />
    </div>
  </div>
</template>

<script>
import _ from 'underscore';

import { isBlank } from 'adready-api/helpers/common';
import validationsMixin from '@/adready-vue/components/mixins/validations-mixin';
import elementsMixin from '@/adready-vue/components/mixins/elements-mixin';
import autofocusMixin from '@/adready-vue/components/mixins/autofocus-mixin';

export default {
  name: 'KTextInput',

  mixins: [validationsMixin, elementsMixin, autofocusMixin],

  props: {
    name: {
      required: false,
      type: String,
      default: null,
    },
    value: {
      required: false,
      type: [String, Number, Array],
      default: undefined,
    },
    maxLength: {
      required: false,
      type: Number,
      default: 524288,
    },
    min: {
      required: false,
      type: Number,
      default: 0,
    },
    type: {
      required: false,
      type: String,
      default: 'text',
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    icon: {
      required: false,
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      isFocused: false,
      internalValue: null,
    };
  },

  computed: {
    isFilled() {
      return !_.isEmpty(this.internalValue);
    },

    haveIcon() {
      // icon must be a non-empty array
      return !isBlank(this.icon);
    },
  },

  watch: {
    value(val) {
      this.setInternalValue(val);
      this.setInputValue(val);
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    /**
     * Init provides a convenient way to override the initialization behavior of
     * this componente in a subclass. Since the mounted() cannot be directly
     * overridden:
     *
     *   > Hook functions with the same name are merged into an array so that
     *   > all of them will be called. Mixin hooks will be called before the
     *   > component’s own hooks.
     *
     * Subclasses can instead override this init method.
     */
    init() {
      this.setInternalValue(this.value);
      this.setInputValue(this.value);
    },

    /*
     * Set the given val directly on the input $el node
     *
     * Rather than set the :value prop directly on the element, we can
     * programatically set it when the element is created. This allows the value
     * to change properly based on user input without conflict from the :value
     * prop.
     */
    setInputValue(val) {
      if (this.$refs.input) {
        this.$refs.input.value = isBlank(val) ? '' : val.toString();
      }
    },

    setInternalValue(val) {
      this.internalValue = val !== null && val !== undefined ? val.toString() : val;
      this.$nextTick(() => {
        // It appears that due to the extra indirection between setting the
        // internal value which then updates the input's value, we need to wait
        // a tick before running our validation against the input element.
        this.onValidation();
      });
    },

    // events

    onChange(ev) {
      this.setInternalValue(ev.target.value);
      this.$emit('change', ev.target.value);
      this.$emit('input', ev.target.value);
    },

    onKeyUp(ev) {
      this.$emit('keyup', ev.target.value, ev);
    },

    onBlur(ev) {
      this.$emit('blur', ev.target.value);
    },

    onFocusIn(ev) {
      this.isFocused = true;
      this.$emit('focusin', ev.target.value);
    },

    onFocusOut(ev) {
      this.isFocused = false;
      this.$emit('focusout', ev.target.value);
    },
  },
};
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.icon-right {
  float: right;
  margin-top: -30px;
  margin-right: 6px;
  position: relative !important;
}
</style>
