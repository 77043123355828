<template>
  <div :class="`${isReadonly ? 'readonly-payment-method' : ''}`">
    <p class="acc-info-title">Select Payment Method</p>
    <div class="row-flex">
      <div
        v-for="payOpt in paymentOptions"
        :key="payOpt.paymentType"
        class="goals-list"
        :class="[
          payOpt.paymentType == activePaymentOpt.paymentType ? 'activeGoal' : '',
          payOpt.isDisabled ? 'disable_goal' : '',
        ]"
        @click="onClickPaymentOpt(payOpt)"
      >
        <span class="pop-view">
          <font-awesome-icon class="pcheck2" :icon="['fas', 'check']" size="1x" />
          <font-awesome-icon class="pop-icon pbig" :icon="payOpt.goal_icon" size="1x" />
        </span>
        <div class="pop-view-txt">
          {{ payOpt.name }}
        </div>
      </div>
    </div>
    <div v-if="activePaymentOpt.paymentType === 'BILLABLE_INVOICING'">
      <div class="s2-info-wrap">
        <div class="mvc-half mvc-half-left ">
          <div class="input-box-format">
            <div class="ss-form-label2">Billing Address</div>
            <k-text-input
              :required="true"
              :max-length="255"
              :value="activePaymentOpt.billingAddress"
              @change="(val) => handleBillingValueChange(val, 'billingAddress')"
            />
          </div>
        </div>
        <div class="mvc-half mvc-half-right ">
          <div class="input-box-format">
            <div class="ss-form-label2">Billing Contact Name</div>
            <k-text-input
              :required="true"
              :max-length="255"
              :value="activePaymentOpt.billingContactPerson"
              @change="(val) => handleBillingValueChange(val, 'billingContactPerson')"
            />
          </div>
        </div>
      </div>
      <div class="s2-info-wrap">
        <div class="mvc-half mvc-half-left ">
          <div class="input-box-format">
            <div class="ss-form-label2">Billing Contact Email</div>
            <k-text-input
              :required="true"
              :max-length="255"
              :value="activePaymentOpt.billingContactEmail"
              @change="(val) => handleBillingValueChange(val, 'billingContactEmail')"
            />
          </div>
        </div>
        <div class="mvc-half mvc-half-right ">
          <div class="input-box-format">
            <div class="ss-form-label2">Billing Contact Phone</div>
            <k-text-input
              :required="true"
              :max-length="20"
              :value="activePaymentOpt.billingContactPhone"
              @change="(val) => handleBillingValueChange(val, 'billingContactPhone')"
            />
          </div>
        </div>
      </div>
      <div class="agree-box-cont">
        <k-checkbox
          name="selectTerm"
          :checked="activePaymentOpt.termsAgreed"
          @change="handleAgreedChange"
          @selected="handleAgreedChange"
        />
        <p>Agree to our <span @click="handleShowTnCModal">Terms</span></p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { isEqual } from 'lodash';

export default {
  name: 'AccSetupPaymentMethod',

  props: {
    advertiserData: {
      type: Object,
      required: true,
      default: {},
    },
    updateAdvertiser: {
      type: Function,
      required: true,
      default: () => {},
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      genericMsg: '',
      paymentOptions: [
        {
          paymentType: 'BILLABLE_INVOICING',
          name: 'Billable Invoicing',
          goal_icon: ['far', 'file-invoice'],
          isDisabled: false,
        },
        {
          paymentType: 'CREDIT_CARD_PREPAY',
          name: 'Credit Card Prepay',
          goal_icon: ['far', 'credit-card-front'],
          isDisabled: true,
        },
      ],
      activePaymentOpt: this.advertiserData?.advertiserPaymentMethod || {
        paymentType: null,
        billingAddress: null,
        billingContactEmail: null,
        billingContactPerson: null,
        billingContactPhone: null,
        termsAgreed: null,
      },
    };
  },
  watch: {
    advertiserData: {
      handler(nv, ov) {
        if (!isEqual(nv, ov)) {
          if (nv?.advertiserPaymentMethod) {
            this.activePaymentOpt = nv?.advertiserPaymentMethod;
          }
        }
      },
    },
  },
  methods: {
    handleShowTnCModal() {
      this.$emit("toggleTnCModal", true);
    },
    onClickPaymentOpt(payOpt) {
      if (payOpt.isDisabled) {
        return;
      }
      this.activePaymentOpt = { ...this.activePaymentOpt, paymentType: payOpt.paymentType };
      this.updateAdvertiser(this.activePaymentOpt, 'advertiserPaymentMethod');
    },
    handleBillingValueChange(val, type) {
      if (type === 'billingContactEmail') {
        const validEmailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!val.match(validEmailRegex)) {
           this.$emit('handleShowGenericModal',{
            showModal: true,
            title: 'Payment',
            message: 'Please enter valid email id',
          })
          return;
        }
      }
      this.activePaymentOpt = { ...this.activePaymentOpt, [type]: val };
      this.updateAdvertiser(this.activePaymentOpt, 'advertiserPaymentMethod');
    },
    handleAgreedChange(val) {
      this.activePaymentOpt = { ...this.activePaymentOpt, termsAgreed: val.checked };
      this.updateAdvertiser(this.activePaymentOpt, 'advertiserPaymentMethod');
    },
  },
};
</script>

<style lang="scss" scoped>
.readonly-payment-method {
  pointer-events: none;
}
.acc-info {
  &-title {
    font-size: 20px;
    color: #48525c;
    margin-bottom: 25px;
  }
}
.mvc-form-wrap3 {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  color: #48525c;
  width: 860px;
  min-height: 680px;
}

.body-sec-title {
  font-size: 1.6em;
  font-weight: 500;
  color: var(--selfserveprimarycolor);
}

.mvc-half {
  display: inline-block;
  width: 48%;
}
.mvc-half-left {
  margin-right: 3%;
}
.mvc-half-right {
  float: right;
}
.pure-input-1 {
  width: 100%;
  height: 50px !important;
  padding: 10px 18px !important;
  font-size: 14px;
  font-weight: 400;
}
.sec-title {
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
  color: #48525c;
  font-size: 20px;
  letter-spacing: 0.02em;
}
.flex-wrap {
  display: flex;
  justify-content: space-between;
  & .custom-dropdown-cont {
    width: 31%;
    margin-right: unset;
  }
}
.s2-info-wrap {
  width: 852px;
  margin: 0px 0px 15px 0px;

  display: flex;
}
.ss-form-label2 {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #48525c;
  text-align: left;
  vertical-align: baseline;
  border: 0;
}
.ss-third {
  display: inline-block;
  margin-right: 2%;
  vertical-align: top;
}

.vc-half-f-left {
}
.vc-half-s-right {
  display: inline-block;
  width: 50%;
  padding-left: 20px;
}
.mvc-flex {
  display: inline-flex !important;
}
::v-deep .input-box-format .form-input .form-input-field {
  border-radius: 5px;
  background: #f7f9fd;
  border: 1px solid #d3d6dd;
  font-size: 14px;
  color: #48525c;
  font-weight: 500;
}
::v-deep .input-box-format .form-input .form-input-field.v-select .vs__dropdown-toggle {
  border-radius: 5px;
  background: #f7f9fd;
  font-size: 14px;
  color: #48525c;
  font-weight: 500;
}
::v-deep .input-box-format .form-input-wrap .form-click {
  border-radius: 5px;
  background: #f7f9fd;
  font-size: 14px;
  color: #48525c;
  font-weight: 500;
  border: 1px solid #d3d6dd;
}
::v-deep .input-box-format .form-input .form-input-field.v-select .vs__search {
  color: #9c9c9c;
  font-size: 14px;
}
::v-deep .input-box-format .form-input-wrap .form-click .selected-text {
  color: #9c9c9c;
  font-size: 14px;
}
::v-deep .input-box-format .form-input label {
  display: none;
}
.row-flex {
  display: flex;
  margin-bottom: 24px;
  .goals-list {
    position: relative;
    cursor: pointer;
    .pop-view {
      display: block;
      width: 100px;
      height: 100px;
      border: 3px solid #f5f5f5;
      margin: 0px 20px;
      border-radius: 100%;
      .pop-icon {
        position: relative;
        top: 33%;
        left: 33%;
      }
      .pcheck2 {
        background-color: var(--selfserveprimarycolor);
        width: 18px !important;
        height: 18px !important;
        padding: 5px;
        border-radius: 20px;
        position: absolute;
        margin: 8px 0px 0px 75px;
        color: #fff;
        display: block;
        opacity: 0;
        transform: scale(1.2);
        transition: all 0.3s ease;
      }
      .pbig {
        color: #48525c;
        width: 32px !important;
        height: 32px !important;
      }
    }
    .pop-view-txt {
      padding-bottom: 12px;
      position: relative;
      top: 10px;
      font-weight: 500;
      font-size: 14px;
      pointer-events: pointer;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      text-align: center;
    }
    .kpi-alert {
      .kpi-alert-content {
        position: absolute;
        top: 0;
        margin: 14px 0px 0px 0px;
        line-height: 1.2em;
        text-align: center;
        padding: 30px;
        z-index: 2;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }

    &:hover {
      .pop-view-txt {
        color: var(--selfserveprimarycolor);
        top: 5px;
      }
      .pop-view {
        border: 3px solid var(--selfserveprimarycolor);
      }
      .pcheck2 {
        opacity: 100;
      }
      .pbig {
        color: var(--selfserveprimarycolor);
      }
      .kpi-alert {
        .kpi-alert-content {
          opacity: 100 !important;
        }
      }
    }
  }
  .activeGoal {
    &:hover .pop-view-txt {
      top: 10px;
    }
    .pop-view-txt {
      color: var(--selfserveprimarycolor) !important;
    }
    .pop-view {
      border: 3px solid var(--selfserveprimarycolor);
    }
    .pcheck2 {
      opacity: 100 !important;
    }
    .pbig {
      color: var(--selfserveprimarycolor) !important;
    }
  }
  .disable_goal {
    &:hover .pop-view-txt {
      top: 10px;
    }
    .pop-view-txt {
      opacity: 0.2;
      color: #48525c !important;
    }
    .pop-view {
      opacity: 0.2 !important;
      border: 3px solid rgba(245, 245, 245, 0.2) !important;
    }
    .pcheck2 {
      opacity: 0 !important;
    }
    .pbig {
      color: #48525c !important;
    }
  }
}
.agree-box-cont {
  display: flex;
  & p {
    font-size: 14px;
    margin-left: 10px;
    & span {
      color: var(--selfserveprimarycolor) !important;
      cursor: pointer;
    }
  }
}
::v-deep .checkbox-btn svg.checkbox-inactive {
  color: #fff !important;
  width: unset;
  border: 1px solid #d3d6dd;
  border-radius: 3px;
  width: 18px;
  height: 18.5px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
</style>
