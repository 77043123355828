<!-- Pixel Code section when selected from the menu on the left-->
<template>
  <div class="flip-pixel-code-container">
    <div class="ss-main-wrap">
      <div class="ss-c ss-1">
        <div class="mvc-form-wrap3 s3-wrap" style="padding: 0px 50px 40px 50px; text-align: left;">
          <div class="cd-pop-hd1" style="padding-top: 50px;">{{ pixelTitle }}</div>
          <p>
            Welcome! To get started, place your {{ pixelTitle }}. The pixel can be used to track a
            variety of things, due to this, the pixel code and how it is placed varies. Please refer
            to the
            <a :href="PIXEL_INSTALLATION_GUIDE_URL" target="_blank">pixel implementation guide</a>
            for documentation on how to set it up.
          </p>
          <div class="mt2">Your Pixel ID:</div>
          <p id="p2" class="greybg code-block">
            {{ pixelId ? pixelId : 'Not available (Contact support)' }}
          </p>
          <button v-if="pixelId" class="mvc-btn btn-teal m-button" @click="copyCodeToClipboard">
            Copy ID
          </button>
          <span ref="copyMessage" class="copied">Copied to clipboard</span>
          <div class="pd-tp-7"></div>
          <div class="mt2">Example pixel code for tracking homepage visits:</div>
          <div v-if="pixelId" class="greybg code-block">
            &lt;script src="https://tracker.pixeltracker.co/pixel.js"&gt;&lt;/script&gt;<br />
            &lt;script&gt;<br />
            FLPIXEL.fire("{{ pixelId }}", {<br />
            "category": "pagevisit",<br />
            "subcategory": "homepage",<br />
            });<br />

            &lt;/script&gt;
          </div>
          <p v-else class="greybg code-block">
            Pixel Not Available. Contact support.
          </p>
          <div class="pd-tp-7"></div>
          <div class="mt2">
            Example pixel code for tracking homepage visits (if your site supports GA):
          </div>
          <div v-if="pixelId" class="greybg code-block">
            &lt;script src="https://tracker.pixeltracker.co/pixel.js"&gt;&lt;/script&gt;<br />
            &lt;script&gt;<br />
            FLPIXEL.fire("{{ pixelId }}", {<br />
            "category": "pagevisit",<br />
            "subcategory": "homepage",<br />
            }, {<br />
            "ga_event_name": "<span class="hglt">CUSTOM_GA_EVENT_NAME</span>",<br />
            "ga_event_action": "<span class="hglt">CUSTOM_GA_EVENT_ACTION</span>",<br />
            });<br />

            &lt;/script&gt;
          </div>
          <p v-else class="greybg code-block">
            Pixel Not Available. Contact support.
          </p>
          <div class="mt2 pd-tp-7">Example pixel code for tracking checkouts with values:</div>
          <p v-if="pixelId" class="greybg code-block">
            &lt;script src="https://tracker.pixeltracker.co/pixel.js"&gt;&lt;/script&gt;<br />
            &lt;script&gt;<br />
            FLPIXEL.fire("{{ pixelId }}", {<br />
            "category": "checkout", <br />
            "order_number": "<span class="hglt">ORDER_NUMBER</span>", <br />
            "amount": "<span class="hglt">ORDER_AMOUNT</span>",<br />
            "currency": "<span class="hglt">CURRENCY</span>"<br />
            });<br />

            &lt;/script&gt;
          </p>
          <p v-else class="greybg code-block">
            Pixel Not Available. Contact support.
          </p>
          <div class="mt2 pd-tp-7">
            Example pixel code for tracking checkouts with values (if your site supports GA):
          </div>
          <p v-if="pixelId" class="greybg code-block">
            &lt;script src="https://tracker.pixeltracker.co/pixel.js"&gt;&lt;/script&gt;<br />
            &lt;script&gt;<br />
            FLPIXEL.fire("{{ pixelId }}", {<br />
            "category": "checkout", <br />
            "order_number": "<span class="hglt">ORDER_NUMBER</span>", <br />
            "amount": "<span class="hglt">ORDER_AMOUNT</span>",<br />
            "currency": "<span class="hglt">CURRENCY</span>"<br />
            }, {<br />
            "ga_event_name": "<span class="hglt">CUSTOM_GA_EVENT_NAME</span>",<br />
            "ga_event_action": "<span class="hglt">CUSTOM_GA_EVENT_ACTION</span>",<br />
            });<br />

            &lt;/script&gt;
          </p>
          <p v-else class="greybg code-block">
            Pixel Not Available. Contact support.
          </p>
          <div class="mt2 pd-tp-7" style="margin-bottom: 15px;">
            Replace the following variables:
          </div>
          <ul class="pxlist">
            <li>
              <span class="hglt">ORDER_NUMBER</span> with the order number of the transaction.
              Alphanumeric.
            </li>
            <li>
              <span class="hglt">ORDER_AMOUNT</span> with the dollar amount for the order (E.g.
              "12.75", 12, or 12.75 are all acceptable)
            </li>
            <li>
              <span class="hglt">CURRENCY</span> with the currency code as per ISO 4217 Standard. Eg
              USD, EUR, etc.
            </li>
            <li>
              <span class="hglt">CUSTOM_GA_EVENT_NAME</span> with the category name (UA) or event
              name (GA4).
            </li>
            <li>
              <span class="hglt">CUSTOM_GA_EVENT_ACTION</span> with the event action name (both UA
              and GA4).
            </li>
          </ul>
          <p style="font-size: 0.9em;">
            <span class="strong">Note:</span> The order confirmation page must have both the order
            number and amount values available to pass into this call.
          </p>
          <p>
            For more examples and details on how to install please see the
            <a :href="PIXEL_INSTALLATION_GUIDE_URL" target="_blank">pixel implementation guide</a>
            .
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PIXEL_INSTALLATION_GUIDE_URL } from '~/constant';

export default {
  name: 'FlipPixelCode',
  props: {
    pixelId: {
      type: String,
      required: false,
    },
    advertiserData: {
      type: Object,
      required: true,
      default: () => {},
    },
    updateAdvertiser: {
      type: Function,
      required: false,
      default: () => {},
    },
    isCurrentUserExternal: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      PIXEL_INSTALLATION_GUIDE_URL,
    };
  },
  computed: {
    pixelTitle() {
      return 'Universal pixel';
    },
  },
  mounted() {
    if (this.pixelId && this.isCurrentUserExternal) {
      if (
        this.advertiserData &&
        this.advertiserData.selfServe &&
        !this.advertiserData.pixelSetup.pixelCode
      ) {
        const temp = { ...this.advertiserData.pixelSetup, pixelCode: true };
        this.updateAdvertiser(temp, 'pixelSetup');
      }
    }
  },
  methods: {
    copyCodeToClipboard() {
      if (!this.pixelId) {
        return;
      }

      navigator.clipboard.writeText(this.pixelId);
      this.$refs.copyMessage?.classList?.add('show-copied');
      setTimeout(() => {
        this.$refs.copyMessage?.classList?.remove('show-copied');
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.flip-pixel-code-container {
}
.btn-teal {
  color: var(--buttontext) !important;
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
}
.mvc-btn {
  display: inline-block;
  min-width: 170px;
  padding: 15px 18px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  appearance: none;
  transition: all 0.6s ease;
  font-family: 'Manrope', sans-serif;
  height: 55px;
  color: var(--pixelovertext);
}
.mvc-btn:hover {
  background-color: #424242;
  color: #fff !important;
  border: 1px solid #424242;
}
.hglt {
  background-color: #fdce07;
}
li {
  margin-bottom: 10px;
  font-size: 15px;
}
.pxlist {
  list-style: none;
  list-style-type: disc !important;
}
.pxlist li {
  margin-bottom: 10px;
  font-size: 15px;
  margin-left: 30px;
}
.copied {
  color: #888;
  font-size: 13px;
  margin-left: 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
  position: relative;
  pointer-events: none;
  top: 10px;
}

.show-copied {
  opacity: 1;
  top: 0px;
}

.greybg {
  padding: 20px;
  background-color: #eee;
  border-radius: 4px;
}
p {
  display: block;
  margin-bottom: 15px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.mt2 {
  margin-top: 30px;
  font-size: 17px;
  font-weight: 600;
}
.cd-pop-hd1 {
  font-size: 1.6em;
  color: var(--primarycolor);
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 1;
  text-transform: capitalize;
}
.ss-c {
  padding-top: 0px;
  transition: padding 0.3s ease;
}
// .ss-main-wrap .pure-form .pure-input-1,.s3-wrap #flight-start-s2, .s3-wrap #flight-end-s2
.s3-wrap {
  padding: 0 50px 30px 50px !important;
  font-size: 14px;
  font-weight: 400;
}
.mvc-form-wrap3 {
  margin-top: 0px;
  margin-left: 6px;
  font-size: 16px;
  color: #48525c;
  line-height: 1.7;
  font-weight: 500;
  font-family: 'Manrope', sans-serif;
  a {
    display: inline-block;
    color: var(--primarycolor);
  }
}
.ss-main-wrap {
  width: calc(100% - 350px);
  max-width: 960px;
  //padding-bottom: 180px;
  margin-top: 60px;
  margin-left: 350px;
  vertical-align: baseline;
}

.code-block {
  margin-top: 5px;
}

.strong {
  font-weight: 600;
}
.pd-tp-7 {
  padding-top: 7px;
}
</style>
