import { ApiCrud } from 'adready-api/api/api-crud';
import http from 'adready-api/api/http';

const flipPixelApi = new ApiCrud(http.AXIOS, {
  flipPixels: 'advertisers/:advertiserId/flipPixels',
  createFlipPixel: 'POST advertisers/:advertiserId/flipPixels',
  editFlipPixel: 'PUT advertisers/:advertiserId/flipPixels/:pixelId',
  viewFlipPixel: 'advertisers/:advertiserId/flipPixels/:pixelId',
  flipPixelFiresSummary: 'advertisers/:advertiserId/flipPixels/:pixelId/fires/summary',
  flipPixelCategories: 'advertisers/:advertiserId/flipPixels/:pixelId/categories',
  flipPixelFires: 'advertisers/:advertiserId/flipPixels/:pixelId/fires',
  flipDailyPixelFires: 'advertisers/:advertiserId/flipPixels/:pixelId/fires/daily',
});

export default flipPixelApi;
